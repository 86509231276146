import * as React from 'react'
import styled from 'styled-components/macro'
import {Card} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import {numberWithCommas} from 'common/helpersNumber'
import {Spinner} from 'common/components'
// import useAssetDetail from 'assets/hooks/useAssetDetail'
import {useStore} from 'common/useStore'
import {notNullUndefined} from 'common/helpers'

const CardTitle = styled.div`
  width: 100%;
  background-color: var(--asc-moss);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 10px 15px;
`

const Table = styled.table`
  margin-bottom: 10px;
`
const TableHeader = styled.th`
  text-align: left;
  padding: 5px 5px 5px 0;
`

const CurrentAssetInformation = ({
  assetDetail,
  assetStatus,
  message,
  showCurrentDriver,
  proximity,
}) => {
  const userConfig = useStore(state => state.userConfig)
  // const {idAsset} = state
  // const {data: assetDetail} = useAssetDetail(idAsset)
  const currentOdometer = assetDetail?.Device?.Estimated_GPS_Odometer
    ? `${numberWithCommas(assetDetail.Device?.Estimated_GPS_Odometer, 0)} ${
        userConfig.Distance_UOM
      }`
    : `0 ${userConfig.Distance_UOM}`
  const currentEngineHours = assetDetail?.Device?.Estimated_Engine_Hours
    ? `${numberWithCommas(assetDetail.Device?.Estimated_Engine_Hours, 0)} hours`
    : '0 hours'

  const hasLatitude =
    assetDetail?.Device?.Latitude &&
    notNullUndefined(assetDetail?.Device?.Latitude)
  const hasLongitude =
    assetDetail?.Device?.Longitude &&
    notNullUndefined(assetDetail?.Device?.Longitude)
  const latitude = hasLatitude ? assetDetail?.Device?.Latitude : ''
  const longitude = hasLongitude ? assetDetail?.Device?.Longitude : ''
  const latLong =
    hasLatitude && hasLongitude ? `${latitude} , ${longitude}` : ''

  return (
    <Card fluid>
      <CardTitle>Current Asset Information</CardTitle>
      {assetStatus === 'loading' && assetDetail?.idAsset ? (
        <Spinner type="partial" />
      ) : (
        assetDetail && (
          <Card.Content>
            <Table>
              <tbody>
                <tr>
                  <TableHeader>Current Odometer</TableHeader>
                  <td>{currentOdometer}</td>
                </tr>
                <tr>
                  <TableHeader>Current Engine Hours</TableHeader>
                  <td>{currentEngineHours}</td>
                </tr>
                <tr>
                  <TableHeader>Current Address</TableHeader>
                  <td>
                    <Link to={`/asset-dashboard/${assetDetail?.idAsset}`}>
                      {assetDetail.Device?.Last_Nearby_Address || latLong}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <TableHeader>Last Reported</TableHeader>
                  <td>
                    {assetDetail.Device.Last_Location_Date_Local
                      ? `${assetDetail.Device.Last_Location_Date_Local} ${assetDetail.Device.Last_Location_Time_Local}`
                      : null}
                  </td>
                </tr>
                {assetDetail.CurrentDriver && showCurrentDriver && (
                  <tr>
                    <TableHeader>Current Driver</TableHeader>
                    <td>{assetDetail.CurrentDriver}</td>
                  </tr>
                )}
                {proximity && (
                  <tr>
                    <TableHeader>Proximity to Asset</TableHeader>
                    <td>{assetDetail.proximity}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Content>
        )
      )}
      {message && (
        <Card.Content>
          <p>{message}</p>
        </Card.Content>
      )}
    </Card>
  )
}

export default CurrentAssetInformation
