import React, {useRef, useState} from 'react'
import styled from 'styled-components/macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {DeviceType} from '../../../types/types'
import {useLocation} from 'react-router-dom'
import Swal from 'sweetalert2'
import Spinner from 'common/components/Spinner'
import Axios from 'axios'
import useDeviceTypes from 'management/hooks/useDeviceTypes'
import {useQueryClient} from '@tanstack/react-query'
import {CustomerType} from 'management/hooks/useCustomers'
import Modal from 'common/Modal'
import {AddDeviceType} from './hooks/usePutDevice'

type Props = {
  selected: CustomerType
  dismissModal: () => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding: 60px;
  width: 99%;
  height: 96%;
  gap: 32px;
  span {
    font-size: calc(12 / 14 * 1rem);
  }
`

const Icon = styled(FontAwesomeIcon)`
  font-size: calc(14 / 14 * 1rem);
  color: #333;
  margin: -0.1em 0.5em 0 0;
`

const Button = styled.button`
  font-size: calc(14 / 14 * 1rem);
  background-color: ${(p: {color?: string; textColor?: string}) =>
    p.color || 'rgba(0,0,0,0.1)'};
  color: ${p => (p.textColor ? p.textColor : 'black')};
  padding: 0.5em 1em;
  border-radius: 5px;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: border 0.2s ease-in-out;
  width: 300px;
  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
`
const FileUploadInput = styled.input`
  display: none;
`

const NoCustomerSelectedMessage = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 1;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin: 0;
  height: 300px;
  justify-content: center;
  align-items: center;
  font-size: calc(17 / 14 * 1rem);
  padding: 10px;
`

const MultipleDeviceUpload = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const {selected, dismissModal} = props
  const location = useLocation()
  const {pathname} = location
  const {data: deviceTypes} = useDeviceTypes()
  const cache = useQueryClient()

  const inputEl = useRef<HTMLInputElement>(null)

  const uploadClick = () => {
    if (inputEl && inputEl.current) {
      inputEl.current.value = ''
      inputEl.current.click()
    }
  }

  const postNewDevice = (device: AddDeviceType) => {
    return Axios.post('/devices', device)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function isNumber(n: any) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0)
  }

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      reader.readAsBinaryString(file)
    }
    reader.onload = async (event: ProgressEvent<FileReader>) => {
      const data = event?.target?.result
      const XLSX = await import('common/SheetJSWriteWrapper')
      const wb = XLSX.read(data, {type: 'binary'})
      const incomingDevices = XLSX.utils.sheet_to_json(
        wb.Sheets['Bulk Add Device Template'],
        {range: 1},
      )
      // Remove template example
      incomingDevices.shift()
      const newDeviceList: AddDeviceType[] = []

      if (incomingDevices.length === 0) {
        Swal.fire({
          text: `No devices found. Please verify entries.`,
          icon: 'error',
        })
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const hasRequiredValues = (d: any) =>
          isNumber(d['Device Type']) &&
          isNumber(d['Carrier ID']) &&
          d['Serial Number']

        // Check IMEI for Surfsight Camera
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const imeiCheck = (d: any) => {
          if (d['Device Type'] === 35) {
            if (!d['IMEI (optional)'] && d['Serial Number']) {
              d['IMEI (optional)'] = d['Serial Number']
            }
            return d['IMEI (optional)'] ? true : false
          } else {
            return true
          }
        }

        if (
          incomingDevices.every(
            device => hasRequiredValues(device) && imeiCheck(device),
          )
        ) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          incomingDevices.forEach((d: any) => {
            let newDeviceType: number = 0
            if (isNumber(d['Device Type'])) {
              newDeviceType = d['Device Type']
            } else {
              if (deviceTypes) {
                const match = deviceTypes.find(
                  (dt: DeviceType) =>
                    dt.Device_Description.toLowerCase() ===
                    d['Device Type'].toLowerCase(),
                )
                if (match?.idDevice_Type) {
                  newDeviceType = match?.idDevice_Type
                }
              }
            }
            if (selected?.idcustomer && newDeviceType) {
              newDeviceList.push({
                idDevice_Type: Number(newDeviceType),
                idCustomer: selected.idcustomer,
                idCarrier: Number(d['Carrier ID']),
                Serial_Number_Displayed: d['Serial Number'],
                Order_Nbr: d['Order Number (optional)'],
                IMEI: d['IMEI (optional)'],
                // Default Unique_ID_Source = 3
                Unique_ID_Source: !isNaN(
                  d['Unique ID (1 for MC4, 3 for any other Device)'],
                )
                  ? parseInt(d['Unique ID (1 for MC4, 3 for any other Device)'])
                  : 3,
                MSISDN: d['MSISDN (optional)'],
                Item_Nbr: d['Item Number (optional)'],
                Order_Price: !isNaN(d['Order Price (optional)'])
                  ? parseFloat(d['Order Price (optional)']).toFixed(2)
                  : null,
                TMC_Part_Nbr: d['TMC Part # (optional)'],
                SIMNBR: d['SIMNBR (optional)'],
                DeviceModel: d['Device Model (optional)'],
                Device_Notes: d['Device Notes (optional)'],
                Salesforce_Customer_ID: d['Salesforce Customer ID (optional)'],
                idCustomer_Final: Number(d['idCustomer_Final (optional)']),
                idDevice_Final: Number(d['idDevice_Final (optional)']),
                DeviceSwapType: Number(d['DeviceSwapType (optional)']),
                idDealer_Swappable: Number(d['idDealer_Swappable (optional)']),
                idDevice_Status: Number(d['idDevice_Status (optional)']),
              })
            }
          })
        } else {
          Swal.fire({
            text: `Incorrect or missing required values.`,
            icon: 'error',
          })
        }
      }
      if (newDeviceList.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        newDeviceList.forEach((d: any) => {
          for (const field in d) {
            if (
              d[field] === null ||
              d[field] === undefined ||
              d[field] === ''
            ) {
              delete d[field]
            }
          }
        })
        Swal.fire({
          text: `${
            incomingDevices.length === 1
              ? incomingDevices.length + ' device'
              : incomingDevices.length + ' devices'
          } will be added to ${selected?.customer_name}.`,
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Continue',
        }).then(async result => {
          if (result.isConfirmed) {
            setLoading(true)
            const results = {
              success: 0,
              errors: 0,
            }
            for (const device of newDeviceList) {
              try {
                const response = await postNewDevice(device)
                if (response.status === 201) {
                  cache.invalidateQueries([`devices-${device.idCustomer}`])
                  cache.invalidateQueries(['hotSwapDevices'])
                  results.success += 1
                } else {
                  results.errors += 1
                }
              } catch (error) {
                results.errors += 1
              }
            }
            if (results.errors === 0) {
              Swal.fire({
                icon: 'success',
                text: `${results.success} new ${
                  results.success === 1 ? 'device' : 'devices'
                } created for ${selected?.customer_name}`,
              })
            } else if (results.errors !== 0 && results.success > 0) {
              Swal.fire({
                icon: 'info',
                html: `<p>Devices Added: ${results.success}</p><p>Errors: ${results.errors}</p>`,
              })
            } else if (results.errors !== 0 && results.success === 0) {
              Swal.fire({
                icon: 'error',
                html: `There was an error adding devices. Please try again.`,
              })
            }
            setLoading(false)
          }
        })
      }
    }
  }

  if (pathname.includes('/support/devices') && !selected)
    return (
      <NoCustomerSelectedMessage>
        Select a customer above to add devices
      </NoCustomerSelectedMessage>
    )

  return (
    <Modal
      title="Bulk Add Devices"
      onDismiss={dismissModal}
      renderFooter={false}
      showDialog={true}
      width="50%"
    >
      {!loading ? (
        <Container>
          <h3>Add devices to {selected?.customer_name} via import</h3>
          <div>
            <Button onClick={() => uploadClick()}>
              <Icon icon="upload" />
              <span>Upload from template</span>
            </Button>
            <FileUploadInput
              accept=".xlsx"
              ref={inputEl}
              onChange={handleUpload}
              type="file"
            />
            <a href="/files/BulkAddDeviceTemplate.xlsx">
              <Button>
                <Icon icon="download" />
                <span>Download Template</span>
              </Button>
            </a>
          </div>
          <span>
            Note: Device Type, Carrier ID, and Serial Number are required for
            each device.
          </span>
        </Container>
      ) : (
        <Spinner></Spinner>
      )}
    </Modal>
  )
}

export default MultipleDeviceUpload
