import * as React from 'react'
import styled from 'styled-components/macro'
import {QUERIES} from 'common/constants'
import {
  EllipsisText,
  InputWithLabel,
  SearchLocationInput,
} from 'common/components'

import Modal from 'common/Modal'
import {Button} from 'semantic-ui-react'
type RowProps = {
  alignItems?: string
  justifyContent?: string
  gap?: string
  padding?: string
  background?: string
  marginTop?: string
}

export const Row = styled.div<RowProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  background: ${props => props.background || ''};
  align-items: ${props => props.alignItems || ''};
  justify-content: ${props => props.justifyContent || ''};
  gap: ${props => (props.gap ? props.gap : '')};
  padding: ${props => (props.padding ? props.padding : '')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '')};
`
export const Wrapper = styled.div`
  flex: 1;
`

export const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  width: ${({width}: {width?: string}) => (width ? width : 'default')};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({width}: {width?: string}) => (width ? width : 'default')};
`
export const DateRow = styled.div`
  display: flex;
  flex-direction: row;
  width: ${({width}: {width?: string}) => (width ? width : 'default')};
`

export const Container = styled.div`
  display: grid;
  grid-template-rows: 350px 1fr;
  width: 100%;
  background: var(--asc-cultured);
  height: 100%;
  form {
    width: 100vw;
    padding: 0px;
    border-bottom: 1px solid var(--asc-coolgray);
  }
  @media ${QUERIES.tabletAndUp} {
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-template-rows: auto;
    form {
      border-right: 1px solid var(--asc-coolgray);
      width: auto;
    }
  }
`

export const Header = styled(Column)`
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h1 {
    font-weight: 700;
    font-size: 24px;
    margin-top: 2px;
  }
  h2 {
    font-weight: normal;
    font-size: 14px;
    margin: 0;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    span {
      content: '*';
      font-size: 0.9rem !important;
      color: var(--asc-vermilion);
      margin-right: 5px;
    }
  }
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  padding: 20px;
  padding-bottom: 0;
`
export const Content = styled(Column)`
  height: 100%;
  overflow-y: auto;
`
type SectionProps = {background: string; flexGrow?: number}
export const Section = styled(Column)<SectionProps>`
  padding: 20px;
  gap: 16px;
  background: ${props => props.background};
  flex-grow: ${props => props.flexGrow || ''};
  border-bottom: 1px solid var(--asc-coolgray);
  label {
    font-size: 14px;
  }
  .field {
    margin-bottom: 0px !important;
  }
`

export const StopSection = styled(Section)`
  padding: 0px;
  border-bottom: none;
  min-height: 250px;
`
export const StopListContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 3px;
`

export const GroupLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
`

export const StyledInputWithLabel = styled(InputWithLabel)`
  margin: 0px !important;
`
export const ButtonContainer = styled.div`
  width: fit-content;
`
export const AddButton = styled(Row)`
  background: transparent;
  height: fit-content;
  align-items: center;
  font-weight: 700;
  color: var(--primary);
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
  :hover {
    background: transparent;
    color: var(--secondary);
    svg {
      fill: var(--secondary);
    }
  }
`
// export const OutlineAddButton = styled.div`
//   &&& {
//     cursor: pointer;
//     padding: 2px 10px;
//     border-radius: 5px;
//     background: transparent;
//     height: fit-content;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     border: 1px solid var(--primary);
//     font-weight: 700;
//     color: var(--primary);
//     svg {
//       margin-right: 5px;
//     }
//     :hover {
//       background: transparent;
//       color: var(--secondary);
//       border: 1px solid var(--secondary);
//       svg {
//         fill: var(--secondary);
//       }
//     }
//     &.disabled {
//       cursor: not-allowed;
//       color: var(--asc-coolgray);
//       border: 1px solid var(--asc-coolgray);
//       pointer-events: none;
//     }
//   }
// `

// export const TitleBadgeGroup = styled(Row)`
//   align-items: center;
// `

type TitleProps = {
  fontSize: number
  marginBottom?: string
  paddingTop?: string
}

export const Title = styled.div<TitleProps>`
  font-weight: 700;
  font-size: ${props => props.fontSize}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '')};
`

// export const Badge = styled(Row)`
//   align-items: center;
//   min-width: 35px;
//   min-height: 1em;
//   padding: 0.37em;
//   line-height: 1em;
//   justify-content: center;
//   border-radius: 500rem;
//   background-color: var(--secondary);
//   box-shadow: 1px 1px 1px var(--asc-coolgray);
//   color: black;
//   font-weight: bold;
//   margin-left: 15px;
//   margin-top: 3px;
// `

export const MapContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  div[role='menubar'] {
    bottom: 14px !important;
    right: 60px !important;
  }
`

// export const ErrorBadge = styled(Row)`
//   align-items: center;
//   background: var(--asc-lavender);
//   border: 1px solid var(--asc-vermilion);
//   border-radius: 4px;
//   padding: 5px 10px;
//   font-weight: 400;
//   height: fit-content;
//   svg {
//     margin-right: 5px;
//   }
// `
export const SectionRow = styled(Row)`
  width: 100%;
  justify-content: center;
  height: 100%;
`
export const ListContainer = styled.div<{topPadding?: string}>`
  padding-top: ${props => (props.topPadding ? props.topPadding : '10px')};
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  height: 100%;

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey; // set the color of the scrollbar thumb
    border-radius: 10px; // round the corners of the scrollbar thumb
  }
`
// export const StopTitleRow = styled(Row)`
//   padding: 0px 20px;
//   justify-content: space-between;
//   padding-top: 10px;
// `
// export const NoteRow = styled(Row)`
//   padding: 10px;
//   span {
//     width: 100%;
//     align-items: center;
//     text-align: center;
//     font-size: 12px;
//   }
// `
export const StopCardContainer = styled(Row)`
  background: ${({menuOpen}: {menuOpen: boolean}) =>
    menuOpen ? 'var(--primary)' : 'white'};
  border: 1px solid var(--asc-coolgray);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  width: 92%;
  height: fit-content;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

// export const StopBadge = styled.div`
//   height: 20px;
//   width: 20px;
//   background: ${({status}: {status: string | undefined}) =>
//     status === 'skipped'
//       ? 'var(--asc-dimgray)'
//       : status === 'arrived'
//       ? 'var(--asc-moss)'
//       : status === 'departed'
//       ? 'var(--asc-gold)'
//       : 'var(--asc-dimgray)'};
//   color: white;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-weight: bold;
// `

export const StopInfoAddress = styled.div`
  color: ${({menuOpen}: {menuOpen: boolean}) =>
    menuOpen ? 'var(--secondary)' : 'var(--primary)'};
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 5px;
`
type StopInfoDetailProps = {menuOpen: boolean; info: string}

export const StopInfoDetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const StopInfoDetail = styled.div<StopInfoDetailProps>`
  color: ${props =>
    props.menuOpen
      ? 'var(--secondary)'
      : props.info === 'date'
      ? 'var(--asc-brown)'
      : 'var(--asc-dimgray)'};
  font-size: 14px;
  span {
    font-weight: 700;
  }
  display: flex;
  flex-direction: row;
  align-items: Center;
  svg {
    margin-right: 5px;
  }
  margin-bottom: 5px;
  flex-wrap: nowrap;
`

export const ModalBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 20px;
`

export const Form = styled(Column)`
  width: 100%;
  gap: 20px;
  label {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    font-family: 'Open Sans';
    margin-bottom: 5px;
    display: block;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.92857143em;
    font-weight: 700;
    svg {
      margin-left: 5px;
    }
  }
  #duration-input {
    width: fit-content !important;
  }
`

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({justifyContent}: {justifyContent?: string}) =>
    justifyContent || 'space-between'};
  gap: 30px;
`
export const RowWithPadding = styled(StyledRow)`
  padding-bottom: 10px;
  .date-picker {
    width: 100%;
  }
  .range-container {
    top: 10px;
  }
`
export const DatePickerRow = styled(StyledRow)`
  padding-bottom: 0px;
  .date-picker {
    width: 100%;
  }
  .range-container {
    top: 30px;
    #bottom-bar {
      flex-wrap: nowrap;
    }
  }
`

export const BottomInput = styled.div`
  padding-bottom: 30px;
  padding-top: 16px;
`
export const StyledSearchLocationInput = styled(SearchLocationInput)`
  margin-bottom: 16px;
`

export const Card = styled(Column)`
  background: #f5f5f7;
  width: 100%;
  height: 100%;
  padding: 0 30px 30px 30px;
  gap: 20px;
`

type StyledModalProps = {hasError?: boolean}
export const StyledModal = styled(Modal)<StyledModalProps>`
  #start-hour {
    border-right: none !important;
    border-color: ${({hasError}) =>
      hasError ? 'var(--asc-vermilion) !important' : ''};
    background: ${({hasError}) =>
      hasError ? 'var(--asc-lavender) !important' : 'transparent'};
    color: ${({hasError}) =>
      hasError ? 'var(--asc-vermilion) !important' : ''};
  }
  #start-minute {
    border-left: none !important;
    border-color: ${({hasError}) =>
      hasError ? 'var(--asc-vermilion) !important' : ''};
    background: ${({hasError}) =>
      hasError ? 'var(--asc-lavender) !important' : 'transparent'};
    color: ${({hasError}) =>
      hasError ? 'var(--asc-vermilion) !important' : ''};
  }
  #time-semicolon {
    border-top: 1px solid var(--asc-graphite) !important;
    border-bottom: 1px solid var(--asc-graphite) !important;
    border-color: ${({hasError}) =>
      hasError ? 'var(--asc-vermilion) !important' : ''};
    background: ${({hasError}) =>
      hasError ? 'var(--asc-lavender) !important' : 'transparent'};
    color: ${({hasError}) =>
      hasError ? 'var(--asc-vermilion) !important' : ''};
  }
  .service-window {
    color: ${({hasError}) =>
      hasError ? 'var(--asc-vermilion) !important' : ''};
    label {
      color: ${({hasError}) =>
        hasError ? 'var(--asc-vermilion) !important' : ''};
    }
    svg {
      fill: ${({hasError}) =>
        hasError ? 'var(--asc-vermilion) !important' : ''};
    }
  }
`

export const StyledGroupButton = styled(Button.Group)`
  &&& {
    height: 30px;
    border-radius: 0px;
    padding-top: 30px;
    .primary {
      background: var(--primary) !important;
      color: var(--secondary) !important;
    }
    button {
      font-weight: 700 !important;
      align-items: center;
      background: white;
      color: var(--primary) !important;
      border: 1px solid var(--asc-moss) !important;
      :first-child {
        border-right: none !important;
        border-radius: 5px 0px 0px 5px !important;
      }
      :last-child {
        border-radius: 0px 5px 5px 0px !important;
      }
      border-radius: 0px !important;
      display: flex;
      flex-direction: row;
      font-size: 13px !important;
      svg {
        margin-right: 3px;
      }
    }
  }
`

export const ReadOnly = styled.div`
  min-height: 25px;
`
export const InputErrorMessage = styled.em`
  color: var(--asc-vermilion);
  font-weight: bold;
`

export const BadgeContainer = styled.div`
  max-width: 100px;
  padding-top: 5px;
`

export const OrderDetailForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  .label {
    background-color: var(--grey-300);
  }
  .field {
    margin-bottom: 1em;
  }
  i {
    opacity: 1 !important;
  }
  .disabled.field {
    opacity: 1;
  }
  .disabled.dropdown {
    opacity: 1;
    background-color: var(--grey-300);
  }
  .disabled.input {
    opacity: 1;
  }
  input:disabled {
    background-color: var(--grey-300);
  }
  textarea {
    width: 100%;
    border: 1px solid rgba(34, 36, 38, 0.15);
  }
  /* Following is needed to scroll correctly */
  position: relative;
`
export const StyledEllipsisText = styled(EllipsisText)`
  max-width: 240px;
`

export const StopCardEditButtonColumn = styled(Column)`
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  svg {
    cursor: pointer;
    color: var(--primary);
    :hover {
      color: var(--secondary);
      fill: var(--secondary);
    }
  }
  .icon {
    min-width: 20px !important;
    min-height: 20px !important;
  }
`

export const StopButtonDescription = styled.div`
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
  div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

export const StopsButtonGroup = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({isServiceSelected, isDeliverySelected, ...props}) => (
    <Button.Group {...props} />
  ),
)`
  &&& {
    border-radius: 6px;
    width: 100%;
    button {
      font-weight: 700 !important;
      align-items: center;
      justify-content: center;
      background: white;
      color: var(--primary);
      border: 1px solid var(--asc-graphite);
      :first-child {
        background: ${props =>
          props?.isServiceSelected ? 'var(--asc-darkolive)' : '#FFFFFF'};
        color: ${props =>
          props?.isServiceSelected ? 'var(--asc-lime)' : 'var(--primary)'};
        border-left: 1px solid var(--asc-graphite);
        border-right: none !important;
        border-radius: 5px 0px 0px 5px !important;
      }
      :last-child {
        background: ${props =>
          props?.isDeliverySelected ? 'var(--asc-darkolive)' : '#FFFFFF'};
        color: ${props =>
          props?.isDeliverySelected ? 'var(--asc-lime)' : 'var(--primary)'};
        border-radius: 0px 5px 5px 0px !important;
      }
      :hover {
        color: var(--Dark-Olive);
        background-color: var(--asc-lime);
      }
      border-radius: 0px !important;
      display: flex;
      flex-direction: row;
      font-size: 13px !important;
    }
  }
`

export const PickupAddButton = styled(Button)`
  &&& {
    display: flex;
    background: var(--asc-lime) !important;
    margin-bottom: 15px;
    width: 300px;
    justify-content: center;
    align-items: center;
  }
`

export const DeliveryAddButton = styled(Button)`
  &&& {
    background: var(--asc-gold) !important;
    display: flex;
    width: 300px;
    margin-top: 15px;
    align-items: center;
    justify-content: center;
  }
`

export const StopButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  gap: 10px;
`
export const LocationPanelContainer = styled.div`
  margin-left: 15px;
`
export const StopTypeButtonGroup = styled(Column)`
  flex: 1;
`
