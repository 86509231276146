import * as React from 'react'
import styled from 'styled-components/macro'
import {
  StyledLabel,
  RadioButtonGroup,
  RequiredAsterisk,
} from 'common/components'
// import {CaretDown, CaretUp} from 'phosphor-react'
import {useStore} from 'common/useStore'
import useComponentVisible from 'common/useComponentVisible'

const ShiftTimeContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  /* background-color: var(--asc-cultured); */
  /* width: min-content; */
  /* margin-bottom: 20px; */
`
const Time = styled.input`
  height: 30px;
  border: 1px solid #ccc;
  /* padding: 10px 0 !important; */
  width: 40px;
  text-align: center;
  height: 38px;
  border: 1px solid var(--asc-platinum);
  line-height: 20px;
  :focus {
    border: 1px solid var(--secondary);
    outline: 1px solid var(--asc-keylime);
  }
  :disabled {
    pointer-events: none;
  }
`
const Hour = styled(Time)`
  border-radius: 5px 0px 0px 5px;
  border-right: 0px;
`
const Minute = styled(Time)`
  border-radius: 0px 5px 5px 0px;
  border-left: 0px;
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  /*Phosphor Caret Up/Down Icons*/
  svg {
    margin: 2px 0px;
    cursor: pointer;
    height: 25px;
    width: 30px;
    padding: 0 5px;
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    border-radius: 5px;
    color: var(--primary);
    &:active {
      transform: scale(0.9);
    }
    color: var(--primary);
    :hover {
      background-color: var(--primary);
      color: var(--secondary);
    }
  }
`
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  color: ${props => (props.hasError ? 'var(--asc-vermilion)' : 'default')};
  input {
    color: ${props => (props.hasError ? 'var(--asc-vermilion)' : 'default')};
  }
`
const PaddingRightLabel = styled(StyledLabel)`
  padding-right: 20px;
`
const Semicolon = styled.div`
  border-top: 1px solid var(--asc-platinum);
  border-bottom: 1px solid var(--asc-platinum);
  font-weight: 700;
  font-size: 16px;
  padding: 0px 4px;
  height: 38px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  ::after {
    content: ':';
  }
`
const HourPanel = styled.div`
  position: absolute;
  top: ${({dialogLocation}) => (dialogLocation === 'top' ? '-150px' : '100%')};
  left: 0;
  background-color: #fff;
  display: flex;
  border: 1px solid var(--asc-graphite);
  border-radius: 4px 4px 0px 0px;
  z-index: 99;
`
const MinPanel = styled(HourPanel)`
  left: 53px;
`

const PanelSelect = styled.div`
  float: left;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 47px;
  max-height: 138px;
  overflow-y: auto;
  position: relative;
  :first-child {
    border-left: 0;
    margin-left: 0;
  }
  :last-child {
    border-right: 0;
  }
  ul {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
  }
`
const Item = styled.li`
  background: ${({selected}) => (selected ? 'var(--asc-moss)' : '#ffffff')};
  color: ${({selected}) => (!selected ? 'var(--asc-drakolive)' : '#ffffff')};
  font-weight: ${({selected}) => (selected ? '700' : '500')};
  list-style: none;
  margin: 0;
  padding: 0 0 0 12px;
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  user-select: none;
  :hover {
    background: ${({selected}) =>
      selected ? 'var(--asc-moss)' : 'var(--asc-keylime)'};
  }
`

export const convertTimeEditFormat = time => {
  const splitTime = time.split(':')
  return {
    hour: splitTime[0],
    minute: splitTime[1],
  }
}
const ShiftTimePicker = ({
  label = '',
  setTime,
  time,
  disabled = false,
  showLabel = true,
  dialogLocation = 'bottom',
  hasError = false,
  required = false,
}) => {
  const {Time_Format} = useStore(state => state.userConfig)
  const startHour = time?.hour?.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  })
  const startMinute = time?.minute?.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  })

  const setAMPM = value => {
    const currentTime = Object.assign({}, time)
    currentTime.ampm = value
    setTime(currentTime)
  }

  const validateTime = newTime => {
    if (Time_Format === '12HR') {
      if (newTime.hour <= 12 && newTime.minute <= 59) {
        setTime(newTime)
      }
    } else {
      if (newTime.hour <= 23 && newTime.minute <= 59) {
        setTime(newTime)
      }
    }
  }

  const hours = Array.from(Array(Time_Format === '12HR' ? 12 : 23).keys())
  const minutes = Array.from(Array(60).keys())
  const selectedMinuteRef = React.useRef(null)
  const selectedHourRef = React.useRef(null)

  const {
    ref: hourRef,
    isComponentVisible: isHourComponentVisible,
    setIsComponentVisible: setIsHourComponentVisible,
  } = useComponentVisible(false)

  const {
    ref: minuteRef,
    isComponentVisible: isMinuteComponentVisible,
    setIsComponentVisible: setIsMinuteComponentVisible,
  } = useComponentVisible(false)

  const hourClicked = event => {
    const hour = event.currentTarget.dataset.hour
    const newValue = {...time, hour: Number(hour)}
    setTime(newValue)
    setIsHourComponentVisible(false)
  }

  const minuteClicked = event => {
    const min = event.currentTarget.dataset.minute
    const newValue = {...time, minute: Number(min)}
    setTime(newValue)
    setIsMinuteComponentVisible(false)
  }

  React.useEffect(() => {
    if (isMinuteComponentVisible && selectedMinuteRef) {
      return selectedMinuteRef.current?.scrollIntoView()
    }
  }, [isMinuteComponentVisible, time])

  React.useEffect(() => {
    if (isHourComponentVisible && selectedHourRef) {
      return selectedHourRef.current?.scrollIntoView()
    }
  }, [isHourComponentVisible, time])

  return (
    <ShiftTimeContainer data-cy="report-timepicker">
      {showLabel && (
        <PaddingRightLabel>
          {label}
          {required && <RequiredAsterisk />}
        </PaddingRightLabel>
      )}
      <Row hasError={hasError}>
        <InputContainer>
          <Hour
            value={startHour}
            data-cy="start-hour"
            id="start-hour"
            disabled={disabled}
            onClick={() => setIsHourComponentVisible(true)}
            onChange={e =>
              validateTime({...time, hour: parseInt(e.currentTarget.value)})
            }
            onBlur={() => {
              if (Time_Format === '12HR') {
                if (time.hour === 0) setTime({...time, hour: 1})
              }
            }}
          />
        </InputContainer>
        <Semicolon id="time-semicolon" />
        <InputContainer>
          <Minute
            value={startMinute}
            data-cy="start-minute"
            id="start-minute"
            disabled={disabled}
            onClick={() => setIsMinuteComponentVisible(true)}
            onChange={e =>
              validateTime({...time, minute: parseInt(e.currentTarget.value)})
            }
          />
        </InputContainer>
        {Time_Format === '12HR' && (
          <RadioButtonGroup
            options={[
              {text: 'AM', value: 'AM'},
              {text: 'PM', value: 'PM'},
            ]}
            selected={time?.ampm}
            setSelected={setAMPM}
            buttonPadding="8px"
            hasError={hasError}
          />
        )}
      </Row>
      {isHourComponentVisible && (
        <HourPanel dialogLocation={dialogLocation} ref={hourRef}>
          <PanelSelect>
            <ul>
              {hours.map(hour => {
                return (
                  <Item
                    key={hour + 1}
                    role="button"
                    data-hour={hour + 1}
                    onClick={hourClicked}
                    selected={time.hour === hour + 1}
                    ref={time.hour === hour + 1 ? selectedHourRef : null}
                  >
                    {(hour + 1).toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                    })}
                  </Item>
                )
              })}
            </ul>
          </PanelSelect>
        </HourPanel>
      )}
      {isMinuteComponentVisible && (
        <MinPanel dialogLocation={dialogLocation} ref={minuteRef}>
          <PanelSelect>
            <ul>
              {minutes.map(minute => {
                return (
                  <Item
                    key={minute}
                    role="button"
                    data-minute={minute}
                    onClick={minuteClicked}
                    selected={time.minute === minute}
                    ref={time.minute === minute ? selectedMinuteRef : null}
                  >
                    {minute.toLocaleString('en-US', {minimumIntegerDigits: 2})}
                  </Item>
                )
              })}
            </ul>
          </PanelSelect>
        </MinPanel>
      )}
    </ShiftTimeContainer>
  )
}

export default ShiftTimePicker
