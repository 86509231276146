import * as React from 'react'
import styled from 'styled-components/macro'

type Props = {
  tickOption: {
    interval: number
    unit?: string
  }
  start: number
  end: number
  initialValue?: number
  onChange: (value: number) => void
}

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`

const Track = styled.div<{position: number}>`
  position: absolute;
  width: 100%;
  height: 6px;
  top: 50%;
  transform: translateY(-50%);
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => props.position}%;
    height: 100%;
    background-color: var(--asc-moss);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: ${props => props.position}%;
    width: ${props => 100 - props.position}%;
    height: 100%;
    background-color: var(--asc-selectedgreen);
  }
`

const Tick = styled.div<{position: number}>`
  position: absolute;
  left: ${({position}) => position}%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  user-select: none;
  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: var(--asc-moss);
    border-radius: 50%;
  }
`

const Label = styled.div`
  font-size: 12px;
  margin-top: 15px;
  white-space: nowrap;
`

const Pointer = styled.div<{position: number}>`
  position: absolute;
  left: ${({position}) => position}%;
  transform: translateX(-50%);
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  box-shadow: 2px 2px 4px 0px #00000026;
  border: 1.5px solid #778737;
`

const Slider: React.FC<Props> = ({
  tickOption,
  start,
  end,
  onChange,
  initialValue,
}) => {
  const [value, setValue] = React.useState(initialValue || start)
  const sliderRef = React.useRef<HTMLDivElement>(null)
  const pointerRef = React.useRef<HTMLDivElement>(null)
  const [tickValues, setTickValues] = React.useState<number[]>([])

  React.useEffect(() => {
    if (initialValue !== undefined && initialValue !== value) {
      setValue(initialValue)
    }
  }, [initialValue, value])

  React.useEffect(() => {
    const tickCount = (end - start) / tickOption.interval
    const tickValues = Array.from(
      {length: tickCount + 1},
      (_, i) => start + i * tickOption.interval,
    )
    if (tickValues[tickValues.length - 1] !== end) {
      tickValues.push(end)
    }
    setTickValues(tickValues)
  }, [start, end, tickOption?.interval])

  const handlePointerDown = () => {
    document.addEventListener('mousemove', handlePointerMove)
    document.addEventListener('mouseup', handlePointerUp)
  }

  const handlePointerMove = (e: MouseEvent) => {
    const rect = sliderRef.current!.getBoundingClientRect()
    const newValue = Math.round(
      ((e.clientX - rect.left) / rect.width) * (end - start) + start,
    )
    if (newValue >= start && newValue <= end) {
      setValue(newValue)
      onChange(newValue)
    }
  }

  const handlePointerUp = () => {
    document.removeEventListener('mousemove', handlePointerMove)
    document.removeEventListener('mouseup', handlePointerUp)
  }

  return (
    <SliderContainer ref={sliderRef}>
      <Track position={((value - start) / (end - start)) * 100} />
      {tickValues.map(tickValue => (
        <Tick
          key={tickValue}
          position={((tickValue - start) / (end - start)) * 100}
        >
          <Label>
            {tickValue}
            {tickOption.unit}
          </Label>
        </Tick>
      ))}
      <Pointer
        ref={pointerRef}
        position={((value - start) / (end - start)) * 100}
        onMouseDown={handlePointerDown}
      >
        {value}
        {tickOption.unit}
      </Pointer>
    </SliderContainer>
  )
}

export default Slider
