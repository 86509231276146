import styled from 'styled-components/macro'
import {Button} from 'semantic-ui-react'
import {QUERIES} from 'common/constants'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 5px;
  padding-right: 15px;
  background: var(--asc-cultured);
  position: relative;
  @media ${QUERIES.tabletAndUp} {
    padding: 15px;
  }
`

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1600px;
  flex-direction: column;
`
export const MapContainer = styled.div`
  display: flex;
  width: 100%;
  height: 300px;
  margin-top: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 16px 16px;
  justify-content: center;
  align-items: center;
`

export const VideoContainer = styled.div`
  div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
    font-weight: bold;
    height: fit-content !important;
    svg {
      margin-right: 10px;
    }
    video {
      object-fit: cover;
      width: 100%;
      height: auto !important;

      @media ${QUERIES.tabletAndUp} {
        max-height: 100%;
        min-height: 320px;
      }
    }
    /* @media ${QUERIES.tabletAndUp} {
      height: 360px !important;
    } */
  }
`
export const CameraIconHolder = styled.span`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
  font-weight: bold;
  svg {
    margin-right: 10px;
  }
`

export const CameraFootageContainer = styled.div`
  ${({showContainer}: {showContainer: boolean}) =>
    !showContainer &&
    'min-width: 525px;' +
      'border: 1px solid #d2d2d7;' +
      'box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);' +
      'padding: 40px 40px 20px 40px;' +
      'background: white;' +
      ' border-radius: 20px;' +
      'overflow-y: auto;' +
      'margin-top: 20px;'}
`

export const RenderMarker = styled.div`
  // these props are necessary for google-maps-react
  display: ${({lat, lng, text}: {lat?: number; lng?: number; text?: string}) =>
    'grid'};
  grid-template-columns: 1fr;
  @media ${QUERIES.tabletAndUp} {
    grid-template-columns: 1fr 1fr;
  }
  grid-template-rows: auto;
  width: 100%;
  gap: 30px;
  img {
    height: auto;
    width: 100%;
    max-width: 600px;
  }
`
export const ImageContainer = styled.div`
  margin: 10px;
  padding: 10px;
  transition: 0.3s;
  border-radius: 5px;
  min-width: 500px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  flex: 1;
`
export const ImageContainerContent = styled.div`
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 200px;
  align-self: auto;
  justify-self: center;
  margin: auto;
  height: fit-content;
`
export const NoVideoView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`
export const ErrorMessage = styled.div`
  padding: 10px;
`
export const CameraInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0px;
  padding-right: 0;
  gap: 80px;

  @media ${QUERIES.tabletAndUp} {
    margin: 15px;
    margin-left: 0;
    padding-right: 37px;

    justify-content: space-between;
    align-items: center;
  }
`

export const Commands = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  h3,
  h4 {
    margin: 0;
  }
  h3 {
    font-size: 24px;
    font-weight: 700;
  }
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 30px;
    h4 {
      font-weight: normal;
      margin-right: 20px;
      font-size: 18px;
    }
    div {
      font-size: 14px;
      font-weight: bold;
    }
  }
`
export const ButtonContent = styled.div`
  &&& {
    display: flex;
    width: 100%;
    justify-content: center;
    .button {
      padding-left: 45px !important;
      padding-right: 15px !important;
    }
    .buttons {
      .button {
        padding: 10px;
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }

    @media ${QUERIES.tabletAndUp} {
      width: fit-content;
      justify-content: space-between;
      gap: 15px;
      .button {
        padding-left: 4.07142857em !important;
        padding-right: 1.5em !important;
      }
      .buttons {
        .button {
          padding: 0.78571429em 1.5em 0.78571429em !important;
        }
      }
    }
  }
`
export const ButtonGroup = styled(Button.Group)`
  &&& {
    border: 1px solid var(--primary);
    border-radius: 6px;
    margin-right: 5px;
    @media ${QUERIES.tabletAndUp} {
      margin-left: 5px;
    }
  }
`

export const ButtonAndText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${QUERIES.tabletAndUp} {
    gap: 20px;
  }
`

export const SelectedButton = styled(Button)`
  &&& {
    background: ${({primary}) => (primary ? 'var(--primary)' : 'transparent')};
    color: ${({primary, disabled}) =>
      primary
        ? 'var(--secondary)'
        : disabled
        ? 'var(--asc-sonicsilver)'
        : 'var(--primary)'};
    :hover {
      background: var(--secondary);
      color: ${({primary, disabled}) =>
        primary
          ? 'var(--primary)'
          : disabled
          ? 'var(--asc-sonicsilver)'
          : 'var(--primary)'};
    }
    :focus,
    :active {
      background: ${({primary}) =>
        primary ? 'var(--primary)' : 'transparent'};
      color: ${({primary, disabled}) =>
        primary
          ? 'var(--secondary)'
          : disabled
          ? 'var(--asc-sonicsilver)'
          : 'var(--primary)'};
      :hover {
        background: ${({primary}) =>
          primary ? 'var(--secondary)' : 'var(--primary)'};
        color: ${({primary, disabled}) =>
          primary
            ? 'var(--primary)'
            : disabled
            ? 'var(--asc-sonicsilver)'
            : 'var(--primary)'};
      }
    }
  }
`

export const CommandButton = styled(Button)`
  &&& {
    background: var(--secondary);
    color: var(--primary);
    .icon {
      background-color: var(--primary) !important;
      color: var(--secondary);
    }
    :hover {
      color: var(--secondary);
      background: var(--primary);
    }
    :focus,
    :active {
      background: var(--secondary);
      color: var(--primary);
      :hover {
        color: var(--secondary);
        background: var(--primary);
      }
    }

    padding-left: 40px !important;
    padding-right: 20px !important;
    margin-right: 5px;
    @media ${QUERIES.tabletAndUp} {
      padding-left: 4.07142857em;
      padding-right: 1.5em;
    }
  }
`
export const ResetButton = styled(Button)`
  &&& {
    display: flex;
    flex-direction: row;
    align-items: Center;
    background: transparent;
    color: var(--primary);
    border: 1px solid var(--primary);
    i {
      color: var(--primary);
    }
    .icon {
      background: transparent !important;
      color: var(--asc-darkolive);
      border-right: 1px solid var(--primary);
    }
    :focus,
    :active {
      background: transparent;
      color: var(--primary);
      border: 1px solid var(--primary);
    }
  }
`
export const CameraIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  svg {
    margin-right: 10px;
  }
  .video {
    font-size: 18px;
  }
`
export const SpeedometerContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
`
export const ImageTitle = styled.div`
  background-color: var(--primary);
  padding: 10px;
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`
export const ImageNumber = styled.div`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 20px;
`
export const ImageDateTime = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
`
