import * as React from 'react'
import {useNavigate} from 'react-router-dom'
import {Dropdown} from 'semantic-ui-react'
import {useStore} from 'common/useStore'
import {trimUserName} from 'common/documents/fileUploadHelpers'
import styled from 'styled-components/macro'
import {
  Asterisk,
  CaretDown,
  CaretUp,
  SignOut,
  User,
  UserCircle,
  // UserSwitch,
} from 'phosphor-react'

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  cursor: pointer;
  span {
    color: var(--primary);
    font-weight: bold;
    font-size: 13px;
    padding-left: 5px;
  }
  .item {
    svg {
      display: inline;
      margin-right: 5px;
    }
  }
`
const StyledDropdown = styled(Dropdown)`
  &&& {
    font-family: 'Open Sans';
    div.menu {
      top: 30px;
      left: -112px;
      border: 1px solid var(--asc-graphite);
      padding: 5px;
      border-radius: 6px;
    }
    div.menu::before {
      content: '';
      display: block;
      position: absolute;
      left: 134px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: var(--asc-graphite);
    }
    div.menu::after {
      content: '';
      display: block;
      position: absolute;
      left: 135px;
      bottom: 99%;
      width: 0;
      height: 0;
      border: 9px solid transparent;
      border-bottom-color: white;
      bottom: calc(100% - 0.5px);
    }
    .selected.item {
      background: white;
      color: rgba(61, 73, 31, 0.66);
      &:hover {
        font-weight: 700;
        color: var(--primary);
      }
    }
    div.item {
      padding: 10px;
      font-weight: 700;
      color: rgba(61, 73, 31, 0.66);
      background-color: white;
      &:hover {
        background-color: var(--secondary);
        font-weight: 700;
        color: var(--primary);
      }
    }
    i.dropdown {
      display: none;
    }
  }
`
const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    margin-right: 5px;
    margin-left: 5px;
  }
`
const UserToolbarDropdown = ({setOpenModal}) => {
  const userConfig = useStore(state => state.userConfig)
  const impersonatingEmail = useStore(state => state.user.impersonatingEmail)
  const navigate = useNavigate()
  const logOutClick = () => {
    document.body.style.setProperty('--primary', '#3D491F')
    document.body.style.setProperty('--secondary', ' #B9CF33')
    navigate('/logout')
  }
  const [menuOpen, setMenuOpen] = React.useState(false)

  return (
    <Content>
      <StyledDropdown
        floating
        direction="left"
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        open={menuOpen}
        icon={
          <Trigger data-testid="topbar-userdropdown">
            <UserCircle
              size={23}
              weight={'fill'}
              color={
                impersonatingEmail ? 'var(--asc-vermilion)' : 'var(--primary)'
              }
            />

            <span>
              {trimUserName(
                `${userConfig?.First_Name || ''} ${
                  userConfig?.Last_Name || ''
                }`,
              )}
            </span>
            {menuOpen ? (
              <CaretUp size={12} weight="fill" color={'var(--primary)'} />
            ) : (
              <CaretDown size={12} weight="fill" color={'var(--primary)'} />
            )}
          </Trigger>
        }
      >
        <Dropdown.Menu>
          <Dropdown.Item
            data-testid="userdropdown-userprofile"
            icon={<User size={15} weight={'fill'} color={'var(--primary)'} />}
            text="User Profile"
            onClick={() => setOpenModal('userProfile')}
          />
          <Dropdown.Item
            data-testid="userdropdown-changepassword"
            icon={
              <Asterisk size={15} weight={'bold'} color={'var(--primary)'} />
            }
            text="Change Password"
            onClick={() => setOpenModal('changePassword')}
          />
          <Dropdown.Item
            data-testid="userdropdown-logout"
            icon={
              <SignOut size={15} weight={'fill'} color={'var(--primary)'} />
            }
            text="Logout"
            onClick={logOutClick}
          />
        </Dropdown.Menu>
      </StyledDropdown>
    </Content>
  )
}
export default UserToolbarDropdown
