import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

export type CustomerType = {
  idcustomer?: number
  customer_name?: string
  CRM_Account_Nbr?: string
  Dealer_Notes?: string
  CustomerType_Label?: string
  Dealer_Name?: string
  FullCustomerAddress?: string
  Latitude?: number
  Longitude?: number
  devicecount?: number
  assetcount?: number
  idDealer?: number
  Created_On: string
  DeviceList?: string
  Dealer_Type?: string
  CRM_ID?: string
  AccountCustomerCount?: number
}

const getCustomers = async () => {
  const {data}: {data: CustomerType[]} = await Axios.get(`/customers`)
  return data
}

const useCustomers = () => {
  const user = useStore(state => state.user)
  return useQuery(['customers'], () => getCustomers(), {
    enabled: Boolean(user.token),
  })
}

export default useCustomers
