import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {subHours} from 'date-fns'
import {formatInTimeZone} from 'date-fns-tz'

const getAlertEvents = async ({dateRange}) => {
  const userConfig = useStore.getState().userConfig
  if (dateRange) {
    const dateFormat = 'MM/dd/yyyy%20HH:mm:ss'
    const startDate = formatInTimeZone(
      dateRange.startDate,
      userConfig.TZ_Moment_Name,
      dateFormat,
    )
    const endDate = formatInTimeZone(
      dateRange.endDate,
      userConfig.TZ_Moment_Name,
      dateFormat,
    )
    const {data} = await Axios.get(
      `/alertevents?after=${startDate}&before=${endDate}`,
    )
    return data
  }
  return
}

export default function useAlertEvents(
  dateRange = {
    startDate: subHours(new Date(), 24),
    endDate: new Date(),
  },
) {
  const user = useStore(state => state.user)
  return useQuery(['alertEvents'], () => getAlertEvents({dateRange}), {
    enabled: Boolean(user?.token),
  });
}
