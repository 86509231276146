import * as React from 'react'
import {useVirtual} from 'react-virtual'
import styled from 'styled-components/macro'
import AssetCard from './AssetCard'
import Spinner from 'common/components/Spinner'
import AddAssetButton from 'common/components/AddAssetButton'
import {useStore} from 'common/useStore'
import useWindowSize from 'common/useWindowSize'

const ListContainer = styled.div`
  overflow-y: auto; // this is necessary for virtual list to work
`
const NoRecords = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  font-weight: bold;
`

const AssetList = ({
  activeMarker,
  setAndFocusActiveMarker,
  assets,
  filteredAssets,
  status,
}) => {
  const setAssetListOpen = useStore(state => state.setAssetListOpen)
  const {isMobile} = useWindowSize()
  const parentRef = React.useRef()
  const activeIDRef = React.useRef()
  const scrollingRef = React.useRef()

  function easeInOutQuint(t) {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
  }
  const scrollToFn = React.useCallback(
    (offset, defaultScrollTo) => {
      const duration = filteredAssets?.length > 2000 ? 0 : 1000
      if (parentRef?.current) {
        const start = parentRef.current.scrollTop
        const startTime = (scrollingRef.current = Date.now())

        const run = () => {
          if (scrollingRef.current !== startTime) return
          const now = Date.now()
          const elapsed = now - startTime
          const progress = easeInOutQuint(Math.min(elapsed / duration, 1))
          const interpolated = start + (offset - start) * progress

          if (elapsed < duration) {
            defaultScrollTo(interpolated)
            requestAnimationFrame(run)
          } else {
            defaultScrollTo(interpolated)
          }
        }

        requestAnimationFrame(run)
      }
    },
    [filteredAssets?.length],
  )

  const {virtualItems, totalSize, scrollToIndex} = useVirtual({
    size: filteredAssets?.length || 0,
    parentRef,
    overscan: 10,
    estimateSize: React.useCallback(() => 154, []),
    scrollToFn,
  })

  const changeActiveMarkerFromClick = e => {
    const id = e.currentTarget.dataset.id
    const asset = assets.find(asset => asset.idAsset === Number(id))
    if (asset) {
      const newActiveMarker = {
        idDevice: asset.idDevice,
        idAsset: asset.idAsset,
        label: asset.Asset_Label,
        lat: asset.Device.Latitude,
        lng: asset.Device.Longitude,
        following: true,
        heading: asset.Device.Last_Heading,
      }
      setAndFocusActiveMarker(newActiveMarker)
      if (isMobile) {
        setAssetListOpen(false)
      }
    }
  }

  React.useEffect(() => {
    if (activeMarker?.idAsset && activeMarker.idAsset !== activeIDRef.current) {
      const assetIndex = filteredAssets.findIndex(
        asset => asset.idAsset === Number(activeMarker?.idAsset),
      )
      if (assetIndex || assetIndex === 0) {
        scrollToIndex(assetIndex)
      }
      activeIDRef.current = activeMarker.idAsset
    }
  }, [activeMarker?.idAsset, filteredAssets, scrollToIndex])

  return (
    <ListContainer ref={parentRef} data-cy="opening-asset-list">
      {status === 'loading' ? (
        <Spinner type="partial" />
      ) : (
        <React.Fragment>
          <div
            className="ListInner"
            style={{
              height: `${totalSize + 30}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {virtualItems.map(virtualRow => {
              const assetDetail = filteredAssets[virtualRow.index]
              return (
                <div
                  key={virtualRow.index}
                  ref={virtualRow.measureRef}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  <AssetCard
                    key={virtualRow.index}
                    index={virtualRow.index}
                    activeMarker={activeMarker}
                    assetDetail={assetDetail}
                    changeActiveMarkerFromClick={changeActiveMarkerFromClick}
                  />
                </div>
              )
            })}
          </div>
          {assets?.length === 0 ? (
            <AddAssetButton />
          ) : filteredAssets?.length === 0 || !filteredAssets ? (
            <NoRecords>No Records Found</NoRecords>
          ) : (
            <></>
          )}
        </React.Fragment>
      )}
    </ListContainer>
  )
}
export default AssetList
