import * as React from 'react'
import styled from 'styled-components/macro'
import useAvailableRecordingDates from './hooks/useAvailableRecordingDates'
import {useStore} from 'common/useStore'
import {format} from 'date-fns'
import {Spinner} from 'common/components'

const Container = styled.div`
  display: flex;
  font-size: 16px;
  color: var(--asc-sonicsilver);
  padding-left: 10px;
  align-items: center;
  white-space: nowrap;
  width: 50px;
`

const EarliestRecordingDate = ({idDevice}: {idDevice: number}) => {
  const {data: availableRecordingDates, status} =
    useAvailableRecordingDates(idDevice)
  const [firstDateFromCamera, setFirstDateFromCamera] = React.useState<
    string | null
  >(null)
  const userConfig = useStore(state => state.userConfig)
  React.useEffect(() => {
    if (availableRecordingDates?.length) {
      //api returns an array of objects where the key of the object is the date and the value is true/false if the date is available
      //loop through array and find first value that is true and use that date
      for (let index = 0; index < availableRecordingDates.length; index++) {
        if (Object.values(availableRecordingDates[index])[0]) {
          const firstDate = Object.keys(availableRecordingDates[index])[0]
          const formattedFirstDate = format(
            new Date(firstDate),
            userConfig?.Date_Format || 'MM/dd/yyyy',
          )
          setFirstDateFromCamera(formattedFirstDate)
          break
        }
      }
    }
  }, [availableRecordingDates, userConfig?.Date_Format, idDevice])

  return (
    <Container>
      Oldest Video on SD Card:{' '}
      {status === 'loading' ? (
        <Spinner type="partial" size="30px" />
      ) : (
        firstDateFromCamera
      )}
    </Container>
  )
}
export default EarliestRecordingDate
