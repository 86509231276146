import styled from 'styled-components/macro'

export const Container = styled.div`
  &&& {
    .field {
      margin: 0;
    }
  }
`
export const InputContainer = styled.div`
  margin-bottom: 15px;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 10px;
  &&& {
    .wide {
      /* margin-right: 20px; */
    }
  }
`
export const ErrorText = styled.div`
  color: #d9534f;
  font-style: italic;
`
