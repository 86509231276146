import * as React from 'react'
import {NavLink} from 'react-router-dom'
import FlyoutMenuContainer from './FlyoutMenuContainer'
import styled from 'styled-components/macro'
import {CaretRight} from 'phosphor-react'
import Axios from 'axios'
import {TRANZTEC_SSO_BASE} from 'common/constants'
import {useStore} from './useStore'
import {SubMenuState} from './mainMenuHelpers'

type Props = {
  subitem: SubMenuState
  collapse: boolean
  dataTestId?: string
}
type SubMenuItemProps = {
  collapse: string
  shouldhover: string
}

const routingAndDispatchClick = async () => {
  const {data} = await Axios.get('/sso/partners/tranztec/token')
  if (data) {
    window.open(
      `${TRANZTEC_SSO_BASE}/partner/temeda/sso?access_token=${data.accessToken}`,
      'routingAndDispatch',
    )
  }
}

const SubMenuItem = styled(NavLink)<SubMenuItemProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0px 3px 46px;
  color: var(--primary);
  font-size: 13px;
  display: ${props => (props.collapse === 'true' ? 'none' : 'flex')};
  position: relative;
  background-color: ${props =>
    props.shouldhover === 'true' ? 'var(--secondary)' : 'transparent'};
  &:hover {
    background-color: var(--secondary);
    color: var(--primary);
  }
`
const FlyoutMenu = ({subitem, collapse, dataTestId}: Props) => {
  const userConfig = useStore(state => state.userConfig)
  const [enableFlyOut, setEnableFlyOut] = React.useState(false)
  const [flyOpen, setFlyOpen] = React.useState(false)
  const [menuTopPosition, setMenuTopPosition] = React.useState(-10000)
  const [flyoutHover, setFlyoutHover] = React.useState(false)

  React.useEffect(() => {
    setEnableFlyOut(
      subitem.text.toLowerCase() === 'fuel cards' ||
        subitem.text.toLowerCase() === 'orders' ||
        subitem.text.toLowerCase() === 'routes' ||
        subitem.text.toLowerCase() === 'setup' ||
        subitem.text.toLowerCase() === 'cameras',
    )
  }, [subitem])

  const onMouseEnter = (
    element: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>,
  ) => {
    if (enableFlyOut) {
      var rect = element.currentTarget.getBoundingClientRect()
      setMenuTopPosition(rect.top)
      setFlyOpen(true)
    }
  }
  const onMouseLeave = () => {
    if (enableFlyOut) {
      setMenuTopPosition(-1000)
      setFlyOpen(false)
    }
  }
  const setFlyoutHovered = (hovered: boolean) => {
    setFlyoutHover(hovered)
  }

  const commonItems = [
    {
      navigateTo: `/routing/setup/route-settings`,
      label: 'Route Default Settings',
    },
    {
      navigateTo: `/routing/setup/route-locations`,
      label: 'Start/End Points',
    },
    {
      navigateTo: `/routing/setup/service-types`,
      label: 'Service Types',
    },
    {
      navigateTo: `/routing/setup/customer-notifications`,
      label: 'Customer Notifications',
    },
    {
      navigateTo: `/routing/setup/driver-settings`,
      label: 'Driver Settings',
    },
    {
      navigateTo: `/routing/setup/driver-attributes`,
      label: 'Driver Attributes',
    },
  ]

  const viaItem = {
    onClick: routingAndDispatchClick,
    label: 'VIA (Legacy)',
  }
  const items =
    userConfig && userConfig.IsTemedaAdminAndIsImpersonated
      ? [...commonItems, viaItem]
      : commonItems

  return (
    <>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onMouseLeave}
      >
        <SubMenuItem
          collapse={String(collapse)}
          to={subitem.link}
          target={subitem.newWindow ? '_blank' : ''}
          rel="opener"
          id={subitem.name}
          shouldhover={flyoutHover.toString()}
          data-testid={dataTestId}
        >
          {subitem.text}
          {enableFlyOut && <CaretRight size={16} />}
        </SubMenuItem>
        {subitem.text.toLowerCase() === 'fuel cards' && (
          <FlyoutMenuContainer
            visible={flyOpen}
            setFlyoutHovered={setFlyoutHovered}
            menuTopPosition={menuTopPosition}
            items={[
              {navigateTo: `/fuel-cards/list`, label: 'Assignments'},
              {
                navigateTo: `/fuel-cards/transactions`,
                label: 'Transactions',
              },
            ]}
            dataTestId={dataTestId}
          />
        )}
        {subitem.text.toLowerCase() === 'cameras' && (
          <FlyoutMenuContainer
            visible={flyOpen}
            setFlyoutHovered={setFlyoutHovered}
            menuTopPosition={menuTopPosition}
            items={[
              {navigateTo: `/cameras/list`, label: 'Camera List'},
              {
                navigateTo: `/cameras/cameraevents`,
                label: 'Camera Events',
              },
              {
                navigateTo: `/cameras/settings`,
                label: 'Camera Settings',
              },
            ]}
            dataTestId={dataTestId}
          />
        )}
        {subitem.text.toLowerCase() === 'setup' && (
          <FlyoutMenuContainer
            setFlyoutHovered={setFlyoutHovered}
            visible={flyOpen}
            menuTopPosition={menuTopPosition}
            items={items}
            dataTestId={dataTestId}
          />
        )}
        {subitem.text.toLowerCase() === 'orders' && (
          <FlyoutMenuContainer
            visible={flyOpen}
            setFlyoutHovered={setFlyoutHovered}
            menuTopPosition={menuTopPosition}
            items={[
              {navigateTo: `/routing/orders/import`, label: 'Import Orders'},
              {
                navigateTo: `/routing/orders/order`,
                label: 'Add Order',
              },
              {navigateTo: `/routing/orders/locations`, label: 'Locations'},
              {navigateTo: `/routing/orders/list`, label: 'Orders'},
            ]}
            dataTestId={dataTestId}
          />
        )}
        {subitem.text.toLowerCase() === 'routes' && (
          <FlyoutMenuContainer
            visible={flyOpen}
            setFlyoutHovered={setFlyoutHovered}
            menuTopPosition={menuTopPosition}
            items={[
              {
                navigateTo: `/routing/routes/create-route`,
                label: 'Create Route',
              },
              {
                navigateTo: `/routing/routes/list`,
                label: 'View Routes',
              },
              {
                navigateTo: `/routing/editing`,
                label: 'Adjust Routes',
              },
            ]}
            dataTestId={dataTestId}
          />
        )}
      </div>
    </>
  )
}
export default FlyoutMenu
