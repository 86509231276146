import * as React from 'react'
import {PropsWithChildren} from 'react'
import {DialogContent, DialogOverlay} from '@reach/dialog'
import '@reach/dialog/styles.css'
import styled from 'styled-components/macro'
import {ModalHeader, Button, Icon} from 'semantic-ui-react'
import {X, WarningCircle, MapPin} from 'phosphor-react'
import {
  InfoPill,
  InfoText,
  IconContainer,
} from 'common/components/StyledComponents'

const ModalContainer = styled(DialogContent)`
  button {
    white-space: nowrap;
  }
  margin: 10px auto;
  max-width: ${(props: {maxWidth?: string; width: string; height: string}) =>
    props.maxWidth || '100vw'};
  max-height: 98vh;
  position: relative;
  display: flex;
  flex-direction: column;
  &&& {
    h2,
    span {
      font-family: 'Open Sans', sans-serif;
    }
    border-radius: 20px;
    padding: 0;
    width: ${(props: {width: string; height: string}) => props.width};
    height: ${props => props.height};
    z-index: 2;
  }
`
const Header = styled.div`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: ${({tabs}: {tabs?: boolean; title?: string; subTitle?: string}) =>
    tabs ? '20px 20px 0 20px' : '20px'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--asc-coolgray);
`
const Footer = styled(ModalHeader)`
  background-color: white;
  padding: 20px 20px;
  margin: 0;
  border-top: 1px solid var(--asc-coolgray);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  justify-content: flex-end;
  display: flex;
  width: 100%;
`
const CloseArea = styled.div`
  padding: 10px;
  cursor: pointer;
`

const SaveButton = styled(Button)`
  &&& {
    white-space: nowrap;
    background: var(--secondary);
    color: var(--primary);
    text-transform: uppercase;
    :hover {
      background: var(--primary);
      color: var(--secondary);
    }
    :active {
      background: var(--secondary) !important;
      color: var(--primary) !important;
    }
    :focus {
      background: var(--secondary) !important;
      color: var(--primary) !important;
    }
    :disabled {
      cursor: not-allowed;
      background-color: var(--asc-platinum);
      color: var(--asc-dimgray);
    }
  }
`
const CancelButton = styled(Button)`
  background-color: transparent !important;
  color: var(--primary) !important;
  :hover {
    background-color: var(--asc-platinum) !important;
  }
`
const SubTitle = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-top: 10px;
  color: var(--asc-black);
  width: 100%;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: ${({scrollable}: {scrollable: boolean; renderFooter: boolean}) =>
    scrollable ? 'hidden' : 'initial'};
  flex-grow: 1;
  input,
  textarea {
    //border: 1px solid var(--asc-graphite) !important;
    :focus {
      border: 1px solid var(--asc-moss) !important;
      box-sizing: border-box;
      box-shadow: 0px 0px 6px 1px rgba(185, 207, 51, 0.6) !important;
      outline: 0;
    }
  }
  border-radius: ${(props: {renderFooter: boolean}) =>
    props.renderFooter ? '0' : '0 0 20px 20px'};
`
const RequiredText = styled.span`
  margin-top: -5px;
  font-size: 14px;
  :before {
    content: '*';
    font-style: italic;
    font-size: 0.8rem;
    color: var(--error-text);
    padding-right: 3px;
  }
`
const TabButton = styled.button`
  z-index: 1;
  display: flex;
  justify-content: space-around;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  align-items: center;
  height: 100%;
  font-size: 13px;
  padding: 0 0 10px 0;
  font-weight: ${({active}: {active: boolean; text: string}) =>
    active ? '700' : '400'};
  box-shadow: ${({active}) =>
    active ? 'inset 0 -5px var(--secondary)' : 'none'};
  :hover {
    box-shadow: inset 0 -5px var(--secondary);
  }
  border: 0;
  background: transparent;
  :after {
    content: ${({text}) => (text ? `"${text}"` : '')};
  }
`
const TabButtonContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
`

const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const ScrollableBody = styled.div`
  overflow: ${({scrollable}: {scrollable: boolean}) =>
    scrollable ? 'auto' : 'initial'};
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  background: var(--asc-lavender);
  border: 1px solid var(--asc-vermilion);
  text-align: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  color: var(--asc-vermilion);
  svg {
    margin-right: 5px;
    margin-top: 2px;
  }
`

const CloseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledDialogOverlay = styled(DialogOverlay)`
  overflow: visible;
`

const ModalHeaderTitle = styled.h2`
  margin-bottom: 0;
`

type Tab = {
  text: string
  active: boolean
  onClick: () => void
  small: boolean
  color: string
  testId?: string
}

type Props = {
  showDialog: boolean
  className?: string
  title: string | React.ReactElement
  ariaLabel?: string
  subTitle?: string | React.ReactElement
  width?: string
  maxWidth?: string
  height?: string
  disabled?: boolean
  onSave?: () => void
  onDismiss: () => void
  renderFooter?: boolean
  submitButtonText?: string
  showRequiredNote?: boolean
  saveButtonInitialFocus?: boolean
  tabs?: Tab[]
  submitting?: boolean
  errorMessage?: string
  hideCancelButton?: boolean
  hideHeader?: boolean
  showInfoPill?: boolean
  infoPillText?: string
  infoPillBackgroundColor?: string
  viewOnly?: boolean
  showClose?: boolean
  testIdName?: string //used for pendo tagging, pass the base of the id. ex. filterpanel
  scrollable?: boolean
}

const Modal = ({
  showDialog,
  className = '',
  children,
  title = '',
  ariaLabel = 'Ascend Modal',
  subTitle = '',
  width = '400px',
  maxWidth,
  height = 'default',
  disabled = false,
  onSave,
  onDismiss,
  renderFooter = false,
  submitButtonText = '',
  showRequiredNote = false,
  // loading = false,
  saveButtonInitialFocus = false,
  tabs = [],
  submitting = false,
  errorMessage = '',
  hideCancelButton = false,
  hideHeader = false,
  showInfoPill = false,
  infoPillText = '',
  infoPillBackgroundColor = '',
  viewOnly = false,
  showClose = true,
  testIdName = '',
  scrollable = true,
}: PropsWithChildren<Props>) => {
  const buttonRef = React.useRef()

  return (
    <div className="parent-dialog">
      <StyledDialogOverlay
        initialFocusRef={saveButtonInitialFocus ? buttonRef : undefined}
        isOpen={showDialog}
        className={className}
      >
        <ModalContainer
          // onDismiss={onDismiss}
          data-cy={'ascend-modal'}
          width={width}
          maxWidth={maxWidth}
          height={height}
          role="dialog"
          className="modal-container"
          aria-labelledby={ariaLabel}
        >
          {!hideHeader && (
            <Header className={'modal-header'}>
              <Row>
                <ModalHeaderTitle>{title}</ModalHeaderTitle>

                {showClose && (
                  <CloseContainer>
                    {(viewOnly || showInfoPill) && (
                      <InfoPill
                        data-cy={'info-pill'}
                        infoPillBackgroundColor={infoPillBackgroundColor}
                      >
                        <IconContainer>
                          <MapPin color={'#000000'} size={12} weight="fill" />
                        </IconContainer>
                        <InfoText data-cy={'info-pill-text'}>
                          {infoPillText}
                        </InfoText>
                      </InfoPill>
                    )}
                    {(viewOnly || !showInfoPill) && (
                      <CloseArea
                        data-cy={'modal-dismiss'}
                        onClick={onDismiss}
                        data-testid={`${testIdName}-close`}
                      >
                        <X size={20} />
                      </CloseArea>
                    )}
                  </CloseContainer>
                )}
              </Row>
              {subTitle ? (
                <Row>
                  <SubTitle>{subTitle}</SubTitle>
                </Row>
              ) : null}
              {showRequiredNote && (
                <Row>
                  <RequiredText>Required</RequiredText>
                </Row>
              )}
              <Row>
                {tabs && tabs.length > 0 && (
                  <TabButtonContainer>
                    {tabs.map((tab, index) => (
                      <TabButton
                        key={index}
                        text={tab.text}
                        active={tab.active}
                        onClick={tab.onClick}
                        color={tab.color}
                        data-testid={tab.testId}
                      />
                    ))}
                  </TabButtonContainer>
                )}
              </Row>
            </Header>
          )}
          <ModalContent
            className={'modal-content'}
            scrollable={scrollable}
            renderFooter={renderFooter}
          >
            {/* id needed for scrolling with javascript */}
            <ScrollableBody id="scrollableModalBody" scrollable={scrollable}>
              {children}
            </ScrollableBody>
          </ModalContent>
          {renderFooter /*Sorrrrry, I know we are anti-class, this is a bandaid and I need to investigate why we have this absolute*/ && (
            <Footer className={'modal-footer'}>
              <FooterRow>
                {errorMessage !== '' ? (
                  <ErrorMessage>
                    <WarningCircle
                      color={'var(--asc-vermilion)'}
                      size={16}
                      weight="fill"
                    />
                    {errorMessage}
                  </ErrorMessage>
                ) : (
                  <div></div>
                )}
                <div>
                  {!hideCancelButton && (
                    <CancelButton
                      data-cy={'modal-cancel'}
                      name="cancel"
                      type="button"
                      onClick={onDismiss}
                      data-testid={`${testIdName}-cancel`}
                    >
                      <Icon name="remove" />
                      CANCEL
                    </CancelButton>
                  )}

                  <SaveButton
                    data-cy={'modal-submit'}
                    ref={buttonRef}
                    disabled={disabled}
                    onClick={onSave}
                    loading={submitting}
                    data-testid={`${testIdName}-${
                      submitButtonText
                        ? submitButtonText.split(' ')[0].toLowerCase()
                        : 'save'
                    }`}
                  >
                    {submitButtonText ? submitButtonText : 'Save'}
                  </SaveButton>
                </div>
              </FooterRow>
            </Footer>
          )}
        </ModalContainer>
      </StyledDialogOverlay>
    </div>
  )
}

export default Modal
