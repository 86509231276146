import {saveAsPDF} from 'common/tables/pdfExport'
import {saveAsExcel} from 'common/tables/excelExport'
import {saveAsCSV} from 'common/tables/csvExport'
import {format} from 'date-fns'
import {useStore} from 'common/useStore'
import {ColumnDef} from '@tanstack/react-table'

type GetExportFileBlobProps = {
  columns: ColumnDef<unknown>[]
  data: unknown[][]
  fileType: 'csv' | 'xlsx' | 'pdf'
  fileName: string
}

export function getExportFileBlob({
  columns,
  data,
  fileType,
  fileName,
}: GetExportFileBlobProps) {
  if (fileType === 'csv') {
    saveAsCSV(columns, data, fileName)
  } else if (fileType === 'xlsx') {
    saveAsExcel({columns, data, fileName})
    return false
  } else if (fileType === 'pdf') {
    saveAsPDF(columns, data, fileName)
    return false
  }
  return false
}

// export const compareIgnoreCase = (a: string, b: string) => {
//   let _a = a && typeof a === 'string' ? a?.toLowerCase() : a || ''
//   let _b = b && typeof b === 'string' ? b?.toLowerCase() : b || ''
//   if (_a < _b) return -1
//   if (_a > _b) return 1
//   return 0
// }

// export const changePageAndScroll = (
//   type,
//   scrollRef,
//   nextPage,
//   previousPage,
// ) => {
//   const Y_OFFSET = 200
//   const topOfElement =
//     scrollRef.current.getBoundingClientRect().top + window.scrollY

//   // window.scrollTo({
//   //   top: topOfElement - Y_OFFSET,
//   //   behavior: 'smooth',
//   // })

//   scrollRef.current.scrollTo({
//     top: topOfElement - Y_OFFSET,
//     behavior: 'smooth',
//   })
//   if (type === 'next') {
//     nextPage()
//   } else {
//     previousPage()
//   }
// }

/**
 *
 * @param {table data} param0
 * @param {* fraction digits default to 1 } fractionDigits
 * @returns
 */
export const oneDecimalRender = (
  {value}: {value: number | undefined},
  fractionDigits = 1,
) => {
  if (value === undefined || value === null) return ''
  let formattedValue = String(value)
  if (value && typeof value === 'number') {
    formattedValue = value.toLocaleString(undefined, {
      minimumFractionDigits: Number.isInteger(fractionDigits)
        ? fractionDigits
        : undefined,
      maximumFractionDigits: Number.isInteger(fractionDigits)
        ? fractionDigits
        : undefined,
    })
  }
  return formattedValue
}

export const formatDate = ({value}: {value: Date | string | number}) => {
  const user = useStore.getState().userConfig
  return value
    ? format(
        new Date(value),
        user?.Date_Format ? user?.Date_Format : 'MM/dd/yyyy',
      )
    : null
}

export const formatDateTime = ({value}: {value: Date | string | number}) => {
  const user = useStore.getState().userConfig
  const timeFormat = user?.Time_Format === '12HR' ? 'h:mm a' : 'H:mm'
  return value
    ? format(
        new Date(value),
        user?.Date_Format
          ? user.Date_Format + ' ' + timeFormat
          : 'M/d/yyyy h:mm a',
      )
    : null
}

export const measureFn = (estimateSize?: number) => {
  if (
    typeof window !== 'undefined' &&
    navigator.userAgent.indexOf('Firefox') === -1
  ) {
    return (element: Element) =>
      element?.getBoundingClientRect().height || estimateSize || 60 // use estimated if height is 0 for testing
  } else {
    return () => estimateSize || 60
  }
}
