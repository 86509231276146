import * as React from 'react'
import styled from 'styled-components/macro'
import {Spinner} from 'common/components'
import {ascendColors} from 'common/GlobalStyles'
import useAuthConfig from './hooks/useAuthConfig'

const Container = styled.div`
  display: flex;
  .lytx-rec-timeline {
    width: 1000px;
  }
`

const SurfsightRecordingTimeline = ({idDevice}: {idDevice: number}) => {
  const lytxTimelineRef = React.useRef<HTMLDivElement | null>(null)
  const {data: authConfigData, status} = useAuthConfig(idDevice)

  React.useEffect(() => {
    let resizeObserverEntries: ResizeObserverEntry[] = []

    const observer = new ResizeObserver(entries => {
      const element = document.getElementById('scrollableModalBody')
      resizeObserverEntries = entries
      for (let entry of entries) {
        if (element) {
          element.scrollTop = entry.contentRect.height
        }
      }
    })

    if (lytxTimelineRef.current) observer.observe(lytxTimelineRef.current)

    return () => {
      resizeObserverEntries.forEach(entry => entry.target.remove())
      observer.disconnect()
    }
  }, [authConfigData])

  React.useEffect(() => {
    const script = document.createElement('script')
    script.type = 'module'
    script.src =
      'https://ui-components.surfsight.net/latest/build/cloud-ui-components.esm.js'
    document.head.appendChild(script)
    return () => {
      document.head.removeChild(script)
    }
  }, [])

  if (status === 'loading') {
    return <Spinner />
  } else if (status === 'error') {
    return (
      <div>
        There was an error loading the timeline, please try again later.
      </div>
    )
  }

  return (
    <Container>
      {authConfigData ? (
        <lytx-recording-timeline
          ref={lytxTimelineRef}
          class="lytx-rec-timeline"
          auth-token={authConfigData.authToken}
          organization-id={authConfigData.idOrganization}
          imei={authConfigData.imei}
          title-display={false}
          driver-name={false}
          description-display={false}
          primary-color={ascendColors.moss}
        ></lytx-recording-timeline>
      ) : null}
    </Container>
  )
}
export default SurfsightRecordingTimeline
