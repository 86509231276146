import React from 'react'
import styled from 'styled-components/macro'

type StyledButtonsProps = {
  buttonPadding?: string
  hasError?: boolean
}

const StyledButtons = styled.div<StyledButtonsProps>`
  margin-left: 10px;
  cursor: pointer;
  padding: 0px;
  border: ${({hasError}: {hasError?: boolean}) =>
    hasError ? '1px solid var(--asc-vermilion)' : '1px solid var(--primary)'};
  display: flex;
  justify-content: center;
  border-radius: 6px;
  outline: none;
  width: fit-content;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  div {
    padding: ${({buttonPadding}: {buttonPadding?: string}) =>
      buttonPadding ? buttonPadding : '10px 20px'};
    align-items: center;
  }
  div:first-child {
    border-radius: 5px 0px 0px 5px;
  }
  div:last-child {
    border-radius: 0px 5px 5px 0px;
  }
`

const StyledButton = styled.div`
  background-color: ${({
    value,
    selected,
    hasError,
  }: {
    value: string
    selected: string
    hasError: boolean
  }) =>
    hasError && value === selected
      ? 'var(--asc-vermilion)'
      : value === selected
      ? 'var(--primary)'
      : '#ffffff'};
  color: ${({
    value,
    selected,
    hasError,
  }: {
    value: string
    selected: string
    hasError: boolean
  }) =>
    hasError && value === selected
      ? 'white'
      : hasError
      ? 'var(--asc-vermilion)'
      : value === selected
      ? 'var(--secondary)'
      : 'var(--primary)'};
`

type Option = {
  value: string
  text: string
}

type Props = {
  options: Option[]
  selected: string
  setSelected: (selected: string) => void
  buttonPadding?: string
  className?: string
  hasError?: boolean
}

const RadioButtonGroup = ({
  options,
  selected,
  setSelected,
  buttonPadding,
  className = '',
  hasError = false,
}: Props) => (
  <StyledButtons
    className={className}
    buttonPadding={buttonPadding}
    hasError={hasError}
  >
    {options.map(option => {
      return (
        <StyledButton
          key={option.value}
          value={option.value}
          selected={selected}
          onClick={() => setSelected(option.value)}
          hasError={hasError}
        >
          {option.text}
        </StyledButton>
      )
    })}
  </StyledButtons>
)
export default RadioButtonGroup
