import styled from 'styled-components/macro'
import {Button} from 'common/components'

import {QUERIES} from 'common/constants'

export const StyledButton = styled(Button)`
  text-transform: uppercase;
  background: var(--secondary);
  color: var(--primary);
  font-weight: bold;
  font-size: 13px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  max-height: 35px;
  @media ${QUERIES.tabletAndUp} {
    padding: 10px 15px;
  }
  :hover {
    color: var(--secondary);
    background: var(--primary);
  }
  svg {
    min-width: 16px;
    min-height: 16px;
  }
`
