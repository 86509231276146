import * as React from 'react'
import styled from 'styled-components/macro'
import MainMenu from 'common/MainMenu'
import Header from 'common/Header'
import {QUERIES} from 'common/constants'

const RootDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  @media print {
    height: fit-content !important;
  }
`

const MainDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  @media print {
    height: fit-content !important;
  }
`

const MainAreaWrapper = styled.div`
  flex: 1;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: var(--asc-cultured);
  display: flex;
  flex-direction: column;
  @media print {
    height: fit-content !important;
  }
`

const MobileMainMenu = styled(MainMenu)`
  @media ${QUERIES.tabletAndUp} {
    display: none;
  }
`

const DesktopMainMenu = styled(MainMenu)`
  display: none;
  @media ${QUERIES.tabletAndUp} {
    display: flex;
    flex-direction: column;
  }
`

type AppLayoutProps = {
  showMainMenuHeader: boolean
  title?: string | undefined
  children: React.ReactNode
}

const AppLayout = (props: AppLayoutProps) => {
  return (
    <RootDiv>
      <MainDiv>
        <DesktopMainMenu
          isMobile={false}
          title={props.title || ''}
          showMainMenuHeader={props.showMainMenuHeader}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <MobileMainMenu
            isMobile={true}
            title={props.title || ''}
            showMainMenuHeader={props.showMainMenuHeader}
          />
          {props.showMainMenuHeader && (
            <Header showMainMenuHeader={props.showMainMenuHeader} />
          )}
          <MainAreaWrapper>{props.children}</MainAreaWrapper>
        </div>
      </MainDiv>
    </RootDiv>
  )
}

export default AppLayout
