import * as React from 'react'
import styled from 'styled-components/macro'
import {Warning, IconProps, IconWeight} from 'phosphor-react'
import {Dropdown, SemanticCOLORS} from 'semantic-ui-react'

type ContainerPropsType = {
  disabled?: boolean
  color?: string
  hovercolor?: string
  nohover?: boolean
}

const Container = styled.div<ContainerPropsType>`
  z-index: 1;
  display: flex;
  justify-content: space-around;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  align-items: center;
  height: 100%;
  font-size: 13px;
  padding: 14px 20px 10px 20px;
  border: 1px solid transparent;
  svg {
    fill: ${({color, disabled}) =>
      disabled ? 'var(--asc-graphite)' : color ? color : 'var(--primary)'};
    min-height: 16px;
    min-width: 16px;
  }
  &:hover {
    border: ${({color, disabled}) =>
      disabled
        ? null
        : color
        ? `1px solid ${color}`
        : '1px solid var(--primary)'};
    background-color: ${({hovercolor, disabled, nohover}) =>
      disabled
        ? null
        : nohover
        ? 'var(--asc-graphite)'
        : hovercolor
        ? hovercolor
        : 'var(--asc-selectedgreen)'};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 7px;
  color: ${({
    color,
    disabled,
  }: {
    color?: string
    disabled: boolean
    iconPosition: 'left' | 'right'
  }) => (disabled ? 'var(--asc-graphite)' : color ? color : 'var(--primary)')};
  svg {
    margin: ${({iconPosition}) =>
      iconPosition === 'left' ? '0 5px 0 0' : '0 0 0 10px'};
  }
`
const Text = styled.span`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 13px;
  color: ${({color, disabled}: {color?: string; disabled: boolean}) =>
    disabled ? 'var(--asc-graphite)' : color ? color : 'var(--primary)'};
`
const StyledDropdown = styled(Dropdown)`
  &&& {
    font-family: 'Open Sans';
    div.menu {
      top: 30px;
      left: -80px;
      border: 1px solid var(--asc-graphite);
      padding: 5px;
      border-radius: 6px;
    }
    div.menu::before {
      content: '';
      display: block;
      position: absolute;
      left: 95px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: var(--asc-graphite);
    }
    div.menu::after {
      content: '';
      display: block;
      position: absolute;
      left: 96px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 9px solid transparent;
      border-bottom-color: white;
      bottom: calc(100% - 0.5px);
    }
    .selected.item {
      background: white;
      color: var(--primary);
      &:hover {
        font-weight: 700;
        color: var(--primary);
      }
    }
    div.item {
      padding: 10px;
      font-weight: 700;
      color: var(--primary);
      background-color: white;
      &:hover {
        background-color: var(--secondary);
        font-weight: 700;
        color: var(--primary);
      }
    }
    i.dropdown {
      display: none;
    }
  }
`
const StyledImageIcon = styled.img`
  height: 18px;
  margin: 0px 5px;
`

interface ButtonPropsType {
  text: string
  color?: string
  fill?: IconWeight
  badgeContent?: string | null
  disabled?: boolean
  iconPosition?: 'left' | 'right'
  noIcon?: boolean
  iconSize?: number
  Icon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >
  ImageIcon?: string
}

export type ActionTypes = {
  value: string
  text: string
  content?: React.JSX.Element
  onClick?: (showModal: boolean) => void
  dataTestId?: string
}

interface IconButtonPropsType extends ButtonPropsType {
  hoverColor?: string
  options?: ActionTypes[] | false
  noHover?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
  color?: SemanticCOLORS | string
  className?: string
  dataTestId?: string
}

const Button = ({
  text,
  color,
  fill,
  Icon,
  ImageIcon,
  badgeContent,
  disabled,
  iconPosition = 'left',
  noIcon,
  iconSize,
}: ButtonPropsType) => (
  <ButtonContainer
    iconPosition={iconPosition}
    color={color}
    disabled={Boolean(disabled)}
  >
    {iconPosition === 'left' && ImageIcon && (
      <StyledImageIcon src={ImageIcon} />
    )}
    {iconPosition === 'left' && !noIcon && !ImageIcon && Icon && (
      <Icon weight={fill ? 'fill' : undefined} size={iconSize} />
    )}
    <Text disabled={Boolean(disabled)} color={color}>
      {`${badgeContent || ''} ${text}`}
    </Text>
    {iconPosition === 'right' && !noIcon && !ImageIcon && Icon && (
      <Icon weight={fill ? 'fill' : undefined} size={iconSize} />
    )}
    {iconPosition === 'right' && ImageIcon && (
      <StyledImageIcon src={ImageIcon} />
    )}
  </ButtonContainer>
)

const IconButton = ({
  text = '',
  onClick,
  ImageIcon = undefined,
  Icon = Warning,
  iconPosition,
  fill = undefined,
  color,
  hoverColor = 'var(--asc-selectedgreen)',
  options,
  badgeContent = null,
  disabled,
  noHover = false,
  noIcon = false,
  className,
  iconSize = 16,
  dataTestId,
}: IconButtonPropsType) => {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const toggleMenu = () => setMenuOpen(!menuOpen)
  return (
    <Container
      disabled={disabled}
      hovercolor={hoverColor}
      color={color}
      onClick={disabled ? undefined : onClick}
      nohover={noHover}
      className={className}
      data-testid={dataTestId}
    >
      {options ? (
        <StyledDropdown
          value=""
          open={menuOpen}
          onBlur={() => setMenuOpen(false)}
          onClick={toggleMenu}
          trigger={
            <Button
              text={text}
              color={color}
              iconSize={iconSize}
              fill={'regular'}
              Icon={Icon}
              iconPosition="right"
              badgeContent={badgeContent}
              disabled={disabled}
              noIcon={noIcon}
              ImageIcon={ImageIcon}
            />
          }
          options={options}
        />
      ) : (
        <Button
          text={text}
          color={color}
          fill={fill}
          Icon={Icon}
          ImageIcon={ImageIcon}
          iconSize={iconSize}
          iconPosition={iconPosition}
          badgeContent={badgeContent}
          disabled={disabled}
          noIcon={noIcon}
        />
      )}
    </Container>
  )
}

export default IconButton
