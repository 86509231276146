/* eslint-disable import/no-unused-modules */
//used in dateinput.js
import * as React from 'react'
import styled from 'styled-components/macro'
import {Calendar} from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
// export interface IDateRange {
//   startDate?: Date
//   endDate?: Date
//   key?: 'selection'
// }

type DatePickerProps = {
  hidden?: boolean
  selectedDate?: Date
  onChange: (range: Date) => void
  'data-cy'?: string
  maxDate?: Date
  minDate?: Date
  disabledDates?: Date[]
}

export const DatePicker = (props: DatePickerProps) => {
  const {
    onChange,
    hidden,
    'data-cy': testId,
    selectedDate = new Date(),
    maxDate,
    minDate,
    disabledDates,
  } = props
  const handleChange = (date: Date) => {
    if (date instanceof Date) {
      onChange(date)
    }
  }

  return (
    <Container hidden={hidden} data-cy={testId}>
      <Calendar
        maxDate={maxDate}
        minDate={minDate}
        date={selectedDate}
        disabledDates={disabledDates}
        onChange={handleChange}
        color={'var(--asc-moss)'}
      ></Calendar>
    </Container>
  )
}
export default DatePicker
