import * as React from 'react'
import styled from 'styled-components/macro'
import Modal from 'common/Modal'
import {CloudArrowUp, Funnel, XCircle} from 'phosphor-react'
import Tooltip from 'common/components/Tooltip'
import AlertModal from 'common/components/AlertModal'
import {QUERIES} from 'common/constants'

const Container = styled.div`
  display: none;
  @media ${QUERIES.tabletAndUp} {
    display: flex;
  }
  margin-left: 5px;
`
const ModalBody = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`
const DropBox = styled.div`
  font-size: 1.25rem;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  padding: 20px 20px;
  background: #f5f5f7;
  border: 2px dashed var(--asc-graphite);
  border-radius: 20px;
  height: 116px;
`
const HiddenInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  display: block;
`
const CSVLabel = styled.label`
  cursor: pointer;
  display: inline-block;
  display: flex;
  svg {
    margin-right: 10px;
  }
`
const UploadButton = styled.button`
  background: transparent;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 5px;
  :hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`
const FilterChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding-top: 10px;
  flex-wrap: wrap;
  max-height: 250px;
  overflow: auto;
`
const FilterChip = styled.button`
  background-color: var(--asc-keylime);
  border: 0;
  outline: 0;
  color: black;
  border-radius: 20px;
  padding: 5px 5px 5px 10px;
  gap: 10px;
  display: flex;
  font-weight: 400;
  line-height: 16px;
  svg {
    cursor: pointer;
  }
`
const StyledList = styled.ul`
  padding: 20px 0 0 30px;
  font-size: 14px;
  line-height: 22px;
`
const TextArea = styled.textarea`
  border: 1px solid var(--asc-graphite);
  border-radius: 10px;
  padding: 15px;
`

const validExts = ['.xlsx', '.xls', '.csv']
function checkfile(sender) {
  var fileExt = sender?.name
  fileExt = fileExt.substring(fileExt.lastIndexOf('.'))
  if (validExts.indexOf(fileExt) < 0) {
    return false
  } else return true
}
function ValidateSize(file) {
  var FileSize = file.size / 1024 / 1024 // in MB
  if (FileSize > 1) {
    //limit 1 MB
    return true
  }
  return false
}
RegExp.quote = function (str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}
const initialState = {
  showDialog: false,
  tokenNames: [],
  loading: false,
}
const AssetsFilter = ({onAssetsFilter}) => {
  const [state, setState] = React.useState(initialState)
  const [showDialog, setShowDialog] = React.useState(false)
  const [dialogText, setDialogText] = React.useState('')

  const do_file = (files, callBack) => {
    var reader = new FileReader()

    reader.onloadstart = function () {
      setState({loading: true})
    }
    reader.onload = function (e) {
      var data
      if (!e) {
        data = reader.content
      } else {
        data = e.target.result
      }
      const dataSplit = data.split('\n')
      const newDataSplit = dataSplit.map(item => {
        item = item.split(',')[0]
        return item
      })
      callBack(newDataSplit)
    }
    reader.readAsBinaryString(files)
  }

  const filterAssets = () => {
    onAssetsFilter(state.tokenNames)
    setState(initialState)
  }
  const getClipboard = event => {
    event.preventDefault()
    const text = (event.originalEvent || event).clipboardData.getData(
      'text/plain',
    )
    const splitText = text.split('\n')
    //remove carriage returns, commas and line breaks
    const CSVObject = splitText.map(x => x.replace(/\n|\r|,/g, ''))
    if (
      CSVObject[CSVObject.length - 1].replace(/^[\r\n]+|\.|[\r\n]+$/g, '') ===
      ''
    ) {
      CSVObject.pop() //remove last element if blank - happens when pasting from Excel in Windows
    }
    setState({...state, tokenNames: CSVObject})
  }
  const handleDragEnter = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragLeave = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const processingFile = droppedFiles => {
    const fileExtensionValid = checkfile(droppedFiles[0])
    const invalidFileSize = ValidateSize(droppedFiles[0])

    if (!fileExtensionValid) {
      setDialogText(
        'Invalid file selected, valid files are of ' +
          validExts.join(' , ') +
          ' types.',
      )
      setShowDialog(true)
      // Swal.fire(
      //   'Error: ',
      //   ,
      // )
      setState({...state, tokenNames: [], loading: false})
    } else if (invalidFileSize) {
      setDialogText('File size exceeds 1 MB')
      setShowDialog(true)
      // Swal.fire('Error: ', 'File size exceeds 1 MB', 'error')
      setState({...state, tokenNames: [], loading: false})
    } else {
      do_file(droppedFiles[0], objectReturn => {
        let allAssetsFromFirstColumn = objectReturn?.map(
          item => item.replace(/^"(.+(?="$))"$/, '$1'), //remove quote from the string only if start from the start and the end of string
        )
        // allAssetsFromFirstColumn.splice(0, 1) //remove the header of the column leave all the rest
        allAssetsFromFirstColumn = allAssetsFromFirstColumn.map(asset =>
          asset.replace(/\n|\s{1,}|ï»¿/g, ''),
        )
        const CSVObject = allAssetsFromFirstColumn
        setState({...state, tokenNames: CSVObject, loading: false})
      })
    }
  }
  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    const droppedFiles = [...e.dataTransfer.files]
    processingFile(droppedFiles)
  }

  const handleFileChange = changeEvent => {
    const droppedFiles = [...changeEvent.target.files]
    processingFile(droppedFiles)
  }

  const removeToken = index => {
    const currentTokens = state.tokenNames
    currentTokens.splice(index, 1)
    setState({...state, tokenNames: currentTokens})
  }

  const [activeTabIndex, setActiveTabIndex] = React.useState(0)
  const updateIndex = index => {
    setActiveTabIndex(index)
    setState({...state, tokenNames: []})
  }
  return (
    <Container>
      <Tooltip
        tooltip={'Filter Assets By Names'}
        position={'top center'}
        trigger={
          <UploadButton
            data-cy="filter-assets-trigger"
            data-testid="cardlist-filter"
            onClick={() => setState({...state, showDialog: !state.showDialog})}
          >
            <Funnel size={19} weight={'fill'} color={'var(--primary)'} />
          </UploadButton>
        }
      />
      <AlertModal
        showDialog={showDialog}
        onSave={() => {
          setShowDialog(false)
        }}
        type={'error'}
        title={'Unable to upload to this image.'}
        text={dialogText}
        showDenyButton={false}
      />
      <Modal
        testIdName="filterpanel"
        showDialog={state.showDialog}
        title={'Assets Filter'}
        subTitle="Use a spreadsheet to filter by Asset Name."
        width={'600px'}
        renderFooter={true}
        disabled={!state.tokenNames?.length}
        onSave={filterAssets}
        onDismiss={() => setState(initialState)}
        submitButtonText="Apply Filter"
        tabs={[
          {
            text: 'Upload .CSV',
            active: activeTabIndex === 0,
            onClick: () => updateIndex(0),
            small: false,
            color: 'var(--asc-black)',
            testId: 'filterpanel-tab-upload',
          },
          {
            text: 'Copy/Paste Text',
            active: activeTabIndex === 1,
            onClick: () => updateIndex(1),
            small: false,
            color: 'var(--asc-black)',
            testId: 'filterpanel-tab-copypaste',
          },
        ]}
      >
        <ModalBody>
          {activeTabIndex === 0 && (
            <>
              <DropBox
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                data-testid="filterpanel-chosefile"
              >
                <HiddenInput
                  id="csvFilter"
                  name="file"
                  type="file"
                  onChange={handleFileChange}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  csv-object="CSVObject"
                  fileread="csvFile"
                ></HiddenInput>
                <CSVLabel htmlFor="csvFilter">
                  {/* <SVG
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="43"
                  viewBox="0 0 50 43"
                >
                  <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path>
                </SVG> */}
                  <CloudArrowUp size={20} />
                  <span>
                    <strong>Choose .csv file</strong> or drag and drop it here
                  </span>
                </CSVLabel>
              </DropBox>
              <StyledList>
                <li>
                  In your spreadsheet, list the Asset Names to filter by in the
                  first column.
                </li>
                <li>Save your document as a .CSV file.</li>
                <li>Upload file and Apply Filter.</li>
              </StyledList>
            </>
          )}
          {activeTabIndex === 1 && (
            <>
              <TextArea
                placeholder="Copy/Paste Asset Names..."
                onPaste={getClipboard}
                cols="45"
                rows="4"
              />
              <StyledList>
                <li>
                  Copy the Asset Names to filter by from your spreadsheet.
                </li>
                <li>Paste the Asset Names and Apply Filter.</li>
              </StyledList>
            </>
          )}
          <FilterChipContainer>
            {state.tokenNames?.map((name, index) => {
              return (
                <FilterChip key={index}>
                  {name}
                  <span onClick={() => removeToken(index)}>
                    <XCircle size={16} weight="fill" />
                  </span>
                </FilterChip>
              )
            })}
          </FilterChipContainer>
        </ModalBody>
      </Modal>
    </Container>
  )
}
export default AssetsFilter
