import * as React from 'react'
import styled from 'styled-components/macro'

const ContainerForm = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  .label {
    background-color: var(--grey-300);
  }
  .field {
    margin-bottom: 1em;
  }
  i {
    opacity: 1 !important;
  }
  .disabled.field {
    opacity: 1;
  }
  .disabled.dropdown {
    opacity: 1;
    background-color: var(--grey-300);
  }
  .disabled.input {
    opacity: 1;
  }
  input:disabled {
    background-color: var(--grey-300);
  }
  textarea {
    width: 100%;
    border: 1px solid rgba(34, 36, 38, 0.15);
  }
  /* Following is needed to scroll correctly */
  position: relative;
`

const StyledForm = ({
  children,
  testId,
  className,
}: {
  children: React.ReactNode
  testId?: string
  className?: string
}) => {
  return (
    <ContainerForm data-cy={testId} className={className}>
      {children}
    </ContainerForm>
  )
}
export default StyledForm
