import * as React from 'react'
import {DialogContent, DialogOverlay} from '@reach/dialog'
import '@reach/dialog/styles.css'
import styled from 'styled-components/macro'
import {ModalHeader} from 'semantic-ui-react'
import {X} from 'phosphor-react'

export type ButtonType =
  | ''
  | 'cancel'
  | 'required'
  | 'info'
  | 'error'
  | 'clear'
  | 'delete'
  | 'unassign'
  | 'remove'
  | 'add'
  | 'update'
  | 'route'
  | 'confirm'
type ColorType = {
  button?: string
  font?: string
  hover?: string
}

type Props = {
  showDialog?: boolean
  disabled?: boolean
  onSave: React.MouseEventHandler<HTMLButtonElement>
  onDismiss?: React.MouseEventHandler<HTMLButtonElement>
  type?: ButtonType
  title?: string
  text?: string
  showDenyButton?: boolean
  colors?: ColorType
}

type SaveButtonType = {
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  type: string
  color?: string
  hover?: string
  font?: string
}

const ModalContainer = styled(DialogContent)`
  margin: 5vh auto;

  &&& {
    h2,
    span {
      font-family: 'Open Sans', sans-serif;
    }
    h2 {
      margin-bottom: 0;
    }
    border-radius: 0;
    border-radius: 20px;
    padding: 0;
    width: 95%;
    max-width: 566px;
    z-index: 2000;
  }
`
const Header = styled.div`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: '30px';
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`
const Footer = styled(ModalHeader)`
  padding: 30px 30px;
  margin: 0;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  justify-content: flex-end;
  display: flex;
`

const Button = styled.button<SaveButtonType>`
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  border: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  svg {
    height: 20px;
    width: 20px;
    margin: 0 7px 0 0;
    padding: 0;
  }
`

const standardTypes = [
  'cancel',
  'clear',
  'delete',
  'error',
  'unassign',
  'remove',
]

interface SaveButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  modalType: string
}

const SaveButton = styled(Button)`
  background-color: ${({modalType, color}: SaveButtonProps) =>
    color
      ? color
      : standardTypes.includes(modalType)
      ? 'var(--asc-vermilion)'
      : 'var(--secondary)'};
  color: ${({modalType, font}) =>
    font
      ? font
      : standardTypes.includes(modalType)
      ? 'white'
      : 'var(--primary)'};
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  :hover {
    color: ${({modalType, font}) =>
      font
        ? font
        : standardTypes.includes(modalType)
        ? 'white'
        : 'var(--secondary)'};
    background-color: ${({modalType, hover}) =>
      hover
        ? hover
        : standardTypes.includes(modalType)
        ? 'var(--asc-vermilion)'
        : 'var(--primary)'};
  }
  :disabled {
    cursor: not-allowed;
    background-color: var(--asc-platinum);
    color: var(--asc-dimgray);
  }
`
const CancelButton = styled(Button)`
  background-color: white;
  color: var(--primary);
  :hover {
    background-color: var(--asc-platinum);
  }
`
const Row = styled.div`
  &&& {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    h2 {
      font-size: 24px;
      margin-left: 30px;
      margin-right: 30px;
      word-break: break-word;
    }
  }
`
const ModalContent = styled.div`
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  max-height: 65vh;
  font-size: 18px;
  white-space: pre-line;
`

const AlertModal = ({
  showDialog,
  disabled = false,
  onSave,
  onDismiss,
  type = 'confirm',
  title = '',
  text = '',
  showDenyButton = false,
  colors = undefined,
}: Props) => {
  return (
    <div className="parent-dialog">
      <DialogOverlay initialFocusRef={undefined} isOpen={showDialog}>
        <ModalContainer
          role="dialog"
          className="modal-container"
          aria-labelledby={`${type}-modal`}
          data-cy="alert-modal"
        >
          <Header>
            <Row>
              <h2 role="title">{title}</h2>
            </Row>
          </Header>
          <ModalContent>{text}</ModalContent>

          <Footer>
            {showDenyButton && onDismiss ? (
              <CancelButton
                onClick={onDismiss}
                type="button"
                role="cancelButton"
              >
                <X size={13} />
                <span role="CancelButtonText">
                  {type === 'cancel' ? 'NO, KEEP WORKING' : 'CANCEL'}
                </span>
              </CancelButton>
            ) : (
              <></>
            )}

            <SaveButton
              disabled={disabled}
              onClick={onSave}
              type="button"
              modalType={type}
              color={colors?.button}
              hover={colors?.hover}
              font={colors?.font}
              role="saveButton"
            >
              {type === 'cancel' || type === 'route'
                ? 'YES, CANCEL'
                : type === 'required' || type === 'info' || type === 'error'
                ? `GOT IT`
                : type === 'clear'
                ? 'YES, CLEAR'
                : type === 'delete'
                ? 'YES, DELETE'
                : type === 'unassign'
                ? 'YES, UNASSIGN'
                : type === 'remove'
                ? 'YES, REMOVE'
                : type === 'add'
                ? 'ADD'
                : type === 'update'
                ? 'UPDATE STATUS'
                : 'CONFIRM'}
            </SaveButton>
          </Footer>
        </ModalContainer>
      </DialogOverlay>
    </div>
  )
}

export default AlertModal
