import {ColumnDef} from '@tanstack/react-table'
import {CustomerType} from 'management/hooks/useCustomers'
import {
  DeviceTransferItem,
  DeviceTransferType,
} from './hooks/usePostDeviceTransfer'
import {SemanticInputType} from 'common/components/InputDropdownOptional'

type ErrorType = {
  [key: string]: {type?: string; message: string}
}

export type DeviceTransferStateType = {
  deviceTransfer: DeviceTransferType
  selectedDeviceIDs: number[]
  errors: ErrorType
  unitPrice: number | null
  showConfirmDialog: boolean
}

type ActionTypes =
  | {type: 'setState'; data: Partial<DeviceTransferStateType>}
  | {
      type: 'updateDeviceTransfer'
      data: {values: Partial<DeviceTransferType>; errors: ErrorType}
    }
  | {type: 'updatePrice'; data: {value: number; errors: ErrorType}}

const requiredFields = {
  unitPrice: {message: 'Unit Price is required'},
  FromidCustomer: {message: 'From Customer is required'},
  ToidCustomer: {message: 'To Customer is required'},
  Order_Num: {message: 'Order Number is required'},
}

export const errorCheck = (
  state: DeviceTransferStateType,
  name?: string,
  data?: string | number | number[],
): ErrorType => {
  const errors: ErrorType = state.errors
  delete errors.ToidCustomer //this will be reevaluated below
  let dataToCheck: {
    unitPrice?: number | null
    FromidCustomer?: number
    ToidCustomer?: number
    Order_Num?: string
  } = {}

  if (!name) {
    dataToCheck = {
      unitPrice: state.unitPrice,
      FromidCustomer: state.deviceTransfer.FromidCustomer,
      ToidCustomer: state.deviceTransfer.ToidCustomer,
      Order_Num: state.deviceTransfer.Order_Num,
    }
  } else {
    dataToCheck = {[name]: data as number}
  }

  Object.keys(dataToCheck).forEach(key => {
    if (key in dataToCheck) {
      const deviceTransferKey = key as keyof typeof dataToCheck
      if (
        !dataToCheck[deviceTransferKey] &&
        dataToCheck[deviceTransferKey] !== 0
      ) {
        errors[key] = requiredFields[key as keyof typeof requiredFields]
      } else {
        delete errors[key]
      }
    }
  })

  if (!name || name === 'FromidCustomer' || name === 'ToidCustomer') {
    if (
      (name === 'FromidCustomer' &&
        data === state.deviceTransfer.ToidCustomer) ||
      (name === 'ToidCustomer' &&
        data === state.deviceTransfer.FromidCustomer) ||
      (!name &&
        state.deviceTransfer.FromidCustomer ===
          state.deviceTransfer.ToidCustomer)
    ) {
      errors.ToidCustomer = {
        message: 'Customer cannot be the same',
      }
    }
  }
  if (
    (!name && state.selectedDeviceIDs.length === 0) ||
    (name === 'selectedDeviceIDs' &&
      data &&
      Array.isArray(data) &&
      data?.length === 0)
  ) {
    errors.deviceTransfers = {message: 'At least one device is required'}
  } else {
    delete errors.deviceTransfers
  }
  return errors
}

export const reducer = (
  state: DeviceTransferStateType,
  action: ActionTypes,
) => {
  switch (action.type) {
    case 'setState':
      return {...state, ...action.data}
    case 'updatePrice':
      const updatedDeviceTransfers = state.deviceTransfer.deviceTransfers.map(
        d => ({...d, Unit_Price: action.data.value}),
      )
      return {
        ...state,
        unitPrice: action.data.value,
        deviceTransfer: {
          ...state.deviceTransfer,
          deviceTransfers: updatedDeviceTransfers,
        },
        errors: action.data.errors,
      }
    case 'updateDeviceTransfer':
      let value = {...state.deviceTransfer, ...action.data.values}
      let newState = state
      if ('FromidCustomer' in action.data.values) {
        value = {
          ...value,
          deviceTransfers: [],
        }
        newState = {
          ...state,
          selectedDeviceIDs: [],
        }
      }
      return {
        ...newState,
        deviceTransfer: value,
        errors: action.data.errors,
      }
    default:
      return state
  }
}

export const onChangeInput = ({
  e,
  dispatch,
  state,
}: {
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  dispatch: React.Dispatch<ActionTypes>
  state: DeviceTransferStateType
}) => {
  let value = e?.currentTarget?.value
  const name = e?.currentTarget?.name
  const errors = errorCheck(state, name, value)
  if (name === 'unitPrice') {
    dispatch({type: 'updatePrice', data: {value: Number(value), errors}})
  } else if (name === 'Order_Num') {
    dispatch({
      type: 'updateDeviceTransfer',
      data: {values: {Order_Num: value}, errors},
    })
  } else {
    console.error('unrecognized input')
  }
}

export const onDropdownChange = ({
  name,
  value,
  dispatch,
  state,
}: {
  name: string
  value: SemanticInputType
  dispatch: React.Dispatch<ActionTypes>
  state: DeviceTransferStateType
}) => {
  const errors = errorCheck(state, name, value as string | number)
  if (name) {
    dispatch({
      type: 'updateDeviceTransfer',
      data: {
        values: {[name]: value},
        errors,
      },
    })
  }
}

export const columns: ColumnDef<DeviceTransferItem>[] = [
  {
    header: 'idDevice',
    accessorKey: 'idDevice',
  },
  {
    header: 'Serial Number Displayed',
    accessorKey: 'Serial_Number_Displayed',
  },
  {
    header: 'Unit Price',
    accessorKey: 'Unit_Price',
  },
]

export const returnSelectedCustomer = (
  customers?: CustomerType[],
  idCustomer?: number,
) => {
  if (!customers || !idCustomer) return ''
  const selectedCustomer = customers?.find(
    customer => customer.idcustomer === idCustomer,
  )
  return selectedCustomer?.customer_name || ''
}
