import React from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import styled from 'styled-components/macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import AscendLogo from 'images/Ascend-Logo-No-Slogan.svg'
import TemedaLogo from 'images/temeda-logo-2020.png'
import {useStore, User as UserType, ORoleType} from 'common/useStore'
import {APP_FLAVOR} from 'common/constants'

const LeftMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  background-color: #263238;
  padding-top: 10px;
`

const TabLabel = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  svg {
    width: 20px !important;
  }
`

const SubTabLabel = styled.div`
  padding: 5px 38px;
`

const LogoContainer = styled.div`
  background-color: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 5px;
`

const LogoImg = styled.img`
  width: auto;
  height: 50px;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: calc(24 / 14 * 1rem);
  margin-right: 10px;
`

const IconAndLabel = styled.div`
  display: flex;
  align-items: center;
`

const MenuCollapseIcon = styled(FontAwesomeIcon)`
  font-size: calc(24 / 14 * 1rem);
  transition: all 0.3s;
  transform: rotate(
    ${(p: {active: string}) => (p.active === 'true' ? '180' : '0')}deg
  );
`

const TabContainer = styled.div`
  border-radius: 8px;
  background-color: rgba(256, 256, 256, 0.1);
  .active {
    background-color: #00695d;
  }
  margin: 10px 10px 0 10px;
`

const TabButton = styled(NavLink)`
  display: flex;
  outline: none;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 8px;
  border: 0;
  color: white;
  width: 100%;
  font-size: calc(14 / 14 * 1rem);
  text-decoration: none;
  background-color: #263238;
`

const SubTabs = styled.div`
  transition: all 0.3s;
  height: ${(p: {active: string; height?: string}) =>
    p.active === 'true' ? (p.height ? p.height : '80px') : '0'};
  opacity: ${(p: {active: string}) => (p.active === 'true' ? '1' : '0')};
  overflow: hidden;
  .active {
    background-color: rgb(81, 89, 93);
  }
  /* margin: 0 10px 10px 10px; */
`

const SubTabButton = styled(NavLink)`
  display: flex;
  width: calc(100% - 20px);
  font-size: calc(14 / 14 * 1rem);
  text-decoration: none;
  padding: 0;
  outline: none;
  transition: 0.3s;
  cursor: pointer;
  background-color: transparent;
  margin: 10px;
  border: 0;
  border-radius: 10px;
  color: white;
`

const LeftMenuTabs = () => {
  const location = useLocation()
  const {pathname} = location

  const currentUser = useStore((state: {user: UserType}) => state.user)
  // const userConfig = useStore((state: {userConfig: UserConfigType | null}) => state.userConfig)

  return (
    <LeftMenuContainer>
      <LogoContainer>
        <LogoImg src={APP_FLAVOR === 'ascend' ? AscendLogo : TemedaLogo} />
      </LogoContainer>
      <TabContainer>
        <TabButton to="/management/users">
          <TabLabel>
            <IconAndLabel>
              <Icon icon="user-friends" />
              <span>Users</span>
            </IconAndLabel>
          </TabLabel>
        </TabButton>
      </TabContainer>
      <TabContainer>
        <TabButton to="/management/hotswapdevices">
          <TabLabel>
            <IconAndLabel>
              <Icon icon="cube" />
              <span>Hot Swap Devices</span>
            </IconAndLabel>
          </TabLabel>
        </TabButton>
      </TabContainer>
      <TabContainer>
        <TabButton to="/management/customers">
          <TabLabel>
            <IconAndLabel>
              <Icon icon="users" />
              <span>Customers</span>
            </IconAndLabel>
            <MenuCollapseIcon
              icon="angle-down"
              active={pathname.startsWith('/management/customers').toString()}
            ></MenuCollapseIcon>
          </TabLabel>
        </TabButton>
        <SubTabs
          height="auto"
          active={pathname.startsWith('/management/customers').toString()}
        >
          <SubTabButton to="/management/customers/users">
            <SubTabLabel>Users</SubTabLabel>
          </SubTabButton>
          <SubTabButton to="/management/customers/branding">
            <SubTabLabel>Branding</SubTabLabel>
          </SubTabButton>
          <SubTabButton to="/management/customers/device-swap">
            <SubTabLabel>Device Swap</SubTabLabel>
          </SubTabButton>
        </SubTabs>
      </TabContainer>
      <TabContainer>
        <TabButton to="/management/dealers">
          <TabLabel>
            <IconAndLabel>
              <Icon icon="building" />
              <span>Dealers</span>
            </IconAndLabel>
            <MenuCollapseIcon
              icon="angle-down"
              active={pathname.startsWith('/management/dealers').toString()}
            ></MenuCollapseIcon>
          </TabLabel>
        </TabButton>
        <SubTabs active={pathname.startsWith('/management/dealers').toString()}>
          <SubTabButton to="/management/dealers/users">
            <SubTabLabel>Users</SubTabLabel>
          </SubTabButton>
          <SubTabButton to="/management/dealers/branding">
            <SubTabLabel>Branding</SubTabLabel>
          </SubTabButton>
        </SubTabs>
      </TabContainer>
      {currentUser.role === ORoleType.TemedaAdministrator && (
        <TabContainer>
          <TabButton to="/management/support">
            <TabLabel>
              <IconAndLabel>
                <Icon icon="phone" />
                <span>
                  {' '}
                  {APP_FLAVOR === 'ascend' ? 'ASCEND Admin' : 'TEMEDA Admin'}
                </span>
              </IconAndLabel>
              <MenuCollapseIcon
                icon="angle-down"
                active={pathname.startsWith('/management/support').toString()}
              ></MenuCollapseIcon>
            </TabLabel>
          </TabButton>
          <SubTabs
            height="auto"
            active={pathname.startsWith('/management/support').toString()}
          >
            <SubTabButton to="/management/support/notreporting">
              <SubTabLabel>MC-3 Devices Not Reporting</SubTabLabel>
            </SubTabButton>
            <SubTabButton to="/management/support/devices">
              <SubTabLabel>Devices</SubTabLabel>
            </SubTabButton>
            <SubTabButton to="/management/support/customeradmin">
              <SubTabLabel>Customers</SubTabLabel>
            </SubTabButton>
            <SubTabButton to="/management/support/useradmin">
              <SubTabLabel>Users</SubTabLabel>
            </SubTabButton>
            <SubTabButton to="/management/support/apiusers">
              <SubTabLabel>API Users</SubTabLabel>
            </SubTabButton>
          </SubTabs>
        </TabContainer>
      )}
    </LeftMenuContainer>
  )
}

export default LeftMenuTabs
