import * as React from 'react'
import styled from 'styled-components/macro'
import {
  DateRangePicker as ReactDateRangePicker,
  Range,
  RangeKeyDict,
} from 'react-date-range'
import {Button, Icon} from 'semantic-ui-react'
import {
  addDays,
  getHours,
  getMinutes,
  getSeconds,
  format,
  endOfDay,
  endOfToday,
  startOfToday,
  set,
  startOfDay,
  getTime,
  addMilliseconds,
  addMinutes,
  differenceInMilliseconds,
  isAfter,
  subDays,
  isSameDay,
} from 'date-fns'
import {
  getDefinedRangeString,
  staticRanges,
  reducer,
  getDateRangeString,
} from './dateRangePickerHelpers'
import {useStore} from 'common/useStore'
import TimePicker from 'common/components/TimePicker'
import {CalendarBlank} from 'phosphor-react'
import useOnClickOutside from './hooks/useOnClickOutside'
import {QUERIES} from 'common/constants'
import useWindowSize from 'common/useWindowSize'
import {RequiredAsterisk} from './InputWithLabel'

export interface IDateRange {
  startDate?: Date
  endDate?: Date
  selection?: string
  key?: string
}

type PropsType = {
  setRange?: (range: Range) => void //made optional for viewonly input
  testId?: string
  range?: Range
  dateFormat?: string
  disabledDates?: Date[]
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
  maxNumberOfDays?: number
  className?: string
  direction?: 'left' | 'right' | 'top-right'
  showTime?: boolean
  months?: number
  color?: string
  showValueOnMobile?: boolean
  showDateAndTime?: boolean
  label?: string
  required?: boolean
  showPredefined?: boolean
  singleDay?: boolean
  hideIcon?: boolean
  durationInMinutes?: number | undefined
  setIsOpen?: (isOpen: boolean) => void
}

type StyleProps = {
  time?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 3px;
  position: relative;
  align-items: flex-end;
  @media ${QUERIES.tabletAndUp} {
    min-width: 175px;
    align-items: inherit;
  }
`
const RangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10;
  border: 1px solid var(--grey-400);
  border-radius: 5px;
  position: absolute;
  margin-top: 45px;
  background-color: #fff;
  .rdrMonth {
    ${(p: {
      showPredefined: boolean
      direction: 'left' | 'right' | 'top-right'
    }) => !p.showPredefined && 'width: 100%;'}
  }
  .rdrCalendarWrapper {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    gap: 10px;
    ${p => !p.showPredefined && 'width: 100%;'}
  }
  .rdrDayToday .rdrDayNumber span:after {
    background: var(--secondary);
  }
  & > div {
    border-radius: 0 0 5px 5px;
    /* overflow: hidden; */
  }
  top: 0;
  .rdrDateRangePickerWrapper {
    display: flex;
    flex-direction: column;
  }
  .rdrDefinedRangesWrapper {
    border-radius: 5px;
    min-width: 226px;
    width: unset;
    ${p => !p.showPredefined && 'display: none;'}
  }
  /*hides the extra space under the options list */
  .rdrInputRanges {
    display: none;
  }
  /*hides doubled border on options list*/
  .rdrStaticRange {
    :first-child {
      border-top-left-radius: 5px;
    }

    :last-child {
      border-bottom: none;
    }
  }
  .rdrNextPrevButton {
    margin: 0 20px;
  }
  @media (min-width: 640px) {
    ${p =>
      p.direction === 'right'
        ? 'right: 0;'
        : p.direction === 'top-right'
        ? 'right: 3px; top: -456px;'
        : 'left: 3px;'}
    .rdrDateRangePickerWrapper {
      flex-direction: row;
    }
  }
`
const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3px 0;
  position: relative;
  svg {
    cursor: pointer;
  }
`
const Buttons = styled.div`
  padding: 6px;
  display: flex;
  justify-content: flex-end;
`
const BottomBar = styled.div<StyleProps>`
  display: flex;
  justify-content: ${({time}) => (time ? 'space-between' : 'flex-end')};
  border-top: 1px solid var(--asc-coolgray);
  align-items: center;
  // flex-wrap: wrap;
`
const StyledLabel = styled.label`
  display: block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  display: flex;
`
const StyledInput = styled.input<{hideIcon: boolean}>`
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  opacity: ${({disabled}) => (disabled ? '.75' : '1')};
  caret-color: transparent;
  width: 0;
  border: 1px solid var(--asc-coolgray);
  border-radius: 5px;
  padding: ${({hideIcon}) =>
    hideIcon ? '8px 5px 8px 10px' : '8px 5px 8px 40px'};
  caret-color: transparent;
  @media ${QUERIES.tabletAndUp} {
    width: 100%;
  }
  :focus {
    outline: 1px solid var(--asc-keylime);
    border: 1px solid var(--asc-moss);
    box-shadow: 0px 0px 6px 1px #b9cf33;
  }
`
const CalendarIcon = styled(CalendarBlank)<{disabled: boolean}>`
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')} !important;
  position: absolute;
  top: 12px;
  left: 15px;
  color: var(--asc-graphite);
  height: 20px;
  width: 20px;
`
const DatePreview = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  color: var(--asc-sonicsilver);
  border: none;
  input {
    max-width: 141px;
  }
`
const TimeColumn = styled.div`
  border-right: 1px solid var(--asc-coolgray);
  padding: 10px 15px;
  ${(p: {showPredefined: boolean}) => !p.showPredefined && 'width: 172px;'}
`
const ApplyButton = styled(Button)`
  &&& {
    background: var(--secondary);
    color: var(--primary);
    max-height: 40px;

    :hover {
      background: var(--primary);
      color: var(--secondary);
    }
  }
`
const CancelButton = styled(Button)`
  &&& {
    background: transparent;
    color: var(--primary);
    max-height: 40px;
    box-shadow: none;
    display: flex;
    :hover {
      background-color: var(--asc-platinum);
    }
  }
`

const defaultRange: Range = {
  startDate: startOfToday(),
  endDate: endOfToday(),
}
/**
 * @params setIsOpen - use this function to set some external state when
 * the calendar pane is currently open or closed
 */
const DateRangePicker = (props: PropsType) => {
  const {
    setRange,
    testId,
    range = defaultRange,
    disabledDates,
    maxDate,
    minDate,
    disabled,
    maxNumberOfDays,
    className,
    direction = 'left',
    showTime = false,
    months = 1,
    color,
    showValueOnMobile = false,
    showDateAndTime = false,
    label = '',
    required = false,
    showPredefined = true,
    singleDay = false,
    durationInMinutes,
    setIsOpen,
    hideIcon = false,
  } = props
  const userConfig = useStore(state => state.userConfig)
  const dateFormatNoTime = userConfig?.Date_Format
    ? userConfig?.Date_Format.replace('yyyy', 'yy')
    : 'M/d/yy'
  const dateFormatWithTime = userConfig?.Date_Format
    ? userConfig?.Date_Format + ' h:mm aa'
    : 'M/d/yyyy h:mm aa'
  const dateFormat = showDateAndTime ? dateFormatWithTime : dateFormatNoTime

  const [state, dispatch] = React.useReducer(reducer, {
    show: false,
    startDate: range.startDate || defaultRange.startDate,
    endDate: range.endDate || defaultRange.endDate,
    rangeString: showPredefined
      ? getDefinedRangeString(
          range.startDate ? range : defaultRange,
          dateFormat,
        )
      : format(range.startDate ? range.startDate : new Date(), dateFormat) +
        '-' +
        format(range.endDate ? range.endDate : new Date(), dateFormat),
    localStaticRanges: staticRanges,
    maxDate: maxDate,
    minDate: minDate,
  })
  const {isMobile} = useWindowSize()
  const [todayDate, setTodayDate] = React.useState(new Date())
  const containerRef = React.useRef<HTMLDivElement>(null)

  const cancel = () => {
    dispatch({
      type: 'setState',
      data: {
        startDate: range.startDate || defaultRange.startDate,
        endDate: range.endDate || defaultRange.endDate,
        show: false,
      },
    })
    setIsOpen && setIsOpen(false)
  }

  const clickedOutside = () => {
    if (state.show) {
      cancel()
    }
  }
  useOnClickOutside(containerRef, clickedOutside)

  const handleChange = (range: RangeKeyDict) => {
    if ('selection' in range) {
      let startTimeMs =
        getTime(state.startDate) - getTime(startOfDay(state.startDate))
      let endTimeMs =
        getTime(state.endDate) - getTime(startOfDay(state.endDate))
      let startDate = range.selection.startDate
      let endDate = singleDay
        ? range.selection.startDate
        : range.selection.endDate
      const rangeString = getDefinedRangeString(
        {
          startDate,
          endDate,
        },
        dateFormat,
        singleDay,
      )
      if (startDate && endDate) {
        //selecting a predefined date range goes to end of day but selecting from calendar doesn't
        //if 00:00:00 move to end of day
        if (!rangeString.includes('/')) {
          //predefined date range was selected, close window
          dispatch({
            type: 'predefinedSelected',
            data: {startDate, endDate, rangeString},
          })
          if (setRange) {
            setRange({
              startDate: range.selection.startDate,
              endDate: range.selection.endDate,
              key: 'selection',
            })
          }
          return
        } else {
          startDate = startDate
            ? addMilliseconds(startOfDay(startDate), startTimeMs)
            : startDate
          endDate = endDate
            ? addMilliseconds(startOfDay(endDate), endTimeMs)
            : endDate
        }

        if (maxNumberOfDays) {
          const maxDateWithNumberOfDays = addDays(startDate, maxNumberOfDays)
          if (endDate > maxDateWithNumberOfDays) {
            endDate = maxDateWithNumberOfDays
          }
        }
        if (
          !showTime &&
          getHours(endDate) === 0 &&
          getMinutes(endDate) === 0 &&
          getSeconds(endDate) === 0
        ) {
          endDate = endOfDay(endDate)
        }
        dispatch({
          type: 'setState',
          data: {
            startDate,
            endDate,
          },
        })
      }
    }
  }

  const applyClick = () => {
    //apply times
    let startDate = state.startDate
    let endDate = state.endDate
    if (setRange) {
      //if same day make sure start time is before end time
      if (
        isSameDay(state.startDate, state.endDate) &&
        state.endDate.getTime() < state.startDate.getTime()
      ) {
        startDate = state.endDate
        endDate = state.startDate
      }
      setRange({
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      })
    }
    dispatch({
      type: 'setState',
      data: {
        rangeString: getDefinedRangeString(
          {startDate: startDate, endDate: endDate},
          dateFormat,
          singleDay,
        ),
        show: false,
      },
    })
    setIsOpen && setIsOpen(false)
  }

  React.useEffect(() => {
    if (state.show) {
      const applyButton = document.getElementById('dateRangeApplyButton')
      if (applyButton) applyButton.scrollIntoView()
    }
  }, [state.show])

  //This is to update the date range screen after midnight if someone leaves the page open overnight
  React.useEffect(() => {
    const updateRangeString = () => {
      let newRangeString = getDateRangeString({
        range,
        dateFormat,
        showTime,
        singleDay,
      })
      dispatch({
        type: 'setState',
        data: {
          rangeString: newRangeString,
        },
      })
    }
    const timeUntilJustAfterMidnight =
      differenceInMilliseconds(endOfDay(new Date()), new Date()) + 15000 // 15 seconds after midnight

    const setDateUpdate = setTimeout(() => {
      updateRangeString()
    }, timeUntilJustAfterMidnight)

    return () => {
      clearTimeout(setDateUpdate)
    }
  }, [dateFormat, range, showTime, singleDay, state.rangeString])

  React.useEffect(() => {
    if (maxNumberOfDays && maxNumberOfDays < 30) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newStaticRanges: any[] = []
      staticRanges.forEach(range => {
        if (range.label !== 'Last Month' && range.label !== 'Month To Date') {
          newStaticRanges.push(range)
        }
      })
      dispatch({type: 'setLocalStaticRanges', data: newStaticRanges})
    }
  }, [maxNumberOfDays])

  React.useEffect(() => {
    const dateRangeString = showPredefined
      ? getDefinedRangeString(
          range.startDate ? range : defaultRange,
          dateFormat,
          singleDay,
        )
      : getDateRangeString({range, dateFormat, showTime, singleDay})
    if (range.startDate && range.endDate) {
      dispatch({
        type: 'setState',
        data: {
          startDate: range.startDate,
          endDate: range.endDate,
          show: false,
          rangeString: dateRangeString,
        },
      })
    } else {
      dispatch({
        type: 'setState',
        data: {
          show: false,
          rangeString: '',
          startDate: new Date(),
        },
      })
    }
  }, [dateFormat, range, showPredefined, showTime, singleDay])

  //if the date range picker is rendered and the date today changes, add one day to the min/max date if it exists
  React.useEffect(() => {
    const updateMaxDate = () => {
      const currentTime = new Date()
      // Check if the current date and time is after the end of today
      if (isAfter(currentTime, endOfDay(todayDate))) {
        setTodayDate(currentTime)
        const newMaxDate = state.maxDate
          ? addDays(state.maxDate, 1)
          : state.maxDate
        const newMinDate = state.minDate
          ? subDays(state.minDate, 1)
          : state.minDate
        dispatch({
          type: 'setState',
          data: {
            maxDate: newMaxDate,
            minDate: newMinDate,
          },
        })
      }
    }

    const intervalId = setInterval(updateMaxDate, 60000) //check every minute

    return () => {
      clearInterval(intervalId)
    }
  }, [state.maxDate, state.minDate, todayDate])

  return (
    <Container
      className={className}
      data-cy={testId}
      ref={containerRef}
      id={'date-range-input-container'}
    >
      {label && (
        <StyledLabel htmlFor={'date-range-picker'}>
          {label}
          {required && <RequiredAsterisk />}
        </StyledLabel>
      )}
      <TopRow className="top-row" id="top-row">
        <StyledInput
          id={'date-range-picker'}
          type="text"
          value={isMobile && !showValueOnMobile ? '' : state.rangeString}
          onClick={() => {
            dispatch({type: 'setShow', data: true})
            setIsOpen && setIsOpen(true)
          }}
          onChange={e => e.stopPropagation()}
          data-cy="selectDateInput"
          disabled={disabled}
          readOnly
          hideIcon={hideIcon}
        />

        {!hideIcon && (
          <CalendarIcon
            disabled={disabled || false}
            onClick={() => !disabled && dispatch({type: 'setShow', data: true})}
            weight="fill"
            color={color ? color : 'var(--asc-sonicsilver)'}
          />
        )}
      </TopRow>
      {state.show && (
        <RangeContainer
          showPredefined={showPredefined}
          direction={direction || 'left'}
          className="range-container"
          id={'date-range-picker-container'}
          data-cy="popupDateRangePicker"
        >
          <ReactDateRangePicker
            ranges={[
              {
                startDate: state.startDate,
                endDate: singleDay ? state.startDate : state.endDate,
                key: 'selection',
              },
            ]}
            onChange={handleChange}
            minDate={state.minDate}
            maxDate={state.maxDate ? state.maxDate : undefined}
            disabledDates={disabledDates}
            inputRanges={[]}
            dateDisplayFormat={dateFormat}
            showDateDisplay={false}
            staticRanges={showPredefined ? state.localStaticRanges : []}
            className={'popupDateRangePicker'}
            rangeColors={['var(--asc-moss)']}
            months={isMobile ? 1 : months}
            dragSelectionEnabled={!singleDay}
            showPreview={!singleDay}
          />
          <BottomBar time={showTime} id="bottom-bar">
            {showTime && (
              <DatePreview id="date-preview">
                <TimeColumn showPredefined={showPredefined} id="time-column">
                  <span>From</span>
                  <TimePicker
                    showSeconds={false}
                    data-cy="startDate"
                    dialogLocation="top"
                    setTime={(t: Date) => {
                      const startDate = set(state.startDate, {
                        hours: t.getHours(),
                        minutes: t.getMinutes(),
                        seconds: t.getSeconds(),
                      })
                      const endDate: Date =
                        durationInMinutes !== undefined
                          ? addMinutes(new Date(startDate), durationInMinutes)
                          : state.endDate
                      dispatch({type: 'setState', data: {startDate, endDate}})
                    }}
                    startTime={state.startDate}
                  />
                  {!singleDay && (
                    <div>
                      <span>On</span>
                      <span>
                        {' '}
                        {state.startDate
                          ? format(state.startDate, dateFormatNoTime)
                          : ''}
                      </span>
                    </div>
                  )}
                </TimeColumn>
                <TimeColumn showPredefined={showPredefined}>
                  <span>To</span>
                  <TimePicker
                    disabled={durationInMinutes !== undefined}
                    showSeconds={false}
                    data-cy="endDate"
                    dialogLocation="top"
                    setTime={(t: Date) => {
                      const endDate = set(state.endDate, {
                        hours: t.getHours(),
                        minutes: t.getMinutes(),
                        seconds: t.getSeconds(),
                      })
                      dispatch({type: 'setState', data: {endDate}})
                    }}
                    startTime={state.endDate}
                  />
                  {!singleDay && (
                    <div>
                      <span>On</span>
                      <span>
                        {' '}
                        {state.endDate
                          ? format(state.endDate, dateFormatNoTime)
                          : ''}
                      </span>
                    </div>
                  )}
                </TimeColumn>
              </DatePreview>
            )}
            {setRange && (
              <Buttons id="buttons">
                <CancelButton type="button" size="small" basic onClick={cancel}>
                  <Icon name="remove" />
                  CANCEL
                </CancelButton>
                <ApplyButton
                  type="button"
                  size="small"
                  primary
                  onClick={applyClick}
                  id="dateRangeApplyButton" //to scroll into view
                >
                  APPLY
                </ApplyButton>
              </Buttons>
            )}
          </BottomBar>
        </RangeContainer>
      )}
    </Container>
  )
}

export default DateRangePicker
