import styled from 'styled-components/macro'

type Props = {
  active: boolean
  colors: {
    activecolor: string
    activebgcolor: string
    inactivecolor: string
    inactivebgcolor: string
  }
}

export const ButtonAction = styled.button`
  border: 0.5px solid ${({colors}: Props) => colors.inactivebgcolor};
  background-color: ${({colors, active}) =>
    active ? colors.activebgcolor : colors.inactivebgcolor};
  color: ${({active, colors}) =>
    active ? colors.activecolor : colors.activebgcolor};
  height: 40px;
  padding: 16px 12px 16px 0px;
  width: fit-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${({colors}) => colors.activebgcolor};
    color: ${({colors}) => colors.activecolor};
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

export const ButtonInput = styled.input`
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentcolor;
  width: 32px;
  height: 32px;
  border: 1px solid ${({colors}: Props) => colors.inactivecolor};
  border-radius: 50%;
  display: grid;
  place-content: center;
  transform: scale(0.5);
  cursor: pointer;
  :checked {
    border: 1px solid ${({colors}: Props) => colors.activecolor};
    background-color: ${({colors}: Props) => colors.activebgcolor};
  }
  ::before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 32px 32px ${({colors}: Props) => colors.activecolor};
  }
  :checked::before {
    transform: scale(1);
  }
  &:hover {
    background-color: ${({colors}: Props) => colors.activebgcolor};
    border: 1px solid ${({colors}: Props) => colors.activecolor};
  }
  &:disabled {
    border: 1px solid ${({colors}) => colors.inactivebgcolor};
  }
`
