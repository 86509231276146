import * as React from 'react'
import {ToggleLeft, ToggleRight} from 'phosphor-react'
import useOnClickOutside from '../hooks/useOnClickOutside'
import {useVirtual} from 'react-virtual'
import {reducer, initialState, getNestedValue} from './multiSelectHelpers'
import {Props, CheckboxType} from './multiSelectTypes'
import usePrevious from 'common/usePrevious'
import {
  Container,
  Controls,
  Dropdown,
  Gradient,
  Input,
  InputCaret,
  OptionList,
  SearchBar,
  SearchControl,
  SearchIcon,
  SelectedItem,
  SelectedListBlurred,
  SelectedListFocused,
  ShowSelectedToggle,
  StyledCheckbox,
  Option,
  EmptyText,
} from './multiSelectStyles'

/**
 * If you are styling this Multiselct, you must pass the list data type to the styled creator i.e.:
 * const styledMultiselect = styled(MultiSelect<ListDataType>)``
 */
const MultiSelect = <TListDataType,>({
  list,
  selectedIDs,
  status = 'success',
  idField,
  labelField,
  customLabel,
  setChecked,
  // pageSize = 50,
  disabled = false,
  typeLabel = '',
  showSelectedToggle = true,
  selectedColor,
  className = ',',
  stayOpen = false,
}: Props<TListDataType>) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [open, setOpen] = React.useState(stayOpen)

  const toggleOpen = () => {
    if (!stayOpen) {
      setOpen(!open)
    }
  }
  const previous = usePrevious({
    idsSelected: state.idsSelected,
    list,
    selectedIDs,
  })

  const clickedOutside = () => {
    if (open && !stayOpen) {
      setOpen(false)
    }
  }

  const containerRef = React.useRef<HTMLDivElement>(null)
  useOnClickOutside(containerRef, clickedOutside)

  const parentRef = React.useRef<HTMLDivElement>(null)
  const {virtualItems, totalSize} = useVirtual({
    size: state.filteredCheckboxes?.length || 0,
    parentRef,
    estimateSize: React.useCallback(() => 46, []),
    overscan: 3,
  })

  //initial load of list into checkboxes
  React.useEffect(() => {
    if (list && status === 'success' && previous?.list !== list) {
      const selectedIDsInList: (string | number)[] = []
      const newCheckboxes: CheckboxType[] = []
      list.forEach(item => {
        const itemValue = item[idField] as string | number
        const foundSelected = selectedIDs?.indexOf(itemValue) > -1
        if (foundSelected) selectedIDsInList.push(itemValue as string | number)
        let label = ''
        if (typeof labelField === 'object') {
          if (customLabel) {
            label = customLabel
            labelField.forEach(field => {
              const thisField = field as keyof TListDataType
              let value = item[thisField]
              if (typeof field === 'string' && field.includes('.')) {
                value = getNestedValue(item, field)
              }
              label = label.replace(field as string, (value || '').toString())
            })
          } else {
            labelField.forEach(
              field => (label += `${item[field as keyof TListDataType]} `),
            )
          }
        } else {
          label = item[labelField as keyof TListDataType]?.toString() || ''
        }
        newCheckboxes.push({
          id: item[idField] as string | number,
          label,
          checked: foundSelected,
        })
      })
      // Sort by label
      newCheckboxes.sort((a, b) => (a.label > b.label ? 1 : -1))
      dispatch({
        type: 'setCheckboxes',
        checkboxes: newCheckboxes,
        // pageSize,
        selectedIDsInList,
      })
    }
  }, [
    list,
    idField,
    labelField,
    // pageSize,
    selectedIDs,
    status,
    previous?.list,
    customLabel,
  ])

  React.useEffect(() => {
    dispatch({
      type: 'updateSelectedIDs',
      idField: idField as string | number,
      selectedIDs: selectedIDs as (string | number)[],
    })
  }, [idField, selectedIDs])

  const checkOption = (dataid: string | number) => {
    let newSelectedIDs = []
    if (dataid && selectedIDs.includes(dataid)) {
      //we are deselecting
      newSelectedIDs = selectedIDs.filter(item => item !== dataid)
    } else {
      //we are selecting
      newSelectedIDs = selectedIDs?.length ? [...selectedIDs, dataid] : [dataid]
    }
    setChecked(newSelectedIDs)
    dispatch({type: 'checkOne', id: dataid as string | number})
  }

  const toggleSelectAll = () => {
    const newSelectedIDs: TListDataType[keyof TListDataType][] = []
    const filteredIDs: TListDataType[keyof TListDataType][] = []
    state.filteredCheckboxes.forEach(cb => {
      filteredIDs.push(cb.id as TListDataType[keyof TListDataType])
    })
    state.checkboxes.forEach(cb => {
      const thisId = cb.id as TListDataType[keyof TListDataType]
      if (filteredIDs.indexOf(thisId) > -1) {
        if (!state.allSelected) {
          newSelectedIDs.push(thisId)
        }
      } else {
        if (cb.checked) {
          newSelectedIDs.push(thisId)
        }
      }
    })
    setChecked(newSelectedIDs as (string | number)[])
    dispatch({type: 'selectAll', active: !state.allSelected})
  }

  const searchChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    dispatch({type: 'searchChange', search: value.toLowerCase()})
  }

  const toggleShowSelected = () => {
    dispatch({
      type: 'showSelected',
      active: !state.showingSelectedOnly,
    })
  }

  return (
    <Container ref={containerRef} className={className}>
      <Input
        data-lpignore={true}
        onFocus={toggleOpen}
        open={open}
        data-cy="multiselect"
      />
      {open ? (
        <SelectedListFocused onClick={toggleOpen}>
          {state.headerText
            ?.split(/&&/)
            .slice(0, 10)
            .map((text: string, index: number) => (
              <SelectedItem
                data-cy={'selected-item'}
                key={index}
                open={open}
                selectedColor={String(selectedColor)}
              >
                {text}
              </SelectedItem>
            ))}
          {!open ? <Gradient /> : null}
        </SelectedListFocused>
      ) : (
        <SelectedListBlurred onClick={toggleOpen}>
          {state.headerText
            ?.split(/&&/)
            .slice(0, 10)
            .map((text: string, index: number) => (
              <SelectedItem
                data-cy={'selected-item'}
                key={index}
                open={open}
                selectedColor={String(selectedColor)}
              >
                {text}
              </SelectedItem>
            ))}
          <Gradient />
          <InputCaret
            open={open}
            onClick={toggleOpen}
            data-cy="multiselect-input-caret"
          />
        </SelectedListBlurred>
      )}
      {open && (
        <Dropdown>
          <Controls>
            <SearchControl>
              <StyledCheckbox color="var(--primary)">
                <input
                  onChange={toggleSelectAll}
                  disabled={disabled}
                  type="checkbox"
                  checked={state.allSelected}
                  data-cy="multiselect-select-all"
                />
              </StyledCheckbox>
              <SearchBar
                placeholder={`Search ${typeLabel}...`}
                onChange={searchChange}
                value={state.searchValue}
              />
              <SearchIcon weight="light" />
            </SearchControl>
            {showSelectedToggle && (
              <ShowSelectedToggle onClick={toggleShowSelected}>
                {!state.showingSelectedOnly ? (
                  <ToggleLeft />
                ) : (
                  <ToggleRight
                    weight="fill"
                    style={{color: 'var(--asc-moss)'}}
                  />
                )}
                <span>Display Selected Only</span>
              </ShowSelectedToggle>
            )}
          </Controls>
          <OptionList ref={parentRef}>
            <div
              className="ListInner"
              style={{
                minHeight: `${totalSize}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {virtualItems.length ? (
                virtualItems.map(virtualItem => {
                  const item = state.filteredCheckboxes[virtualItem.index]
                  return (
                    <Option
                      key={item.id}
                      checked={item.checked}
                      onClick={() => checkOption(item.id)}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        transform: `translateY(${virtualItem.start}px)`,
                      }}
                    >
                      <StyledCheckbox color="var(--primary)">
                        <input
                          data-id={item.id}
                          checked={item.checked}
                          onChange={e => e.stopPropagation()}
                          id={`mscb${item.id}`}
                          data-cy={`mscb${item.id}`}
                          disabled={disabled}
                          type="checkbox"
                        />
                        {item.label}
                      </StyledCheckbox>
                    </Option>
                  )
                })
              ) : (
                <EmptyText>No Results Found</EmptyText>
              )}
            </div>
          </OptionList>
        </Dropdown>
      )}
    </Container>
  )
}
export default MultiSelect
