import * as React from 'react'
import styled from 'styled-components/macro'
import {setVisible} from './plotMarkers'
import {
  ListBullets,
  MapTrifold,
  Pause,
  Play,
  SkipBack,
  SkipForward,
} from 'phosphor-react'
import ExportButton from './ExportButton'
import Tooltip from 'common/components/Tooltip'
import SpeedDropDown, {OptionType} from './SpeedDropDown'
import {
  useHistoryTrailStore,
  getStartingBounds,
} from './hooks/useHistoryTrailStore'
import {HistoryTrailPoint} from './hooks/historyTrailTypes'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  padding-bottom: 10px;
  background-color: var(--asc-cultured);
  border-top: 1px solid var(--asc-coolgray);
  border-right: 1px solid var(--asc-coolgray);
  justify-content: space-between;
  box-shadow: 0px 4px 12px -5px rgba(0, 0, 0, 0.15);
  z-index: 5;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90px;
  align-items: center;

  @media (min-width: 768px) {
    position: unset;
    height: unset;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  justify-content: center;
  width: 50%;
  align-items: center;
  /*Control Icons*/
  svg {
    cursor: ${({disabled}: {disabled: boolean}) =>
      disabled ? 'default' : 'pointer'};
    color: ${({disabled}) =>
      disabled ? 'var(--asc-coolgray)' : 'var(--primary)'};
    :hover {
      color: ${({disabled}) =>
        disabled ? 'var(--asc-coolgray)' : 'var(--secondary)'};
    }
  }
`

const speedOptions: OptionType[] = [
  {
    key: 'Slow',
    text: 'Slow',
    value: 500,
  },
  {
    key: 'Medium',
    text: 'Medium',
    value: 150,
  },
  {
    key: 'Fast',
    text: 'Fast',
    value: 50,
  },
]

const SpeedBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  @media (max-width: 768px) {
    width: unset;
  }
`

const ShowCardsButton = styled.button`
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: solid 1px var(--primary);
  color: var(--primary);
  height: 44px;
  width: 44px;
  margin-left: 7%;
  margin-right: 20px;
  :hover {
    background-color: var(--primary);
    color: var(--asc-lime);
  }
  @media (min-width: 768px) {
    display: none;
  }
`
type Props = {
  map: google.maps.Map
  data: HistoryTrailPoint[]
  toggleShowCards: () => void
  showCards?: boolean
  disabled?: boolean
  hideExport?: boolean
}

const Controls = ({
  map,
  data,
  toggleShowCards,
  showCards = false,
  disabled = false,
  hideExport = false,
}: Props) => {
  const points = useHistoryTrailStore(state => state.eventFilterPoints)
  const setActiveID = useHistoryTrailStore(state => state.setActiveID)
  const activeID = useHistoryTrailStore(state => state.activeID)
  const pausePlayingCounter = useHistoryTrailStore(
    state => state.pausePlayingCounter,
  )
  const assetInfo = useHistoryTrailStore(state => state.assetInfo)
  const [playing, setPlaying] = React.useState(false)
  const [speed, setSpeed] = React.useState(speedOptions[1].value)
  const playingRef = React.useRef<boolean>(false)
  const speedRef = React.useRef(speedOptions[1].value)
  const panToStartingBounds = () => {
    const startingBounds = getStartingBounds(data)
    map.fitBounds(startingBounds)
  }

  const changePlayingState = (newState: boolean) => {
    //for loop needs ref to get latest value
    setPlaying(newState)
    if (playingRef?.current !== undefined) {
      playingRef.current = newState
    }
  }

  const changeSpeed = React.useCallback((e: string) => {
    // speedRef.current = result.value
    // setSpeed(result.value)
    if (speedOptions) {
      const foundSpeedOption = speedOptions.find(element => element.key === e)
      if (foundSpeedOption) {
        setSpeed(foundSpeedOption.value)
        speedRef.current = foundSpeedOption.value
      }
    }
  }, [])

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  const startPlaying = async ({animate = false}) => {
    let startSleep = !activeID || animate ? true : false
    for (let i in points) {
      if (playingRef.current) {
        setActiveID(points[i].idRaw_Data)
        setVisible({
          map,
          featureID: points[i].idRaw_Data,
          animate: startSleep,
          visible: true,
        })
        if (startSleep) {
          await sleep(Number(speedRef.current))
        }
        if (points[i].idRaw_Data === activeID) {
          startSleep = true
        }
      }
    }
    if (playingRef.current) {
      setActiveID(null)
      changePlayingState(false)
    }
  }

  const previousButtonClick = () => {
    setActiveID(points[0]?.idRaw_Data)
    map.data.forEach(feature => {
      if (feature.getGeometry()?.getType() !== 'Polygon') {
        map.data.overrideStyle(feature, {visible: false})
      }
    })
    changePlayingState(false)
  }

  React.useEffect(() => {
    //clicking on a marker should pause playing
    changePlayingState(false)
  }, [pausePlayingCounter])

  const playPoints = () => {
    if (playing) {
      changePlayingState(false)
    } else {
      changePlayingState(true)
      map.data.forEach(feature => {
        if (feature.getGeometry()?.getType() !== 'Polygon') {
          map.data.overrideStyle(feature, {visible: false})
        }
      })
      if (!activeID || points[points.length - 1].idRaw_Data === activeID) {
        setActiveID(null)
        panToStartingBounds()
        setTimeout(() => {
          startPlaying({animate: true})
        }, 100)
      } else {
        startPlaying({animate: false})
      }
    }
  }
  const nextButtonClick = () => {
    let startSleep = !activeID
    setActiveID(points[points.length - 1]?.idRaw_Data)

    points.forEach(point => {
      setVisible({
        map,
        featureID: point.idRaw_Data,
        animate: startSleep,
        visible: true,
      })
    })

    changePlayingState(false)
  }

  return (
    <Container data-cy="controls">
      <SpeedBox>
        {!disabled && (
          <SpeedDropDown
            data-cy="controlsSpeed"
            options={speedOptions}
            onChange={changeSpeed}
            speed={Number(speed)}
          />
        )}
      </SpeedBox>

      <ButtonContainer disabled={disabled}>
        {disabled ? (
          <SkipBack data-cy="previousButton" size={20} weight="fill" />
        ) : (
          <Tooltip
            tooltip={'To Beginning'}
            position={'top center'}
            trigger={
              <SkipBack
                data-cy="previousButton"
                size={20}
                onClick={previousButtonClick}
                weight="fill"
              />
            }
          />
        )}

        {disabled ? (
          <Play data-cy="playButton" size={30} weight="fill" />
        ) : playing ? (
          <Tooltip
            tooltip={'Pause History Trail'}
            position={'top center'}
            trigger={
              <Pause
                data-cy="playButton"
                size={30}
                onClick={playPoints}
                weight="fill"
              />
            }
          />
        ) : (
          <Tooltip
            tooltip={'Play History Trail'}
            position={'top center'}
            trigger={
              <Play
                data-cy="playButton"
                size={30}
                onClick={playPoints}
                weight="fill"
              />
            }
          />
        )}
        {disabled ? (
          <SkipForward data-cy="nextButton" size={20} weight="fill" />
        ) : (
          <Tooltip
            tooltip={'To End'}
            position={'top center'}
            trigger={
              <SkipForward
                data-cy="nextButton"
                size={20}
                onClick={nextButtonClick}
                weight="fill"
              />
            }
          />
        )}
      </ButtonContainer>
      {!hideExport ? (
        <ExportButton data={data} assetInfo={assetInfo || undefined} />
      ) : (
        <div style={{width: '25%'}}></div>
      )}
      <ShowCardsButton onClick={toggleShowCards}>
        {showCards ? <MapTrifold size={24} /> : <ListBullets size={24} />}
      </ShowCardsButton>
    </Container>
  )
}
export default React.memo(Controls)
