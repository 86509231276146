import {CSSProperties} from 'react'
import {Row} from 'common/components'
import {Button, Dropdown} from 'semantic-ui-react'
import styled from 'styled-components/macro'
import {Column} from '@tanstack/react-table'

export type SelectionActionButton = {
  icon?: JSX.Element
  text: string
  actionFunction: () => void
}

// eslint-disable-next-line import/no-unused-modules
export const getCommonPinningStyles = <T,>(
  column: Column<T>,
): CSSProperties => {
  const isPinned = column.getIsPinned()
  const isLastLeftPinnedColumn =
    isPinned === 'left' && column.getIsLastColumn('left')
  const isFirstRightPinnedColumn =
    isPinned === 'right' && column.getIsFirstColumn('right')

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-2px 0 2px -2px gray inset'
      : isFirstRightPinnedColumn
      ? '2px 0 2px -2px gray inset'
      : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
  }
}

export const Styles = styled.div`
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;

  table {
    border-spacing: 0;
    border: none;
    width: 100%;

    .resizer {
      position: absolute;
      right: 0;
      top: 0;
      height: 26px;
      width: 8px;
      background: white;
      cursor: col-resize;
      user-select: none;
      touch-action: none;
      border-radius: 2px;
    }

    .resizer.isResizing {
      background: var(--asc-coolgray);
      opacity: 1;
      z-index: 5;
    }

    @media (hover: hover) {
      .resizer {
        opacity: 0;
      }

      *:hover > .resizer {
        opacity: 1;
      }
    }
    thead {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 3;
    }
    /* tr,
    th {
      :last-child {
        td {
          border-bottom: 1px solid var(--table-borders);
        }
      }
    } */
    .subheader {
      margin: 0px !important;
    }
    tr .subheader {
      box-shadow: none !important;
    }
  }

  a {
    color: var(--primary);
    text-decoration: none;
    font-weight: 700;
    &:hover {
      color: var(--asc-moss) !important;
      text-decoration: underline;
    }
  }
`

export const Body = styled.tbody`
  display: grid;
  width: 100%;
  position: relative;
`

type ContainerCellType = {
  activeRow?: boolean
  index?: number
  width?: number
}

export const ContainerCell = styled.td`
  display: flex;
  align-items: center;
  background-color: ${(p: ContainerCellType) =>
    p.activeRow
      ? 'var(--asc-hoverrow)'
      : p.index !== undefined && p.index % 2 === 0
      ? 'var(--asc-cultured)'
      : 'white'};
  padding: 12px ${({width}: ContainerCellType) => (width ? 6 : 0)}px;
`

export const Cell = styled.div`
  :first-child {
    text-overflow: ellipsis;
    overflow: ${({disableOverflow}: {disableOverflow: boolean}) =>
      disableOverflow ? 'visible' : 'hidden'};
    white-space: nowrap;
    width: 100%;
  }
`

export const HeaderContainer = styled.th`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  /* padding: 3px; */
  text-align: left;
  position: relative;
  padding: 3px;
  padding-right: ${({align}: {align: 'left' | 'center' | 'right'}) =>
    align === 'right' ? '10px' : '3px'};
  /* border-bottom: 1px var(--table-borders) solid; */
  background: var(--primary);
  color: white;
  margin: 0;
  overflow: visible;
  td {
    vertical-align: bottom;
    height: 100%;
  }
`

export const SubHeader = styled.div`
  background: var(--asc-cultured);
  padding: 5px;
  width: 100%;
  margin-bottom: 0;
  flex: 1;
  height: 80px;
  max-height: 80px;
  display: flex;
  padding-right: 20px;
  h4 {
    font-size: 1.4rem;
    margin-bottom: 0;
    padding-left: 10px;
  }

  justify-content: space-between;
  align-items: center;
`

export const CenteredColumn = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const SortedArrow = styled.div`
  &&& {
    width: 14px !important;
    height: 14px !important;
    margin-left: 3px;
    margin-right: 10px;
    svg {
      width: 14px !important;
      height: 14px !important;
      min-width: 14px !important;
      min-height: 14px !important;
    }
  }
`

export const ScrollableTable = styled.div`
  height: ${({height}: {height?: string}) =>
    height ? height : 'calc(100vh - 176px)'};
  overflow: auto;
  position: relative;
  max-width: 100%;
  width: 100%;
`

export const StyledTableRow = styled.tr`
  display: flex;
  position: absolute;
  width: 100%;
  background-color: white;
  /* border: 1px solid lightgray; */
  cursor: ${({clickable}: {clickable: boolean; index: number}) =>
    clickable ? 'pointer' : 'inherit'};
  :hover {
    td {
      background-color: var(--asc-hoverrow) !important;
      color: black !important;
    }
  }
  //visible class allows dropdowns to float other absolutely positioned rows
  :has(.visible) {
    z-index: 99;
  }
  .directDistance {
    background-color: var(--asc-moss) !important;
    font-weight: 700;
    opacity: ${({index}: {clickable: boolean; index: number}) =>
      index !== undefined && index % 2 === 0 ? 1 : 0.8} !important;
    :hover {
      color: black !important;
    }
  }
`

type ResizableHeaderType = {
  canSort: boolean
  headerAlign: string | undefined
}

export const ResizableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: ${({canSort}: ResizableHeaderType) =>
    canSort ? 'pointer' : 'default'};
  justify-content: ${({headerAlign}: ResizableHeaderType) =>
    headerAlign === 'right'
      ? 'flex-end'
      : headerAlign === 'center'
      ? 'center'
      : 'space-between'};
`

export const RightButtons = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`

export const SelectAllRow = styled(Row)`
  align-items: center;
  gap: 20px;
  min-width: 225px; //prevents other buttons from moving left and right when selecting items to delete
`

export const ButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

// export const DeleteButton = styled.button`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   background: ${({deleteSelected}: {deleteSelected: boolean}) =>
//     deleteSelected ? 'var(--asc-vermilion)' : 'var(--asc-platinum)'};
//   border: ${({deleteSelected}: {deleteSelected: boolean}) =>
//     deleteSelected ? '1px solid #D24341' : '1px solid #eaeaec'};
//   font-weight: bold;
//   border-radius: 5px;
//   color: ${({deleteSelected}: {deleteSelected: boolean}) =>
//     deleteSelected ? 'white' : 'var(--asc-dimgray)'};
//   cursor: ${({deleteSelected}: {deleteSelected: boolean}) =>
//     deleteSelected ? 'pointer' : 'default'};
//   svg {
//     margin-right: 5px;
//     margin-top: 2px;
//   }
// `

// export const UpdateButton = styled.button`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   background: ${({updateSelected}: {updateSelected: boolean}) =>
//     updateSelected ? 'var(--asc-moss)' : 'var(--asc-platinum)'};
//   border: ${({updateSelected}: {updateSelected: boolean}) =>
//     updateSelected ? '1px solid var(--asc-moss)' : '1px solid #eaeaec'};
//   font-weight: bold;
//   border-radius: 5px;
//   color: ${({updateSelected}: {updateSelected: boolean}) =>
//     updateSelected ? 'white' : 'var(--asc-dimgray)'};
//   cursor: ${({updateSelected}: {updateSelected: boolean}) =>
//     updateSelected ? 'pointer' : 'default'};
//   svg {
//     margin-right: 5px;
//     margin-top: 2px;
//   }
// `
// export const ButtonHolder = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
// `
export const StyledDropdown = styled(Dropdown)`
  &&& {
    .icon {
      display: none;
    }
    .menu {
      width: 142px;
      padding: 8px;
      background-color: white;
      border: 1px solid var(--asc-subduedmoss);
      .item {
        padding: 5px 10px !important;
        background: white;
        line-height: 22px;

        :hover {
          background: var(--secondary);
        }
      }
    }
    .text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }
`
export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  /* align-items: center; */
  /* align-self: center; */
  a {
    color: black;
    font-weight: 500;
  }
  a:hover {
    color: black !important;
    text-decoration: none;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  a:hover {
    text-decoration: none;
  }
`
export const ActionButton = styled.button`
  background-color: white;
  border-radius: ${({noOptions = true}: {noOptions: boolean}) =>
    noOptions ? '5px' : '5px 0px 0px 5px'};
  border-width: 1px 1px 1px 1px;
  border-color: var(--asc-subduedmoss);
  color: var(--asc-subduedmoss);
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  height: 30px;
  margin: 0;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
  gap: 3px;
  cursor: pointer;
  :first-of-type {
    padding: 0 15px;
  }
  :hover {
    background-color: var(--primary);
    color: var(--asc-keylime);
  }
`

export const ActionToggle = styled(Button)`
  &&& {
    border-radius: 0px 5px 5px 0px;
    border-width: 1px 1px 1px 0px;
    padding: 0 10px !important;
    border: solid 1px var(--asc-subduedmoss);
    border-left: none;
    background-color: ${({open}: {open: boolean}) =>
      open ? 'var(--primary)' : 'white'};
    color: ${({open}) =>
      open ? 'var(--asc-keylime)' : 'var(--asc-subduedmoss)'};
    svg {
      min-height: 14px;
      min-width: 14px;
    }
    :hover {
      background-color: var(--primary);
      color: var(--asc-keylime);
    }
  }
`
