import {create} from 'zustand'
import {GutStateType, initialGutAssetState} from 'gut/gutHelpers'
import {Asset, Device} from 'common/types/opening1Response'
import {ActiveMarker} from 'common/types/typesModule'

interface DirectionsType extends Partial<google.maps.DirectionsRenderer> {
  routes: google.maps.DirectionsRenderer[]
  directionsServiceArray?: google.maps.DirectionsService[]
  markers?: google.maps.Marker[]
  route?: google.maps.DirectionsResult
}

type GutAlertDataType = {
  count: number
  color: 'green' | 'red' | 'yellow'
  types: string[]
}

type MapStoreType = {
  markers: google.maps.Marker[]
  clusters: google.maps.Marker[]
  recentAssets: Asset[]
  locationMarkers: google.maps.Marker[]
  zoneMarkers: google.maps.Marker[]
  directions: DirectionsType
  totalActiveAssets: number
  activeMarker?: ActiveMarker
  activeUnassignedMarker?: Device
  showBoxSearch: boolean
  boxSearchMarker: google.maps.Marker | null
  boxSearchRectangle: null
  boxSearchDrawingManager: null
  addOrdersButton: null
  gutAssetData: GutStateType

  gutAlertData: GutAlertDataType
  showingNearestAssetDistances: boolean
  nearByDistances: {idAsset: number; distance: number}[]
  gridSort: null
  showDropdown: boolean
  clickedLocation: {id: number; coordinates: {lat: number; lng: number}} | null
  showAssetMarkers: boolean
  showClusters: boolean
  showAssetLabels: boolean
  setDirections: (directions: DirectionsType) => void
  removeDirections: () => void
  setActiveMarker: (marker: ActiveMarker) => void
  setActiveUnassignedMarker: (marker: Device) => void
  setTotalActiveAssets: (count: number) => void
  emptyStore: () => void
}

const removeDirections = (directions: DirectionsType) => {
  directions.routes?.map(route => {
    if (route !== null) {
      route.setMap(null)
    }
    return route
  })
  directions.routes = []
  directions.directionsServiceArray = []
  directions.markers?.map(marker => marker.setMap(null))
  directions.markers = []
}

const initialGutAlertData: GutAlertDataType = {
  count: 0,
  color: 'green',
  types: [],
}

const initialStore = {
  markers: [],
  clusters: [],
  recentAssets: [],
  locationMarkers: [],
  zoneMarkers: [],
  directions: {
    routes: [],
    directionsServiceArray: [],
    markers: [],
  },
  totalActiveAssets: 0,
  activeMarker: undefined,
  activeUnassignedMarker: undefined,
  showBoxSearch: false,
  boxSearchMarker: null,
  boxSearchRectangle: null,
  boxSearchDrawingManager: null,
  addOrdersButton: null,
  gutAssetData: initialGutAssetState,
  gutAlertData: initialGutAlertData,
  showingNearestAssetDistances: false,
  nearByDistances: [],
  gridSort: null,
  showAssetLabels: true,
  showClusters: true,
  showDropdown: false,
  clickedLocation: null,
  showAssetMarkers: true,
}

export const useMapStore = create<MapStoreType>()((set, get) => ({
  ...initialStore,
  removeDirections: () => {
    const directions = get().directions
    removeDirections(directions)
    set({directions: {routes: [], markers: [], directionsServiceArray: []}})
  },
  setDirections: (directions: DirectionsType) => {
    set({directions})
  },
  setActiveMarker: (marker: ActiveMarker) => {
    set({activeUnassignedMarker: undefined})
    set({activeMarker: marker})
  },
  setActiveUnassignedMarker: (marker: Device) => {
    set({activeMarker: undefined})
    set({activeUnassignedMarker: marker})
  },
  setTotalActiveAssets: (count: number) => set({totalActiveAssets: count}),
  emptyStore: () => set(() => ({...initialStore})),
}))
