import Axios from 'axios'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

const vinAuditPromise = async vin => {
  const {data} = await Axios.get(`/vinaudit/getvinauditdata/${vin}`)
  return data
}

const getVINAudit = async vin => {
  const [vinAuditDetails] = await Promise.all([vinAuditPromise(vin)])
  const returnObject = {
    ...vinAuditDetails,
  }
  return returnObject
}

const useVINAudit = vin => {
  const user = useStore(state => state.user)
  const queryClient = useQueryClient()
  return useQuery(['vinAudit', vin], () => getVINAudit(vin), {
    enabled: Boolean(vin && user?.token),
    keepPreviousData: true,
    initialData: () => {
      return queryClient.getQueryData(['opening1'])
        ?.assets?.find(d => d.Vin === vin);
    },
  });
}

export default useVINAudit
