import * as React from 'react'
import styled from 'styled-components/macro'

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  /*Checkbox styling... Referenced: https://moderncss.dev/pure-css-custom-checkbox-style/*/
  input[type='checkbox'] {
    appearance: none;
    background-color: ${props =>
      props.disabled ? 'var(--asc-platinum)' : 'var(--asc-cultured)'};
    margin: 0;
    font: inherit;
    width: 16px;
    height: 16px;
    border: 1px solid var(--asc-coolgray);
    border-radius: 5px;
    /* transform: translateY(-0.075em); */
    display: grid;
    place-content: center;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }
  :hover && ${props => !props.disabled}{
    input {
      border: 1px solid var(--asc-moss);
      box-sizing: border-box;
      box-shadow: 0px 0px 2px 1px var(--secondary)};
      border-radius: 5px;
    }
  }
  /*Checkmark*/
  input[type='checkbox']::before {
    content: '';
    width: 0.7em;
    height: 0.7em;
    border-radius: 4px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background: var(--asc-cultured);
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }
  /*Checked state */
  input[type='checkbox']:checked {
    background-color: ${props =>
      props.color ? props.color : 'var(--primary)'};
    border: ${props =>
      props.color ? `1px solid ${props.color}` : `1px solid var(--primary)`};
  }
  input[type='checkbox']:checked::before {
    transform: scale(1);
  }
`
const CheckboxRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: fit-content;
  align-items: ${props => props.alignItems};
  input {
    min-width: 15px; //prevents checkbox from being squished
  }
`

const CheckboxLabel = styled.div`
  font-weight: bold;
  font-size: 13px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

const Checkbox = ({
  selected = false,
  dataCy,
  onChange,
  disabled,
  color = 'var(--primary)',
  className = null,
  children = null,
  alignItems = 'center',
  label = '',
}) => {
  return (
    <CheckboxContainer disabled={disabled} color={color} className={className}>
      <CheckboxRow alignItems={alignItems}>
        <input
          type="checkbox"
          checked={selected}
          readOnly
          data-cy={dataCy}
          onChange={onChange}
          disabled={disabled}
        />
        {label !== '' && (
          <CheckboxLabel
            disabled={disabled}
            onClick={disabled ? null : onChange}
          >
            {label}
          </CheckboxLabel>
        )}
        {children}
      </CheckboxRow>
    </CheckboxContainer>
  )
}
export default Checkbox
