import * as React from 'react'
import styled from 'styled-components/macro'
import {Row} from './StyledComponents'
import {PropsWithChildren} from 'react'

type Props = {
  marginLeft?: string
  marginTop?: string
}

const BadgeContainer = styled(Row)`
  align-items: center;
  min-width: 35px;
  min-height: 1em;
  padding: 0.37em;
  line-height: 1em;
  justify-content: center;
  border-radius: 500rem;
  background-color: var(--secondary);
  box-shadow: 1px 1px 1px var(--asc-coolgray);
  color: black;
  font-weight: bold;
  margin-left: ${({marginLeft}: {marginLeft?: string; marginTop?: string}) =>
    marginLeft ? marginLeft : '15px'};
  margin-top: ${({marginTop}: {marginTop?: string; marginLeft?: string}) =>
    marginTop ? marginTop : '3px'};
`
const Badge = (props: PropsWithChildren<Props>) => {
  return (
    <BadgeContainer
      data-cy="badge"
      marginLeft={props?.marginLeft}
      marginTop={props?.marginTop}
    >
      {props.children}
    </BadgeContainer>
  )
}
export default Badge
