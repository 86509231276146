import * as React from 'react'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components/macro'
import useDeviceDetail from './hooks/useDeviceDetail'
import useCarriers from 'management/hooks/useCarriers'
import useDeviceTypes from 'management/hooks/useDeviceTypes'
import {
  InputWithLabel,
  InputDropdownOptional,
  TextAreaWithLabel,
} from 'common/components'
import {SemanticInputType} from 'common/components/InputDropdownOptional'
import Modal from 'common/Modal'
import {CustomerType} from 'management/hooks/useCustomers'
import {DeviceType} from 'management/types/types'
import usePutDevice, {EditDeviceType, AddDeviceType} from './hooks/usePutDevice'
import usePostDevice from './hooks/usePostDevice'
import Swal from 'sweetalert2'
import {showToast} from 'common/components/Toastr'
import {
  initialState,
  reducer,
  errorCheck,
  onChangeInput,
  onDropdownChange,
} from './deviceHelpers'
import useDeviceStatuses from './hooks/useDeviceStatuses'

const StyledModal = styled(Modal)`
  h2 {
    width: 100%;
  }
`

const Container = styled.div`
  display: flex;
  padding: 16px 32px 32px 32px;
  gap: 16px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const NoCustomerSelectedMessage = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 1;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin: 0;
  height: 300px;
  justify-content: center;
  align-items: center;
  font-size: calc(17 / 14 * 1rem);
  padding: 10px;
`

const DeviceForm = ({
  selected,
  dismissModal,
  idDeviceEdit,
}: {
  selected: CustomerType
  dismissModal: () => void
  idDeviceEdit?: number | null
}) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    device: {...initialState.device, idCustomer: selected.idcustomer},
  })
  const {data: deviceDetailData, status} = useDeviceDetail(Number(idDeviceEdit))
  const {data: carriers} = useCarriers()
  const {data: deviceTypes} = useDeviceTypes()
  const {data: deviceStatuses} = useDeviceStatuses()
  const location = useLocation()
  const {pathname} = location
  const {mutateAsync: postDevice, status: postDeviceStatus} = usePostDevice()
  const {mutateAsync: putDevice, status: putDeviceStatus} = usePutDevice()

  const submitForm = async () => {
    const errors = errorCheck(state)
    dispatch({type: 'setState', data: {errors}})
    const errorCount = Object.keys(errors).length
    if (errorCount > 0) {
      Swal.fire({
        icon: 'error',
        title: 'We need a little more detail...',
        text: 'Please check that all required information is included and try again.',
      })
      return
    } else {
      try {
        if (idDeviceEdit) {
          await putDevice(state.device as EditDeviceType)
          showToast('Device successfully edited', 'success')
        } else {
          await postDevice(state.device as AddDeviceType)
          showToast('Device successfully added', 'success')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong! Please check your entries and try again.',
        })
      }
    }
    dismissModal()
  }

  const carrierOptions = React.useMemo(() => {
    if (carriers) {
      return carriers.map((carrier: {idCarrier: number; Name: string}) => ({
        key: carrier.Name,
        text: carrier.Name,
        value: carrier.idCarrier,
      }))
    } else return []
  }, [carriers])

  const deviceStatusOptions = React.useMemo(() => {
    if (deviceStatuses) {
      return deviceStatuses?.map(
        (deviceStatus: {
          idDevice_Status: number
          Status_Description: string
        }) => ({
          text: deviceStatus.Status_Description,
          value: deviceStatus.idDevice_Status,
          key: deviceStatus.idDevice_Status,
        }),
      )
    }
  }, [deviceStatuses])

  const deviceTypeOptions = React.useMemo(() => {
    if (deviceTypes) {
      return deviceTypes?.map((deviceType: DeviceType) => ({
        text: deviceType.Device_Description,
        value: deviceType.idDevice_Type,
        key: deviceType.idDevice_Type,
      }))
    } else return []
  }, [deviceTypes])

  const inputChanged = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChangeInput({e, dispatch})
  }
  const dropdownChanged = ({
    name,
    value,
  }: {
    name: string
    value: SemanticInputType
  }) => onDropdownChange({name, value, dispatch})

  React.useEffect(() => {
    if (status === 'success' && deviceDetailData) {
      dispatch({type: 'setState', data: {device: deviceDetailData, errors: {}}})
    }
  }, [idDeviceEdit, status, deviceDetailData])

  if (pathname.includes('/support/devices') && !selected)
    return (
      <NoCustomerSelectedMessage>
        Select a customer above to manage devices
      </NoCustomerSelectedMessage>
    )

  return (
    <StyledModal
      title={`${selected?.customer_name} - ${
        idDeviceEdit ? 'Edit' : 'Add'
      } Device`}
      onDismiss={dismissModal}
      onSave={submitForm}
      showDialog={true}
      showClose={true}
      width="90%"
      maxWidth="1200px"
      renderFooter={true}
      submitButtonText={'Save'}
      height="fit-content"
      scrollable={false}
      submitting={
        postDeviceStatus === 'loading' ||
        putDeviceStatus === 'loading' ||
        (Boolean(idDeviceEdit) && status === 'loading')
      }
    >
      <Container>
        <Column>
          <InputWithLabel
            label="Customer ID"
            name="idCustom"
            value={selected.idcustomer || ''}
            onChange={() => {}}
            disabled={true}
          />
          <InputWithLabel
            label="Serial Number"
            name="Serial_Number_Displayed"
            value={state.device.Serial_Number_Displayed || ''}
            onChange={inputChanged}
            required={true}
            errors={state.errors}
          />

          <InputDropdownOptional
            label="Device Type"
            options={deviceTypeOptions || []}
            onChange={dropdownChanged}
            value={
              state.device?.idDevice_Type === undefined ||
              state.device?.idDevice_Type === null
                ? ''
                : state.device.idDevice_Type
            }
            name="idDevice_Type"
            required={true}
            errors={state.errors}
            clearable={false}
          />
          <InputDropdownOptional
            label="Carrier"
            options={carrierOptions}
            onChange={dropdownChanged}
            value={state.device?.idCarrier || null}
            name="idCarrier"
            required={true}
            errors={state.errors}
            clearable={false}
          />
        </Column>
        <Column>
          <InputWithLabel
            label="IMEI"
            name="IMEI"
            value={state.device.IMEI || ''}
            onChange={inputChanged}
          />
          <InputWithLabel
            label="Order Number"
            name="Order_Nbr"
            value={state.device.Order_Nbr || ''}
            onChange={inputChanged}
          />
          <InputWithLabel
            label="Item Number"
            name="Item_Nbr"
            value={state.device.Item_Nbr || ''}
            onChange={inputChanged}
          />
          <InputWithLabel
            label="Order Price"
            name="Order_Price"
            value={state.device.Order_Price || ''}
            onChange={inputChanged}
            type="number"
            decimals={2}
          />
        </Column>
        <Column>
          <InputWithLabel
            label="TMC Part Number"
            name="TMC_Part_Nbr"
            value={state.device.TMC_Part_Nbr || ''}
            onChange={inputChanged}
          />
          <InputWithLabel
            label="SIM Number"
            name="SIMNBR"
            value={state.device.SIMNBR || ''}
            onChange={inputChanged}
          />

          <InputWithLabel
            label="Salesforce Customer ID"
            name="Customer_ID"
            value={state.device.Salesforce_Customer_ID || ''}
            onChange={inputChanged}
          />
        </Column>
        <Column>
          <InputWithLabel
            label="Device Model"
            name="DeviceModel"
            value={state.device.DeviceModel || ''}
            onChange={inputChanged}
          />
          <InputDropdownOptional
            label="Device Status"
            options={deviceStatusOptions || []}
            onChange={dropdownChanged}
            value={
              state.device?.idDevice_Status === undefined ||
              state.device?.idDevice_Status === null
                ? ''
                : state.device.idDevice_Status
            }
            name="idDevice_Status"
            errors={state.errors}
          />
          <TextAreaWithLabel
            label="Device Notes"
            name="Device_Notes"
            onChange={inputChanged}
            defaultValue={state.device.Device_Notes || ''}
          />
        </Column>
        <Column>
          <InputWithLabel
            label="idCustomer_Final"
            name="idCustomer_Final"
            value={state.device.idCustomer_Final || ''}
            onChange={inputChanged}
            type="number"
          />
          <InputWithLabel
            label="idDevice_Final"
            name="idDevice_Final"
            value={state.device.idDevice_Final || ''}
            onChange={inputChanged}
            type="number"
          />
          <InputDropdownOptional
            name="DeviceSwapType"
            label="Device Swap Type"
            options={[
              {text: 'RMA', value: 1, key: 1},
              {text: 'Tech Upgrade', value: 2, key: 2},
              {text: 'Dealer Discretion', value: 3, key: 3},
            ]}
            onChange={dropdownChanged}
            value={state.device.DeviceSwapType || null}
            clearable={true}
            required={false}
            errors={{}}
          />
          <InputWithLabel
            label="Dealer ID (Hot Swap)"
            name="idDealer_Swappable"
            value={state.device.idDealer_Swappable || ''}
            onChange={inputChanged}
            type="number"
          />
        </Column>
      </Container>
    </StyledModal>
  )
}
export default DeviceForm
