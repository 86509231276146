import styled from 'styled-components/macro'
import {QUERIES} from 'common/constants'

export const MapControlContainer = styled.div<{hasOnlyOneButton?: boolean}>`
  display: none;
  @media ${QUERIES.tabletAndUp} {
    display: flex;
  }
  flex: 1;
  flex-direction: row;
  position: relative;
  top: 10px;
  right: 5px;
  white-space: nowrap;
  border: 1px solid var(--asc-graphite);
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  /* // height: 40px; */
  button {
    padding: 6px 5px; /* Some padding */
    cursor: pointer; /* Pointer/hand icon */
    float: left; /* Float the buttons side by side */
    border: 1px solid var(--asc-coolgray);
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  button:last-child {
    border-radius: 20px;
    @media ${QUERIES.tabletAndUp} {
      border-radius: ${({hasOnlyOneButton}) =>
        hasOnlyOneButton ? '20px' : '0px 20px 20px 0px'} !important;
    }

    /* Prevent double borders */
    /* border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; */
  }
  button:first-child {
    border-radius: 20px;
    @media ${QUERIES.tabletAndUp} {
      border-radius: ${({hasOnlyOneButton}) =>
        hasOnlyOneButton ? '20px' : '20px 0px 0px 20px'} !important;
    }
    /* border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; */
  }
  :after {
    content: '';
    clear: both;
    display: table;
  }
`

export const MobileMapControlContainer = styled(MapControlContainer)`
  display: flex;
  @media ${QUERIES.tabletAndUp} {
    display: none;
  }
  button {
    border-radius: 20px;
  }
`

export const MapControlButton = styled.button<{isActive?: boolean}>`
  background-color: ${({isActive}) =>
    isActive ? 'var(--primary)' : 'white'} !important;
  color: ${({isActive}) =>
    isActive ? 'var(--secondary)' : 'var(--asc-sonicsilver)'} !important;
  cursor: pointer;
  flex-direction: row;
  display: flex;
  justify-content: center;
  svg {
    margin: 3px;
  }
  :hover {
    background: var(--secondary) !important;
    color: var(--primary) !important;
  }
`

export const DropdownMenu = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #949494;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 80px;
  width: 170px;
  right: 95px;
  top: 45px;
`
export const DropdownMenuItem = styled.div`
  padding: 10px;
  justify-content: flex-start;
  display: flex;
  flex: 1;
  font-size: 14px;
  border-bottom-width: 1px;
  border-bottom-color: #000;
  .checkbox {
    :hover {
      input {
        border: 1px solid var(--asc-moss);
        box-sizing: border-box;
        box-shadow: 0px 0px 6px 1px var(--secondary);
        border-radius: 5px;
      }
    }
    input[type='checkbox'] {
      appearance: none;
      background-color: var(--asc-cultured);
      margin: 0;
      font: inherit;
      border: 1px solid var(--asc-coolgray);
      border-radius: 5px;
      display: grid;
      place-content: center;
      opacity: 1 !important;
      :focus {
        border: 1px solid var(--asc-coolgray) !important;
      }
    }
    /*Checkmark*/
    input[type='checkbox']::before {
      content: '';
      width: 0.7em;
      height: 0.7em;
      border-radius: 4px;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background: var(--asc-cultured);
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
    /*Checked state */
    input[type='checkbox']:checked {
      background-color: var(--primary);
      border: 1px solid var(--primary);
      z-index: 2;
    }
    input[type='checkbox']:checked::before {
      transform: scale(1);
    }
  }
`
