import * as React from 'react'
import styled from 'styled-components/macro'
import {Spinner} from 'common/components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px;
  max-height: 222px;
`

const SensorCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #d2d2d7;
  box-sizing: border-box;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  margin-bottom: 20px;
`

const SensorHeader = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
`

const SensorMessage = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`

const TimeStamp = styled.span`
  font-size: 13px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.5px;
  color: var(--asc-sonicsilver);
  margin-bottom: 15px;
`
const SensorName = styled.span`
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 10px;
`

const SensorsTab = ({sensorData}) => {
  if (status === 'loading' || !sensorData) return <Spinner type="partial" />

  return (
    <Container>
      {sensorData.map((sensor, index) => {
        return (
          <SensorCard key={index}>
            <SensorHeader>{sensor.Sensor_Type}</SensorHeader>
            <SensorName>{`${
              sensor.Name === null ? sensor.SystemName : sensor.Name
            }`}</SensorName>
            {sensor.SensorLastState?.SensorState ? (
              <>
                <TimeStamp>
                  {`${sensor.SensorLastState.LastSensorStateOnDate}  ${sensor.SensorLastState?.LastSensorStateOnTime}`}
                </TimeStamp>
                <SensorMessage>{`${
                  sensor.SensorLastState.SensorState?.Name === null
                    ? sensor.SensorLastState.SensorState.SystemName
                    : sensor.SensorLastState.SensorState.Name
                }`}</SensorMessage>
              </>
            ) : (
              <div>No previous state found.</div>
            )}
          </SensorCard>
        )
      })}
    </Container>
  )
}
export default SensorsTab
