import ReactDOMServer from 'react-dom/server'
import {useMapStore} from './useMapStore'
import createHTMLMapMarker from './MarkerOverlay'
import Supercluster from 'supercluster'
// import {dequal} from 'dequal'
import Marker from './Marker'
import ClusterMarker from './ClusterMarker'
import {MARKER_COLORS} from 'common/constants'
import {notNullUndefined} from 'common/helpers'

export function lookupColor(id) {
  // let colors = {
  //   1: '#b3171a',
  //   2: '#fc4d00',
  //   3: '#e59f23',
  //   4: '#955f1a',
  //   5: '#006200',
  //   6: '#40E0D0',
  //   7: '#0C395B',
  //   8: '#0000FF',
  //   9: '#5d006b',
  //   10: '#cc11be',
  //   11: '#562f14',
  //   12: '#000000',
  //   unknown: '#ffffff',
  // }

  if (notNullUndefined(id)) {
    if (Object.prototype.hasOwnProperty.call(MARKER_COLORS, id)) {
      return MARKER_COLORS[id]
    } else {
      return MARKER_COLORS.unknown
    }
  } else {
    return MARKER_COLORS.unknown
  }
}

export const createPoints = (assets = []) => {
  //transform points into format needed for supercluster (GEOJson)
  const points = []
  assets.forEach(asset => {
    if (
      asset.Device.Longitude !== undefined &&
      asset.Device.Longitude !== null &&
      asset.Device.Latitude !== undefined &&
      asset.Device.Latitude !== null &&
      !asset.Primary_idAsset
    ) {
      points.push({
        type: 'Feature',
        properties: {
          idAsset: asset.idAsset,
          idDevice: asset.Device.idDevice,
          Asset_Label: asset.Asset_Label,
          heading: asset.Device.Last_Heading,
          inMotion: asset.Device.In_Motion,
          color: lookupColor(asset.Icon_Color),
          alerts: asset.Alert_Count || 0,
          maint: (asset.MaintDueCount || 0) + (asset.MaintOverdueCount || 0),
        },
        geometry: {
          type: 'Point',
          coordinates: [
            parseFloat(asset.Device.Longitude),
            parseFloat(asset.Device.Latitude),
          ],
        },
      })
    }
  })
  return points
}

export const getPointsBounds = (points, maps) => {
  if (points && points.length && maps) {
    let newBounds = new maps.LatLngBounds()
    points.forEach(point => {
      const lat = point.Device.Latitude
      const lng = point.Device.Longitude
      if (lat && lng) {
        newBounds.extend(new maps.LatLng(lat, lng))
      }
    })
    return newBounds
  }
  return null
}

const calculateClusters = (points, bounds, zoom, minPoints) => {
  if (!points?.length || !bounds || !zoom)
    return {clusters: [], supercluster: null}
  const supercluster = new Supercluster({
    radius: 100,
    maxZoom: 16,
    minPoints: minPoints,
    map: props => ({
      alerts: props.alerts,
      maint: props.maint,
    }),
    reduce: (acc, props) => {
      acc.alerts += props.alerts
      acc.maint += props.maint
      return acc
    },
  })
  supercluster.load(points)
  const clusters = supercluster.getClusters(bounds, zoom)
  return {
    clusters,
    supercluster,
  }
}

export const onClusterClick = (cluster, supercluster, map) => {
  if (supercluster) {
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(cluster.id) + 2,
      17,
    )

    const location = {
      lat: cluster?.geometry?.coordinates[1],
      lng: cluster?.geometry?.coordinates[0],
    }

    map.setZoom(expansionZoom)
    map.panTo(location)
  }
}

export const onMarkerClick = (idAsset, map, setActiveMarker, gridOpen) => {
  if (idAsset) {
    const {markers: currentMarkers} = useMapStore.getState()
    const clickedMarker = currentMarkers.find(m => m.idAsset === idAsset)

    const latLng = {
      lat: clickedMarker?.geometry?.coordinates[1],
      lng: clickedMarker?.geometry?.coordinates[0],
    }
    const div = map.getDiv()
    if (map.getZoom() < 17) {
      map.setZoom(17)
    }
    map.panTo(latLng)
    if (div) {
      if (gridOpen) map.panBy(0, -div.offsetHeight / 3.5)
      else map.panBy(0, -div.offsetHeight / 7)
    }
    if (setActiveMarker) {
      const newActiveMarker = {
        idAsset: idAsset,
        idDevice: clickedMarker.idDevice,
        label: clickedMarker.properties.Asset_Label,
        lat: latLng.lat,
        lng: latLng.lng,
        following: true,
        heading: clickedMarker.heading,
      }
      const existingFollowingMarkers = [
        ...document.querySelectorAll('#map-container .following'),
      ]
      existingFollowingMarkers.forEach(m => {
        m.classList.remove('following')
      })
      clickedMarker.googleMarker.div.children[0].classList.add('following')
      setActiveMarker(newActiveMarker)
    }
  }
}

export const clearAllMarkersAndClusters = () => {
  const {markers: currentMarkers, clusters: currentClusters} =
    useMapStore.getState()
  currentMarkers.forEach(marker => {
    marker.googleMarker.remove()
  })
  currentClusters.forEach(cluster => {
    cluster.googleMarker.remove()
  })
}

export const plotMarkersAndClusters = (data, map, newActive) => {
  const {markers: currentMarkers, clusters: currentClusters} =
    useMapStore.getState()
  const setActiveMarker = useMapStore.getState().setActiveMarker
  if (!data?.length) {
    //no markers to plot
    currentMarkers.forEach(marker => {
      marker.googleMarker.remove()
    })
    currentClusters.forEach(cluster => {
      cluster.googleMarker.remove()
    })
    useMapStore.setState({markers: [], clusters: []})
    setActiveMarker(null)
  } else {
    const activeMarker = useMapStore.getState().activeMarker

    const showAssetLabels = useMapStore.getState().showAssetLabels
    const showClusters = useMapStore.getState().showClusters
    const gridOpen = useMapStore.getState().gridOpen
    const geoJSON = createPoints(data)
    const zoom = map.getZoom()
    const bounds = map.getBounds()
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    const coords = [sw.lng(), sw.lat(), ne.lng(), ne.lat()]
    const minPoints = showClusters ? 2 : 1000
    let {clusters: points, supercluster} = calculateClusters(
      geoJSON,
      coords,
      zoom,
      minPoints,
    )

    const newMarkers = []
    const newClusters = []

    const existingActiveMarkers = [
      ...document.querySelectorAll('#map-container .activeMarker'),
    ]
    existingActiveMarkers.forEach(m => {
      if (!activeMarker || m.id !== `markerContainer-${activeMarker.idAsset}`) {
        m.classList.remove('activeMarker')
        m.classList.remove('following')
      }
    })

    //look for markers that need to be moved or removed
    currentMarkers.forEach(marker => {
      const foundPoint = points.find(
        point => point.properties.idAsset === marker.properties.idAsset,
      )
      if (foundPoint) {
        newMarkers.push(marker)
      } else {
        //marker no longer is valid - remove from map
        marker.googleMarker.remove()
      }
    })

    //look for clusters that have changed counts
    currentClusters.forEach(cluster => {
      const foundPoint =
        showClusters && points.find(point => point.id === cluster.id)
      if (!foundPoint) {
        cluster.googleMarker.remove()
      } else {
        newClusters.push(cluster)
      }
    })

    //now look for points that don't have markers/clusters
    points.map(point => {
      if (
        !point.properties.cluster &&
        point.geometry?.coordinates[0] &&
        point.geometry?.coordinates[1]
      ) {
        const latLng = new window.google.maps.LatLng(
          point.geometry?.coordinates[1],
          point.geometry?.coordinates[0],
        )
        const foundMarker = currentMarkers.find(
          marker => marker.idAsset === point.properties.idAsset,
        )

        if (foundMarker && showAssetLabels === true) {
          foundMarker.googleMarker.addLabels()
        } else if (foundMarker && showAssetLabels === false) {
          foundMarker.googleMarker.removeLabels()
        }

        if (!foundMarker && point) {
          const markerEl = Marker({point}, activeMarker)
          const markerString = ReactDOMServer.renderToString(markerEl)
          let marker = createHTMLMapMarker({
            idAsset: point.properties.idAsset,
            idDevice: point.properties.idDevice,
            latlng: latLng,
            html: markerString,
            map: map,
            showAssetLabels,
          })

          marker.addListener('click', () =>
            onMarkerClick(
              point.properties.idAsset,
              map,
              setActiveMarker,
              gridOpen,
            ),
          )
          newMarkers.push({
            idAsset: point.properties.idAsset,
            idDevice: point.properties.idDevice,
            properties: point.properties,
            geometry: {coordinates: point.geometry.coordinates},
            googleMarker: marker,
          })
        }
      } else {
        const latLng = new window.google.maps.LatLng(
          point.geometry?.coordinates[1],
          point.geometry?.coordinates[0],
        )
        const foundCluster = currentClusters.find(
          cluster => cluster.id === point.id,
        )
        if (!foundCluster) {
          const clusterEl = ClusterMarker({
            cluster: point,
            pointCount: point.properties.point_count,
          })
          const clusterString = ReactDOMServer.renderToString(clusterEl)
          let cluster = createHTMLMapMarker({
            id: point.id,
            latlng: latLng,
            html: clusterString,
            map: map,
          })
          cluster.addListener('click', () => {
            onClusterClick(point, supercluster, map)
          })
          newClusters.push({
            id: point.id,
            properties: point.properties,
            googleMarker: cluster,
          })
        }
      }
      return point
    })

    if (activeMarker) {
      // window.google.maps.event.addListenerOnce(map, 'idle', () => {
      //if map moved without redrawing activeMarker add manually
      const newMarker = document.querySelector(
        `#map-container #markerContainer-${activeMarker.idAsset}`,
      )
      if (newMarker) {
        newMarker.classList.add('activeMarker')
      }
      // })
    }

    useMapStore.setState({markers: newMarkers, clusters: newClusters})
    if (setActiveMarker && newActive) {
      setActiveMarker(newActive)
    }
  }
}

export const toggleFollowingClass = activeMarker => {
  const foundMarker = document.getElementById(
    `markerContainer-${activeMarker.idAsset}`,
  )
  if (foundMarker) {
    if (activeMarker.following) {
      foundMarker.classList.add('following')
    } else {
      const els = document.querySelectorAll('.following')
      for (const el of [...els]) {
        el.classList.remove('following')
      }
    }
  }
}

export const dedupeMapUpdates = pendingMapUpdates => {
  const dedupedMapUpdates = []
  pendingMapUpdates.forEach(thisUpdate => {
    const foundDeviceIndex = dedupedMapUpdates.findIndex(
      dd =>
        dd.idDevice === thisUpdate.idDevice &&
        thisUpdate.Last_Communication > dd.Last_Communication,
    )
    if (foundDeviceIndex > -1) {
      dedupedMapUpdates[foundDeviceIndex] = thisUpdate
    } else {
      dedupedMapUpdates.push(thisUpdate)
    }
  })
  return dedupedMapUpdates
}

export const updateMarker = pendingMapUpdates => {
  const markers = useMapStore.getState().markers
  const activeMarker = useMapStore.getState().activeMarker
  const setActiveMarker = useMapStore.getState().setActiveMarker
  pendingMapUpdates.forEach(device => {
    if (markers) {
      // const properties = foundPoint.properties
      const foundMarker = markers.find(m => m.idDevice === device.idDevice)

      if (foundMarker) {
        if (activeMarker?.idDevice === device.idDevice) {
          const newActiveMarker = {
            idAsset: foundMarker.idAsset,
            idDevice: foundMarker.idDevice,
            label: foundMarker.properties.Asset_Label,
            lat: device.Latitude,
            lng: device.Longitude,
            following: activeMarker.following,
            heading: foundMarker.heading,
          }
          setTimeout(() => {
            setActiveMarker(newActiveMarker)
          }, 500)
        }
        const latLng = new window.google.maps.LatLng(
          device.Latitude,
          device.Longitude,
        )
        const foundDiv = document.getElementById(`ida-${foundMarker.idAsset}`)
        // if (foundDiv) {
        //   foundDiv.classList.add('animate')
        // }
        if (foundDiv) {
          const markerGoImage =
            foundDiv.getElementsByClassName('marker-img-status')
          const markerStopImage =
            foundDiv.getElementsByClassName('marker-img-stop')
          if (markerGoImage?.length && markerStopImage?.length) {
            if (device.In_Motion) {
              markerGoImage[0].classList.remove('hidden')
              markerStopImage[0].classList.add('hidden')
              markerGoImage[0].style.transform = `rotate(${device.Last_Heading}deg)`
            } else {
              markerGoImage[0].classList.add('hidden')
              markerStopImage[0].classList.remove('hidden')
            }
          }
        }

        foundMarker.googleMarker.animateTo(latLng)
        // if (foundDiv) {
        //   setTimeout(() => {
        //     foundDiv.classList.remove('animate')
        //   }, 1200)
        // }

        //update marker in store
        const newMarkers = markers.map(marker => {
          if (marker.idDevice === device.idDevice) {
            const newMarker = {
              ...marker,
              geometry: {
                type: 'Point',
                coordinates: [device.Longitude, device.Latitude],
              },
            }
            return newMarker
          } else {
            return marker
          }
        })
        useMapStore.setState({markers: newMarkers})
      }
    }
  })
  return
}

export function createMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.DEFAULT,
      position: maps.ControlPosition.BOTTOM_RIGHT,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID,
      ],
    },

    tilt: 0,
    rotateControl: false,
    mapTypeControl: true,
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
    streetViewControl: true,
    streetViewControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },

    // fullscreenControl: true,
    fullscreenControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
  }
}
export function createSmallMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    mapTypeControlOptions: {
      mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE],
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.RIGHT_BOTTOM,
      // style: maps.MapTypeControlStyle.DROPDOWN_MENU,
    },
    tilt: 0,
    rotateControl: false,
    controlSize: 28,
    mapTypeControl: true,
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
      style: maps.ZoomControlStyle.SMALL,
    },
    streetViewControl: true,
    streetViewControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
    },

    // fullscreenControl: true,
    fullscreenControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
    },
  }
}
