import {select, scaleTime, axisBottom, selectAll} from 'd3'
import {addSeconds, format, parseISO} from 'date-fns'
import Alert from 'images/Alert.svg'
import {useHistoryTrailStore} from './hooks/useHistoryTrailStore'
import {useStore} from 'common/useStore'

export const drawTimeLine = ({data, svgRef, startDate, alerts}) => {
  const setActiveID = useHistoryTrailStore.getState().setActiveID
  const userConfig = useStore.getState().userConfig
  //clear svg
  selectAll('#timelinechart > *').remove()
  //clear tooltip
  selectAll('.timelineTooltip').remove()
  const LINE_COLOR = '#555'
  const BAR_MOVING_COLOR = 'var(--asc-lime)'
  const BAR_MOVING_COLOR_BORDER = 'var(--asc-darkolive)'
  const BAR_IDLING_COLOR = 'var(--asc-sonicsilver)'
  const BAR_IDLING_COLOR_BORDER = 'var(--asc-darkolive)'
  const BAR_OFF_COLOR = 'white'
  const BAR_OFF_COLOR_BORDER = 'var(--asc-darkolive)'
  const FONT_SIZE = '3em'
  const margin = {top: 10, right: 10, bottom: 10, left: 10},
    padding = {top: 50, right: 40, bottom: 30, left: 50},
    outerWidth = 1600,
    outerHeight = 200,
    innerWidth = outerWidth - margin.left - margin.right,
    innerHeight = outerHeight - margin.top - margin.bottom,
    width = innerWidth - padding.left - padding.right,
    height = innerHeight - padding.top - padding.bottom

  const endDate = addSeconds(startDate, 86399)

  const totalSeconds = 86400

  //outside container (with margins)
  const svg = select(svgRef.current).attr(
    'viewBox',
    `0 0 ${outerWidth} ${outerHeight}`,
  )

  //chart container (with padding)
  const g = svg
    .append('g')
    .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')

  var timeScale = scaleTime()
    .domain([startDate, endDate])
    .range([0, width])
    .nice()

  function make_x_axis() {
    return axisBottom(timeScale)
      .ticks()
      .tickFormat(d => {
        return format(d, userConfig.Time_Format === '24HR' ? 'HH' : 'haaa')
      })
  }

  // gridlines in x axis function
  function make_x_gridlines() {
    return axisBottom(timeScale).ticks(24)
  }
  /**
   * draw x axis
   */
  g.append('g')
    .style('font-size', FONT_SIZE)
    .attr('transform', `translate(0,${height})`)
    .call(make_x_axis())

  //line at top of chart
  svg
    .append('line')
    .style('stroke', LINE_COLOR)
    .style('stroke-width', 1)
    .attr('x1', 50)
    .attr('x2', outerWidth - 60)
    .attr('y1', 50)
    .attr('y2', 50)

  // Define the div for the tooltip - styles in App.css
  const tooltipDiv = select('body')
    .append('div')
    .attr('class', 'timelineTooltip')

  const mouseover = (e, d) => {
    if (d.idAlert_Event && d.Created_On) {
      tooltipDiv
        .style('opacity', 1)
        .html(`<div>Alert ${format(parseISO(d.Created_On), 'hh:mm a')}</div>`)
      select(this).style('stroke', 'black').style('opacity', 1)
    } else if (d?.status) {
      tooltipDiv
        .style('opacity', 1)
        .html(
          `<div>${d.status} ${format(d.startTime, 'hh:mm a')} - ${format(
            d.endTime,
            'h:mm a',
          )} </div>`,
        )
      select(this).style('stroke', 'black').style('opacity', 1)
    }
  }
  const onClick = (e, d) => {
    setActiveID(d.idRaw_Data)
  }
  const mousemove = e => {
    tooltipDiv
      .style('left', e.pageX - 100 + 'px')
      .style('top', e.pageY - 40 + 'px')
  }
  const mouseleave = () => {
    tooltipDiv
      .style('opacity', 0)
      .style('left', 10000 + 'px')
      .style('top', 10000 + 'px')
    select(this).style('stroke', 'none').style('opacity', 0.8)
  }

  // add the X gridlines here so they are on top
  g.append('g')
    .attr('class', 'grid')
    .style('fill', 'white')
    .attr('transform', 'translate(0,' + height + ')')
    .attr('color', LINE_COLOR)
    .call(make_x_gridlines().tickSize(-height).tickFormat(''))
  /**
   * main bars
   */
  g.selectAll('g.timeSliceBars')
    .data(data)
    .enter()
    .append('rect')
    .attr('class', 'timeSliceBars') //used in App.css
    .attr('x', d => {
      return timeScale(d.startTime)
    })
    .attr('height', 100)
    .attr('width', d => {
      return (d.seconds / totalSeconds) * innerWidth
    })
    .attr('stroke', d => {
      if (d.status === 'moving') return BAR_MOVING_COLOR_BORDER
      if (d.status === 'idling') return BAR_IDLING_COLOR_BORDER
      if (d.status === 'off') return BAR_OFF_COLOR_BORDER
    })
    .attr('stroke-width', 2)
    .attr('fill', d => {
      if (d.status === 'moving') return BAR_MOVING_COLOR
      if (d.status === 'idling') return BAR_IDLING_COLOR
      if (d.status === 'off') return BAR_OFF_COLOR
    })
    .on('mouseover', mouseover)
    .on('mousemove', mousemove)
    .on('mouseleave', mouseleave)
    .on('click', onClick)

  g.selectAll('g.alertPoints')
    .data(alerts)
    .enter()
    .append('svg:image')
    .attr('class', 'alertPoints') //used in App.css
    .attr('x', d => {
      return timeScale(new Date(d.Created_On)) - 25
    })
    .attr('y', -50)
    .attr('width', 50)
    .attr('height', 50)
    .attr('xlink:href', Alert)
    .on('mouseover', mouseover)
    .on('mousemove', mousemove)
    .on('mouseleave', mouseleave)
    .on('click', onClick)
}
