import * as React from 'react'
import styled from 'styled-components/macro'
import {Link} from 'react-router-dom'
import {openWindowTab} from 'common/helpers'
import {CaretUp, CaretDown} from 'phosphor-react'
import {Dropdown} from 'semantic-ui-react'

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  /* flex: 1; */
  /* align-items: center; */
  /* align-self: center; */
  a {
    color: black;
    font-weight: 500;
  }
  a:hover {
    color: black !important;
    text-decoration: none;
  }
`
const Container = styled.div`
  display: flex;
  /* position: relative; */
  a:hover {
    text-decoration: none;
  }
`
const StyledDropdown = styled(Dropdown)`
  &&& {
    .icon {
      display: none;
    }
    .menu {
      width: 142px;
      padding: 8px;
      background-color: white;
      border: 1px solid var(--asc-subduedmoss);
      .item {
        padding: 5px 10px !important;
        background: white;
        line-height: 22px;

        :hover {
          background: var(--secondary);
        }
      }
    }
    .text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }
`
const Button = styled.button`
  &&& {
    background-color: white;
    border-radius: ${({noOptions}: {noOptions?: boolean}) =>
      noOptions ? '5px' : '5px 0px 0px 5px'};
    border-width: 1px 1px 1px 1px;
    border-color: var(--asc-subduedmoss);
    color: var(--asc-subduedmoss);
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    height: 30px;
    margin: 0;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    :first-of-type {
      padding: 0 15px;
    }
    :hover {
      background-color: var(--primary);
      color: var(--asc-keylime);
    }
  }
`
const Toggle = styled(Button)`
  &&& {
    border-radius: 0px 5px 5px 0px;
    border-width: 1px 1px 1px 0px;
    padding: 0 10px !important;
    background-color: ${({open}: {open?: boolean}) =>
      open ? 'var(--primary)' : 'white'};
    color: ${({open}) =>
      open ? 'var(--asc-keylime)' : 'var(--asc-subduedmoss)'};
    svg {
      min-height: 14px;
      min-width: 14px;
    }
  }
`

export type CustomDropDownType = {
  text: string
  onClick: () => void
  disabled?: boolean
  testId: string
  newWindow?: boolean
}

type Props = {
  editPath?: string
  editText?: string
  dashboardPath?: string
  onDelete?: () => void
  customDeleteText?: string
  testIds?: {edit: string; delete: string}
  deleteDisabled?: boolean
  disabled?: boolean
  nonAdmin?: boolean
  path?: string
  editState?: {[key: string]: unknown}
  filters?: {
    filters?: {name?: string; value: unknown}[]
    globalFilter?: string
  } | null
  newWindow?: boolean
  editAction?: () => void
  className?: string
  serviceHistoryPath?: string
  customDropdownItems?: CustomDropDownType[]
}

const TableActionButtons = ({
  editPath = '',
  dashboardPath,
  onDelete,
  customDeleteText = '',
  testIds,
  deleteDisabled = false,
  disabled = false,
  nonAdmin,
  path = '',
  editState,
  filters,
  newWindow = false,
  editText,
  editAction,
  className = '',
  customDropdownItems = [],
}: Props) => {
  const [open, setOpen] = React.useState(false)

  const noOptions = !dashboardPath && !onDelete && !customDropdownItems?.length

  return (
    <Controls className={className}>
      {!noOptions ? (
        <StyledDropdown
          direction="left"
          open={open}
          onClose={() => setOpen(false)}
          disabled={disabled}
          trigger={
            editAction ? (
              <Container>
                <Button onClick={() => editAction()} data-cy="main-button">
                  {editText ? editText : nonAdmin ? 'View' : 'Edit'}
                </Button>
                <Toggle onClick={() => setOpen(!open)} data-cy="toggle">
                  {open ? (
                    <CaretUp weight="fill" />
                  ) : (
                    <CaretDown weight="fill" />
                  )}
                </Toggle>
              </Container>
            ) : (
              <Container>
                <Link
                  data-cy={testIds?.edit ? testIds.edit : 'edit-item'}
                  to={nonAdmin ? path || '' : editPath}
                  state={
                    nonAdmin
                      ? {prevPath: location.pathname}
                      : {
                          prevPath: location.pathname,
                          filters,
                          ...editState,
                        }
                  }
                >
                  <Button data-cy="main-button">
                    {editText ? editText : nonAdmin ? 'View' : 'Edit'}
                  </Button>
                </Link>
                <Toggle onClick={() => setOpen(!open)} data-cy="toggle">
                  {open ? (
                    <CaretUp weight="fill" />
                  ) : (
                    <CaretDown weight="fill" />
                  )}
                </Toggle>
              </Container>
            )
          }
        >
          <Dropdown.Menu data-cy="dropdown-menu">
            {customDropdownItems.map((item, index) => (
              <Dropdown.Item
                key={index}
                text={item.text}
                onClick={item.onClick}
                disabled={item.disabled || false}
                data-cy={item.testId}
                target={item.newWindow ? '_blank' : ''}
              />
            ))}

            {dashboardPath && (
              <Dropdown.Item
                text="Asset Dashboard"
                onClick={() => openWindowTab('asset_dashboard', dashboardPath)}
              />
            )}
            {onDelete && (
              <Dropdown.Item
                text={customDeleteText ? customDeleteText : 'Delete'}
                onClick={onDelete}
                disabled={deleteDisabled}
                data-cy={testIds?.delete ? testIds.delete : 'delete-item'}
              />
            )}
          </Dropdown.Menu>
        </StyledDropdown>
      ) : (
        <>
          {editAction ? (
            <Button
              noOptions={true}
              onClick={() => editAction()}
              data-cy="main-button"
            >
              {editText ? editText : nonAdmin ? 'View' : 'Edit'}
            </Button>
          ) : (
            <Link
              to={nonAdmin ? path : editPath}
              state={
                nonAdmin
                  ? {prevPath: location.pathname}
                  : {
                      prevPath: location.pathname,
                      filters,
                      ...editState,
                    }
              }
              target={newWindow ? '_blank' : '_self'}
            >
              <Button noOptions={true} data-cy="main-button">
                {editText ? editText : nonAdmin ? 'View' : 'Edit'}
              </Button>
            </Link>
          )}
        </>
      )}
    </Controls>
  )
}

export default TableActionButtons
