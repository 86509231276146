import * as React from 'react'
import styled from 'styled-components/macro'
import {setHours, setMinutes, format, getHours, setSeconds} from 'date-fns'
import usePrevious from 'common/usePrevious'
import {CaretDown, CaretUp} from 'phosphor-react'

import useComponentVisible from 'common/useComponentVisible'
import {useStore} from 'common/useStore'

const TimePicker = styled.div`
  display: inline-block;
  position: relative;
  box-sizing: border-box;
`

const ToggleButton = styled.a`
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  margin: 0;
  color: var(--asc-moss);
`

const StyledInput = styled.input`
  width: 100%;
  position: relative;
  display: inline-block;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 12px;
  line-height: 1.5;
  color: ${({disabled}) =>
    disabled ? 'var(--asc-sonicsilver)' : 'var(--primary)'};
  font-weight: 700;
  background-color: ${({disabled}) =>
    disabled ? 'var(--asc-coolgray)' : '#fff'};
  background-image: none;
  padding: 0px;
  border: none !important;
  border-color: #ffffff;
  border-radius: 4px;
  :focus {
    outline: none;
    border: none;
  }
  [disabled] {
    color: #ccc;
    background: #f7f7f7;
    cursor: not-allowed;
  }
`

const Panel = styled.div`
  position: absolute;
  top: ${({dialogLocation}) => (dialogLocation === 'top' ? '-150px' : '100%')};
  left: 0;
  background-color: #fff;
  display: flex;
  border: 1px solid var(--asc-graphite);
  border-radius: 4px 4px 0px 0px;
  z-index: 99;
`

const PanelSelect = styled.div`
  float: left;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 47px;
  max-height: 144px;
  overflow-y: auto;
  position: relative;
  :first-child {
    border-left: 0;
    margin-left: 0;
  }
  :last-child {
    border-right: 0;
  }
  ul {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  min-width: ${p =>
    p.panelOpen ? '141px' : p.showSeconds ? '115px' : '100px'};
`
const Item = styled.li`
  background: ${({selected}) => (selected ? 'var(--asc-moss)' : '#ffffff')};
  color: ${({selected}) => (!selected ? 'var(--asc-drakolive)' : '#ffffff')};
  font-weight: ${({selected}) => (selected ? '700' : '500')};
  list-style: none;
  margin: 0;
  padding: 0 0 0 12px;
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  user-select: none;
  :hover {
    background: ${({selected}) =>
      selected ? 'var(--asc-moss)' : 'var(--asc-keylime)'};
  }
`

const Timepicker = ({
  startTime,
  setTime,
  showSeconds,
  dialogLocation = 'bottom',
  disabled = false,
}) => {
  const {Time_Format} = useStore(state => state.userConfig)
  // const [panelOpen, setPanelOpen] = React.useState(false)
  const hours = Array.from(Array(12).keys())
  const minutes = Array.from(Array(60).keys())
  const seconds = Array.from(Array(60).keys())
  const [thisTime, setThisTime] = React.useState(startTime)
  const {ref, isComponentVisible, setIsComponentVisible} =
    useComponentVisible(false)

  const previous = usePrevious({
    isComponentVisible,
  })

  const hourClicked = e => {
    const copyOfThisTime = new Date(thisTime.getTime())
    const currentHours = getHours(copyOfThisTime)
    const hoursToSet =
      currentHours > 11
        ? Number(e.target.dataset.hour) + 12
        : e.target.dataset.hour
    const newTime = setHours(copyOfThisTime, hoursToSet)
    setThisTime(newTime)
    setTime(newTime)
  }

  const minuteClicked = e => {
    const copyOfThisTime = new Date(thisTime.getTime())
    const newTime = setMinutes(copyOfThisTime, e.target.dataset.minute)
    setThisTime(newTime)
    setTime(newTime)
  }

  const secondsClicked = e => {
    const copyOfThisTime = new Date(thisTime.getTime())
    const newTime = setSeconds(copyOfThisTime, e.target.dataset.second)
    setThisTime(newTime)
    setTime(newTime)
  }

  React.useEffect(() => {
    if (
      !isComponentVisible &&
      isComponentVisible !== previous?.isComponentVisible
    ) {
      setTime(thisTime)
      setThisTime(thisTime)
    }
  }, [isComponentVisible, previous?.isComponentVisible, setTime, thisTime])

  const amPmClicked = e => {
    const copyOfThisTime = new Date(thisTime.getTime())
    const currentHours = getHours(copyOfThisTime)
    if (e.target.dataset.choice === 'am' && currentHours > 11) {
      const newTime = setHours(copyOfThisTime, currentHours - 12)
      setThisTime(newTime)
      setTime(newTime)
    } else if (e.target.dataset.choice === 'pm' && currentHours < 12) {
      const newTime = setHours(copyOfThisTime, currentHours + 12)
      setThisTime(newTime)
      setTime(newTime)
    }
  }
  const selectedHour = () => {
    if (Time_Format === '12HR') {
      const parseHour = parseInt(format(new Date(thisTime), 'h'))
      if (parseHour === 12) return 0
      else return parseHour
    } else {
      return parseInt(format(new Date(thisTime), 'H'))
    }
  }

  const selectedMinute = parseInt(format(new Date(thisTime), 'm'))
  const selectedSecond = parseInt(format(new Date(thisTime), 's'))
  const selectedAMPM = format(new Date(thisTime), 'a')

  return (
    <Container
      panelOpen={isComponentVisible}
      ref={ref}
      showSeconds={showSeconds}
      className="timepicker"
    >
      <TimePicker>
        <StyledInput
          type="text"
          value={format(startTime, showSeconds ? 'hh:mm:ss a' : 'hh:mm a')}
          readOnly
          onFocus={() => !disabled && setIsComponentVisible(true)}
          disabled={disabled}
          data-cy="timeInput"
        />
        {/* <span className="rc-time-picker-icon"></span> */}
        <ToggleButton
          data-cy="closeTimePicker"
          title="clear"
          onClick={() => setIsComponentVisible(!isComponentVisible)}
        >
          {isComponentVisible ? (
            <CaretUp weight="fill" />
          ) : (
            <CaretDown weight="fill" />
          )}
        </ToggleButton>
      </TimePicker>
      {isComponentVisible && (
        <Panel dialogLocation={dialogLocation}>
          <PanelSelect>
            <ul>
              {hours.map(hour => {
                return (
                  <Item
                    key={hour}
                    role="button"
                    data-hour={hour}
                    onClick={hourClicked}
                    selected={selectedHour() === hour}
                  >
                    {hour
                      ? hour.toLocaleString('en-US', {minimumIntegerDigits: 2})
                      : '12'}
                  </Item>
                )
              })}
            </ul>
          </PanelSelect>
          <PanelSelect>
            <ul data-cy="minutes">
              {minutes.map(minute => {
                return (
                  <Item
                    key={minute}
                    role="button"
                    data-minute={minute}
                    onClick={minuteClicked}
                    selected={selectedMinute === minute}
                  >
                    {minute.toLocaleString('en-US', {minimumIntegerDigits: 2})}
                  </Item>
                )
              })}
            </ul>
          </PanelSelect>
          {showSeconds && (
            <PanelSelect>
              <ul>
                {seconds.map(second => {
                  return (
                    <Item
                      key={second}
                      role="button"
                      data-second={second}
                      onClick={secondsClicked}
                      selected={selectedSecond === second}
                    >
                      {second.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                      })}
                    </Item>
                  )
                })}
              </ul>
            </PanelSelect>
          )}

          <PanelSelect>
            <ul>
              <Item
                role="button"
                data-choice="am"
                onClick={amPmClicked}
                selected={selectedAMPM === 'AM'}
              >
                am
              </Item>
              <Item
                role="button"
                data-choice="pm"
                onClick={amPmClicked}
                selected={selectedAMPM === 'PM'}
              >
                pm
              </Item>
            </ul>
          </PanelSelect>
        </Panel>
      )}
    </Container>
  )
}
export default Timepicker

// .rc-time-picker-panel-select-active {
//   overflow-y: auto;
// }

//need to have variables for these
// li.rc-time-picker-panel-select-option-selected {
//   background: #f7f7f7;
//   font-weight: bold;
// }
// li.rc-time-picker-panel-select-option-disabled {
//   color: #ccc;
// }
// li.rc-time-picker-panel-select-option-disabled:hover {
//   background: transparent;
//   cursor: not-allowed;
// }
// </style>
