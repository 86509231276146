import React from 'react'
import styled from 'styled-components/macro'
import {useLocation} from 'react-router-dom'
import {QueryClient, useQueryClient} from '@tanstack/react-query'
import {CellContext, ColumnDef} from '@tanstack/react-table'
import ResizableTable from 'common/tables/ResizableTable/ResizableTable'
import {format} from 'date-fns'
import {deleteDevice} from 'management/components/tableHelpers'
import {useStore} from 'common/useStore'
import useDevices from '../../../hooks/useDevices'
import {CustomerType} from 'management/hooks/useCustomers'
import {ArrowsLeftRight, ListPlus, PencilSimple, X} from 'phosphor-react'
import DeviceForm from './DeviceForm'
import {StyledButton} from 'common/tables/TableButtons'
import DeviceTransferForm from './DeviceTransferForm'
import MultipleDeviceUpload from './MultipleDeviceUpload'
import {EditDeviceType} from './hooks/usePutDevice'

type Props = {
  timezoneName: string
  selected: CustomerType | null
  height?: string
}

const Container = styled.div`
  /* border-top: 1px solid #dedede; */
`

const NoCustomerSelectedMessage = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 1;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin: 0;
  height: 300px;
  justify-content: center;
  align-items: center;
  font-size: calc(17 / 14 * 1rem);
  padding: 10px;
`
const ActionCellContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
`

const ActionButton = styled.button`
  display: flex;
  padding: 0 5px;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--asc-coolgray);
  border-radius: 5px;
  width: 30px;
  height: 30px;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
`

const ActionCell = ({
  instance,
  cache,
  devices,
  editDevice,
}: {
  instance: CellContext<EditDeviceType, unknown>
  cache: QueryClient
  devices: EditDeviceType[]
  editDevice: (idDevice: number) => void
}) => {
  const {idDevice} = instance.row.original
  return (
    <ActionCellContainer>
      <ActionButton
        data-cy="edit-device-button"
        onClick={() => {
          editDevice(idDevice)
        }}
      >
        <PencilSimple size={20} />
      </ActionButton>
      <ActionButton
        data-cy="delete-device"
        onClick={() => deleteDevice(idDevice, devices, cache)}
      >
        <X weight="fill" size={20} />
      </ActionButton>
    </ActionCellContainer>
  )
}

const DevicesTable = (props: Props) => {
  const {selected} = props
  const location = useLocation()
  const {pathname} = location
  const dateFormat = useStore(state => state.userConfig)?.Date_Format
  const timeFormat = useStore(state => state.userConfig)?.Time_Format
  const cache = useQueryClient()
  const {data: devices, status, isFetching} = useDevices(selected?.idcustomer)
  const [showDeviceModal, setShowDeviceModal] = React.useState(false)
  const [showDeviceTransferModal, setShowDeviceTransferModal] =
    React.useState(false)
  const [showBulkAddDeviceModal, setShowBulkAddDeviceModal] =
    React.useState(false)
  const [idDeviceEdit, setIdDeviceEdit] = React.useState<number | null>(null)
  const DateCell = (instance: CellContext<EditDeviceType, unknown>) => {
    const value = String(instance.getValue())
    if (!value) return ''
    const localDate = format(
      new Date(value),
      `${dateFormat} ${timeFormat === '12HR' ? 'h:mm a' : 'H:mm'}`,
    )
    if (localDate) {
      return localDate
    } else {
      return ''
    }
  }

  const DeviceTypeCell = (instance: CellContext<EditDeviceType, unknown>) => {
    const idDeviceType = instance.row.original.idDevice_Type
    const value = String(instance.getValue())
    return `${value} (${idDeviceType})`
  }

  const editDevice = (idDevice: number) => {
    setIdDeviceEdit(idDevice)
    setShowDeviceModal(true)
  }

  const DeviceSwapTypes: {[key: number]: string} = {
    1: 'RMA',
    2: 'Tech Upgrade',
    3: 'Dealer Discretion',
  }

  const columns: ColumnDef<EditDeviceType>[] = [
    {
      header: 'Actions',
      cell: (instance: CellContext<EditDeviceType, unknown>) =>
        ActionCell({instance, cache, devices: devices || [], editDevice}),
      size: 80,
      enableResizing: false,
      enableSorting: false,
      meta: {
        disableExport: true,
      },
      id: 'idCustomer',
      accessorKey: 'idCustomer',
    },
    {
      header: 'Serial #',
      accessorKey: 'Serial_Number_Displayed',
      size: 120,
    },

    {
      header: 'Created On',
      accessorKey: 'Created_On',
      cell: DateCell,
    },
    {
      header: 'idDevice',
      accessorKey: 'idDevice',
      size: 80,
    },
    {
      header: 'Carrier',
      accessorKey: 'Carrier1',
    },
    {
      header: 'Device Type',
      accessorKey: 'Device_Type',
      cell: DeviceTypeCell,
    },
    {
      header: 'Device Status',
      accessorKey: 'Device_Status_Description',
    },
    {
      header: 'Device Notes',
      accessorKey: 'Device_Notes',
      size: 140,
    },
    {
      header: 'IMEI',
      accessorKey: 'IMEI',
    },
    {
      header: 'SIMNBR',
      accessorKey: 'SIMNBR',
    },
    {
      header: 'Device Model',
      accessorKey: 'DeviceModel',
    },
    {
      header: 'Order #',
      accessorKey: 'Order_Nbr',
    },
    {
      header: 'Item Number',
      accessorKey: 'Item_Nbr',
    },
    {
      header: 'Saleforce Customer ID',
      accessorKey: 'Salesforce_Customer_ID',
      size: 100,
    },
    {
      header: 'idCustomer_Final',
      accessorKey: 'idCustomer_Final',
      size: 120,
    },
    {
      header: 'idDevice_Final',
      accessorKey: 'idDevice_Final',
    },
    {
      header: 'Device Swap Type',
      accessorFn: row =>
        row.DeviceSwapType ? DeviceSwapTypes[row.DeviceSwapType] : '',
      size: 130,
    },
    {
      header: 'Dealer ID (Hot Swap)',
      accessorKey: 'idDealer_Swappable',
    },
  ]

  if (pathname.startsWith('/management/support/devices') && !selected)
    return (
      <NoCustomerSelectedMessage>
        Select a customer above to see devices
      </NoCustomerSelectedMessage>
    )

  return (
    <Container>
      <ResizableTable<EditDeviceType, number>
        data={devices || []}
        columns={columns}
        title={selected ? `${selected?.customer_name} Devices` : 'All Devices'}
        status={status}
        height={'calc(50vh - 115px)'}
        isUpdating={isFetching}
        pinnedColumns={['idCustomer']}
        addOptions={{
          addAction: () => {
            setIdDeviceEdit(null)
            setShowDeviceModal(true)
          },
          addButtonText: 'ADD DEVICE',
        }}
        rightHeaderContent={
          selected ? (
            <>
              <StyledButton onClick={() => setShowDeviceTransferModal(true)}>
                <ArrowsLeftRight weight="fill" size={20} /> TRANSFER DEVICES
              </StyledButton>
              <StyledButton onClick={() => setShowBulkAddDeviceModal(true)}>
                <ListPlus weight="fill" size={20} /> Bulk ADD Devices
              </StyledButton>
            </>
          ) : undefined
        }
        oneRowHeader={true}
      />
      {showDeviceModal && selected && (
        <DeviceForm
          selected={selected}
          dismissModal={() => setShowDeviceModal(false)}
          idDeviceEdit={idDeviceEdit}
        />
      )}
      {showDeviceTransferModal && selected && (
        <DeviceTransferForm
          selected={selected}
          dismissModal={() => setShowDeviceTransferModal(false)}
        />
      )}
      {showBulkAddDeviceModal && selected && (
        <MultipleDeviceUpload
          selected={selected}
          dismissModal={() => setShowBulkAddDeviceModal(false)}
        ></MultipleDeviceUpload>
      )}
    </Container>
  )
}

export default DevicesTable
