import Swal from 'sweetalert2'
import {format, parse} from 'date-fns'
import {
  convertFormDataToLogiFormat,
  convertTimeSubmitFormat,
  parseReportURL,
} from 'reports/reportHelpers'
import {isEmpty} from 'lodash'
import {isEmail} from 'common/helpers'
import {ordinalNumber} from 'common/helpersNumber'
//Gather form data for schedules
export const getScheduleFormData = ({state, user, uoms, userConfig}) => {
  //Create an object to pass to the form submit since some of the names are different in Logi

  //list of assets or groups that will be sent to logi to use in either report
  const logiFormData = convertFormDataToLogiFormat({
    state,
    user,
    uoms,
    userConfig,
  })
  const scheduleSubmitObject = getScheduleSubmitObject(userConfig, state)
  var reportUrl = Object.keys(logiFormData)
    .filter(
      key =>
        logiFormData[key] !== null &&
        logiFormData[key] !== undefined &&
        logiFormData[key] !== '' &&
        logiFormData[key] !== 0,
    )
    .map(key => {
      var value = String(logiFormData[key])
      value = encodeURIComponent(value)
      if (value === 'true') {
        value = 1
      }
      return key + '=' + value
    })
    .join('&')

  scheduleSubmitObject.Report_URL = reportUrl
  return scheduleSubmitObject
}
export const getScheduleSubmitObject = (userConfig, scheduleData) => {
  const startDate = scheduleData.startDate
    ? parse(
        scheduleData.startDate,
        userConfig?.Date_Format || 'MM/dd/yyyy',
        new Date(),
      )
    : new Date()
  var schedule = {
    idUser: userConfig.idUser,
    Email_List: scheduleData.emailList,
    Title: scheduleData.title,
    Report_Format: getReportFormat(scheduleData),
    Report_Frequency: getFrequency(scheduleData),
    Enabled: 'true',
    Report_Occurrence: getOccurrence(scheduleData),
    Send_Time: convertTimeSubmitFormat(scheduleData.sendTime),
    NextRunTime:
      format(startDate, 'yyyy-MM-dd') +
      'T' +
      convertTimeSubmitFormat(scheduleData.sendTime),
    // username: userConfig.Email_Address,
    DailyDateRangeOption: convertDailyDateRangeOptFormat(
      scheduleData.dailyDateRangeOption,
    ),
  }
  if (scheduleData.idReport_Schedules) {
    schedule.idReport_Schedules = scheduleData.idReport_Schedules
  }
  if (scheduleData.endDateType === 'Date') {
    const endDate = parse(
      scheduleData.endDate,
      userConfig.Date_Format,
      new Date(),
    )
    schedule.End_Date = format(endDate, 'yyyy-MM-dd')
    schedule.endoption = `Date:${schedule.End_Date}`
  }
  if (scheduleData.endDateType === 'Count') {
    schedule.Reports_Remaining = Number(scheduleData.endDateCount)
    schedule.endoption = `Count:${scheduleData.endDateCount}`
  }
  return schedule
}
export const convertDailyDateRangeOptFormat = dailyDateRangeOpt => {
  switch (dailyDateRangeOpt) {
    case 'PreviousDay':
      return 0
    case 'Previous24':
      return 1
    case 'CurrentDay':
      return 2
    default:
      return 0
  }
}

export const getOccurrence = scheduleData => {
  const {
    reportFrequency,
    occurrence,
    weeklyOption,
    monthlyOption,
    monthlyDate,
    monthlyWeeknum,
    monthlyWeekday,
  } = scheduleData

  var newOccurrence = ''
  if (reportFrequency === 'Once') return 'Once'
  if (reportFrequency === 'Daily') {
    newOccurrence = occurrence
  } else if (reportFrequency === 'Weekly') {
    newOccurrence = weeklyOption
      ?.filter(item => item.active)
      .map(item => item.value)
      .join(', ') //get only active day
  } else if (reportFrequency === 'Monthly') {
    if (monthlyOption === 'Date') {
      newOccurrence = monthlyOption + ':' + monthlyDate
    } else {
      newOccurrence =
        monthlyOption + ':' + monthlyWeeknum + ':' + monthlyWeekday
    }
  }
  return newOccurrence
}
export const getOccurrenceOptions = (scheduleData, editForm) => {
  const {Report_Frequency, Report_Occurrence} = scheduleData
  const selectOptions = {}
  const weeklyOptions = [
    {label: 'Sun', value: 'Sun', active: false},
    {label: 'Mon', value: 'Mon', active: true},
    {label: 'Tue', value: 'Tue', active: false},
    {label: 'Wed', value: 'Wed', active: false},
    {label: 'Thu', value: 'Thu', active: false},
    {label: 'Fri', value: 'Fri', active: false},
    {label: 'Sat', value: 'Sat', active: false},
  ]
  const reportFrequency = getFrequencyFromEdit(Report_Frequency)
  if (reportFrequency === 'Daily') {
    selectOptions.occurrence = selectOptions
  } else if (reportFrequency === 'Weekly') {
    const weeklyOccurance = Report_Occurrence.split(', ')
    weeklyOptions.map(week => {
      if (weeklyOccurance.indexOf(week.value) !== -1) {
        week.active = true
      } else {
        week.active = false
      }
      return week
    })
    editForm.weeklyOption = weeklyOptions
  } else if (reportFrequency === 'Monthly') {
    const splitMonthlyOccurance = Report_Occurrence.split(':')
    if (splitMonthlyOccurance[0] === 'Date') {
      editForm.monthlyOption = splitMonthlyOccurance[0]
      editForm.monthlyDate = Number(splitMonthlyOccurance[1])
    } else {
      editForm.monthlyOption = splitMonthlyOccurance[0]
      editForm.monthlyWeeknum = splitMonthlyOccurance[1]
      editForm.monthlyWeekday = splitMonthlyOccurance[2]
    }
  }
}
export const getReportFormat = scheduleData => {
  let format = null
  switch (scheduleData.reportFormat) {
    case 'Excel': {
      format = 1
      break
    }
    case 'CSV': {
      format = 2
      break
    }
    case 'PDF': {
      format = 3
      break
    }
    default: {
      format = 1
      break
    }
  }
  return format
}
export const getReportFormatEdit = reportFormat => {
  let format = null
  switch (reportFormat) {
    case 1: {
      format = 'Excel'
      break
    }
    case 2: {
      format = 'CSV'
      break
    }
    case 3: {
      format = 'PDF'
      break
    }
    default: {
      format = 'Excel'
      break
    }
  }
  return format
}
export const getFrequency = scheduleData => {
  let frequency = null
  switch (scheduleData.reportFrequency) {
    case 'Daily': {
      frequency = 1
      break
    }
    case 'Weekly': {
      frequency = 2
      break
    }
    case 'Monthly': {
      frequency = 3
      break
    }
    default: {
      frequency = 1
      break
    }
  }
  return frequency
}
export const getFrequencyFromEdit = reportFrequency => {
  let frequency = null
  switch (reportFrequency) {
    case 1: {
      frequency = 'Daily'
      break
    }
    case 2: {
      frequency = 'Weekly'
      break
    }
    case 3: {
      frequency = 'Monthly'
      break
    }
    default: {
      frequency = 'Daily'
      break
    }
  }
  return frequency
}
export const getDateRangeOptionFromEdit = dateRangeOption => {
  switch (dateRangeOption) {
    case 0:
      return 'PreviousDay'
    case 1:
      return 'Previous24'
    case 2:
      return 'CurrentDay'
    default:
      return 'PreviousDay'
  }
}
export const checkEmails = Email_List => {
  let emailsValid = true
  let ordinalNumberEmail = 0
  if (Email_List && Email_List !== '') {
    //split on /n and comma
    let emailArray = Email_List.split(/[\n,]/g)
    if (emailArray && emailArray.length > 0) {
      emailArray.forEach((email, index) => {
        if (emailsValid && !isEmail(email.trim())) {
          emailsValid = false
          ordinalNumberEmail = ordinalNumber(index)
        }
      })
    }
  }
  return {isValid: emailsValid, ordinalNumberEmail}
}
export const convertStandardTime = time => {
  if (!time)
    return {
      hour: 0,
      minute: 0,
      ampm: 'AM',
    }
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ]

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1) // Remove full string match value
    time[4] = +time[0] < 12 ? 'AM' : 'PM' // Set AM/PM
    time[0] = +time[0] % 12 || 12 // Adjust hours
  }
  time[3] = ':'
  return {
    hour: time[0],
    minute: Number(time[2]),
    ampm: time[4],
  } // return adjusted time or original string
}
export const submitSchedule = async ({
  state,
  userConfig,
  user,
  uoms,
  putSchedule,
  postSchedule,
  dispatch,
}) => {
  if (!isEmpty(state.errors)) {
    Swal.fire(
      'Missing Information',
      'Please fill out all required fields. Check the Scheduled Report Preview window for errors',
      'error',
    )
    dispatch({type: 'setState', data: {submitting: false}})
  } else {
    const scheduleFormData = getScheduleFormData({
      state,
      userConfig,
      user,
      uoms,
    })
    //if edit mode
    if (scheduleFormData.idReport_Schedules) {
      await putSchedule(scheduleFormData)
    } else {
      await postSchedule(scheduleFormData)
    }

    window.location.href = '/reports/saved'
  }
}

const decodeHTML = html => {
  var txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

export const getEditState = (user, userConfig, assets, data, locations) => {
  const scheduleEdit = parseReportURL({}, data.Report_URL)
  const editState = {
    reportFormat: getReportFormatEdit(data.Report_Format),
    reportFrequency: getFrequencyFromEdit(data.Report_Frequency),
    occurrence: data.Report_Occurrence,
    title: data.Title,
    idReport_Schedules: data.idReport_Schedules,
    emailList: data.Email_List,
    Enabled: true,
    idCustomer: data.idCustomer,
    idUser: user.idUser,
    rdReport: scheduleEdit.rdReport,
    assetSelectionType: scheduleEdit.irbAssetSelOptions,
    endDate: scheduleEdit.inpEndDate
      ? format(
          new Date(scheduleEdit.inpEndDate.split('+')[0]),
          userConfig.Date_Format,
        )
      : null,
    startDate: format(new Date(data.NextRunTime), userConfig.Date_Format),
    shiftReportingEnabled: Boolean(scheduleEdit.inpShiftEnabled),
    proximityDistance: Number(scheduleEdit.itDistance),
    dailyDateRangeOption: getDateRangeOptionFromEdit(data.DailyDateRangeOption),
  }
  if (scheduleEdit.inpShiftEnabled) {
    editState.shiftStartTime = convertStandardTime(scheduleEdit.inpShiftStart)
    editState.shiftEndTime = convertStandardTime(scheduleEdit.inpShiftEnd)
  }
  getOccurrenceOptions(data, editState)
  editState.sendTime = convertStandardTime(data.Send_Time)
  if (scheduleEdit.irbAssetSelOptions) {
    let ids = scheduleEdit.ids.split(',').map(item => Number(item))
    if (scheduleEdit.irbAssetSelOptions === 'Asset') {
      ids = ids.filter(id => assets.some(asset => asset.idAsset === id))
      const selectedAssets = assets.filter(asset => ids.includes(asset.idAsset))
      editState.labels = selectedAssets
        .map(asset => asset.Asset_Label)
        .join(',')
    } else {
      editState.labels = decodeHTML(scheduleEdit.names)
    }
    editState[
      scheduleEdit.irbAssetSelOptions !== 'Hierarchy'
        ? `selected${scheduleEdit.irbAssetSelOptions}s`
        : 'selectedHierarchy'
    ] = ids
  }
  if (scheduleEdit.location) {
    const locationIds = scheduleEdit.location
      .split(',')
      .map(item => Number(item))
      .filter(id => locations?.some(location => location.idLandmark === id))
    const filteredLocations = locations?.filter(location =>
      locationIds.includes(location.idLandmark),
    )

    editState.selectedLocations = locationIds
    editState.locationNames = filteredLocations
      ?.map(location => location.Landmark_Name)
      .join(',')
  }
  if (scheduleEdit.islReport2Run === 'Proximity') {
    editState.proximityDistance = Number(scheduleEdit.itDistance)
    editState.proximityAddress = scheduleEdit.itAddress
    editState.proximityType = scheduleEdit.irbPlace
  }
  //convert speed strings to numbers
  if (scheduleEdit.speed) {
    var schedSpeed = Number(scheduleEdit.speed)
    if (typeof schedSpeed === 'number') {
      editState.speed = schedSpeed
    }
  }

  if (scheduleEdit.speeding) {
    var schedSpeeding = Number(scheduleEdit.speeding)
    if (typeof schedSpeeding === 'number') {
      editState.speeding = schedSpeeding
    }
  }

  if (scheduleEdit.itFuelRate) {
    editState.fuelRate = Number(scheduleEdit.itFuelRate)
  }

  if (scheduleEdit.durationHoursThreshold) {
    var durationHoursThreshold = Number(scheduleEdit.durationHoursThreshold)
    if (typeof durationHoursThreshold === 'number') {
      editState.durationHoursThreshold = durationHoursThreshold
    }
  }

  if (scheduleEdit.islReport2Run === 'AlertDetail') {
    if (scheduleEdit['alerts']) {
      if (
        scheduleEdit['alertSelectionType'] &&
        scheduleEdit.alertSelectionType === 'One'
      ) {
        //Find the alert based on the index
        editState.selectedAlertEdit = Number(scheduleEdit.alerts)
        editState.alertSelectionType = 'One'
        editState.selectedAlertType = null //reset it to all
      } else {
        //Find the alert type based on the index
        editState.alertSelectionType = 'Type'
        if (scheduleEdit.alerts) {
          editState.selectedAlertID = Number(scheduleEdit.alerts)
        } else {
          //all alerts is null
          editState.selectedAlertType = null
        }
        editState.selectedAlert = null //reset it to the first one
      }
    } else {
      editState.alertSelectionType = 'Type'
      editState.selectedAlert = null
    }
  }
  return editState
}
