import * as React from 'react'
import styled from 'styled-components/macro'
import {X} from 'phosphor-react'
import {toggleBoxSearch} from './boxSearchHelper'

const BoxSearchDialogContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 15px;
  position: absolute;
  width: 450px;
  height: 42px;
  color: white;
  top: 10px;
  right: 400px;
  background: rgba(61, 73, 31, 0.85);
  border: 1px solid #ffffff;
  border-radius: 5px;
  font-size: 14px;
`
const RightBorder = styled.div`
  border-right: 1px solid #d4d4d4;
  height: 25px;
  align-self: center;
  display: flex;
`
const CancelContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`
const BoxSearchDialog = ({
  map,
  assetsRef,
  setAssetFilter,
  clearAssetFilter,
}) => {
  return (
    <BoxSearchDialogContainer>
      <span>Draw a rectangle on the map to search an area.</span>
      <RightBorder />
      <CancelContainer
        onClick={() =>
          toggleBoxSearch({
            map,
            assetsRef,
            setAssetFilter,
            clearAssetFilter,
          })
        }
      >
        <X weight="fill" size={15} />
        <span>Cancel</span>
      </CancelContainer>
    </BoxSearchDialogContainer>
  )
}

export default BoxSearchDialog
