import React, {PropsWithChildren} from 'react'
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  SortingState,
  Row,
  RowData,
  ColumnDef,
} from '@tanstack/react-table'
// import {useVirtual, VirtualItem} from 'react-virtual'
import {useVirtualizer} from '@tanstack/react-virtual'
import {
  Body,
  SubHeader,
  StyledTableRow,
  ContainerCell,
  Cell,
  Styles,
  RightButtons,
  ScrollableTable,
  CenteredColumn,
  getCommonPinningStyles,
} from './resizableTableStyles'
import {TableHeader, calcColWidth, fuzzyFilter} from './resizableTableHelpers'
import ColumnFilter from './ColumnFilter'
import {Spinner, DateRangePicker} from 'common/components'
import GlobalFilter from '../GlobalFilter'
import TableExportButton from '../TableExportButton'
import {saveAsExcel} from '../excelExport'
import {saveAsCSV} from '../csvExport'
import {saveAsPDF} from '../pdfExport'
import SelectAllButton from './SelectAllButton'
import ResizableTableHeader from './ResizableTableHeader'
import {TableProps} from './ResizableTableTypes'
import {measureFn} from '../tableHelpers'

type State = {
  allRows: unknown[]
  globalFilter: string
  divWidth: number | null
}

type Actions = {type: 'setState'; data: Partial<State>}

const reducer = (state: State, action: Actions) => {
  switch (action.type) {
    case 'setState':
      return {...state, ...action.data}
    default:
      return state
  }
}

/**
 * For TS warnings:
 * You can add the data type and the type of the IDs (number | string) like this:
 * <ResizableTable<OrderType, string> {props}    />
 */

const ResizableTable = <TDataType, TSelectionIdType>({
  title = '',
  data,
  columns,
  status,
  dateRange,
  height,
  selectionActionButtons,
  children,
  initialFilters,
  updateToMatchGlobalFilter,
  rowClick,
  showHeader = true,
  showSubHeader = true,
  rowSelection,
  // fixedRowHeight,
  estimateSize,
  initSort = [],
  isUpdating = false,
  backUrl,
  rightHeaderContent,
  addOptions,
  filters,
  pinnedColumns,
  showExportInHeader = false,
  testIdName = '',
  oneRowHeader = false,
}: PropsWithChildren<TableProps<TDataType, TSelectionIdType>>) => {
  const initialState = {
    allRows: [],
    globalFilter: initialFilters?.globalFilter || '',
    divWidth: null,
  }
  const [state, dispatch] = React.useReducer(reducer, initialState)
  //we need a reference to the scrolling element for logic down below
  const tableContainerRef = React.useRef<HTMLDivElement>(null)
  const memoizedColumns: ColumnDef<TDataType, TSelectionIdType>[] | null = ([] =
    React.useMemo(() => {
      const newColumns = [...columns]
      if (!tableContainerRef.current?.offsetWidth) return []
      return calcColWidth<TDataType, TSelectionIdType>({
        columns: newColumns,
        // hiddenColumns: [],
        containerWidth: tableContainerRef.current?.offsetWidth - 10 || 0,
      })

      //offsetWidth needed to get width after render
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, tableContainerRef.current?.offsetWidth]))
  //can't get this to work with a reducer 😡 https://github.com/TanStack/table/discussions/4005

  const [sorting, setSorting] = React.useState<SortingState>(
    memoizedColumns.length ? initSort : [],
  )

  React.useEffect(() => {
    if (initSort?.length && initSort.length > 0 && columns.length) {
      setSorting(initSort)
    }
  }, [initSort, columns])
  // const [allRows, setAllRows] = React.useState<unknown[]>([])
  // const [globalFilter, setGlobalFilter] = React.useState(
  //   initialFilters?.globalFilter || '',
  // )
  // const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
  //   [],
  // )

  // const divWidth = React.useRef(0)

  const memoizedData = React.useMemo(() => {
    return data || []
  }, [data])
  // let memoizedColumns: ColumnDef<TDataType, TSelectionIdType>[] | null = []

  // // Create a new ResizeObserver
  // const resizeObserver = new ResizeObserver(entries => {
  //   if (state.divWidth !== entries[0].contentRect.width) {
  //     divWidth.current = entries[0].contentRect.width
  //     dispatch({
  //       type: 'setState',
  //       data: {divWidth: entries[0].contentRect.width},
  //     })
  //   }
  // })

  // Select the element to observe
  // const targetElement = tableContainerRef.current

  // Start observing the target element
  // if (targetElement) {
  //   resizeObserver.observe(targetElement)
  // }

  //hiding columns is no longer supported with show:value
  /*To hide column on table for exporting
      {
      header: '',
      id: 'idAsset',
      accessorKey: 'idAsset',
      cell: '',
      maxSize: 0,
      enableResizing: false,
      meta: {
        disableExport: true,
      },
    },
  */

  //I don't know how to type this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateColumnFilters = (filters: any) => {
    if (filters?.onColumnFilterChange) {
      filters.onColumnFilterChange(filters)
    }
  }

  const table = useReactTable({
    data: memoizedData || [],
    columns: memoizedColumns || [],
    enableColumnResizing: true,
    enableColumnFilters: Boolean(filters?.showColumnFilters),
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    globalFilterFn: fuzzyFilter,
    columnResizeMode: 'onChange',
    onGlobalFilterChange: (gf: string) =>
      dispatch({type: 'setState', data: {globalFilter: gf}}),
    onSortingChange: setSorting,
    onColumnFiltersChange: updateColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enablePinning: true,
    enableColumnPinning: true,
    state: {
      sorting: sorting,
      globalFilter: state.globalFilter,
      // columnFilters,
    },
    initialState: {
      globalFilter: initialFilters?.globalFilter || '',
      columnPinning: {
        left: pinnedColumns,
      },
    },
  })

  const {rows: filteredRows} = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    count: filteredRows.length,
    estimateSize: () => estimateSize || 60,
    measureElement: measureFn(estimateSize),
  })

  const setFilter = (value: string) => {
    if (!state.allRows.length) {
      const rows = table.getRowModel().rows
      dispatch({
        type: 'setState',
        data: {allRows: rows || [], globalFilter: value},
      })
    } else {
      dispatch({type: 'setState', data: {globalFilter: value}})
    }
    //TODO change to filters
    updateToMatchGlobalFilter && updateToMatchGlobalFilter(value)
  }

  const getArrayOfDataForExport = (
    rows: Row<RowData>[],
    columns: ColumnDef<TDataType, TSelectionIdType>[],
  ) => {
    const aoa: Array<Array<RowData>> = []
    rows.forEach(thisRow => {
      const allCells = thisRow.getVisibleCells()
      const row: RowData[] = []
      allCells.forEach((cell, index) => {
        if (!columns[index]?.meta?.disableExport) {
          let value = cell.getValue() || ''
          row.push(value)
        }
      })
      aoa.push(row)
    })
    return aoa
  }

  const exportData = (type: 'xlsx' | 'csv' | 'pdf', allRecords: boolean) => {
    const rows =
      allRecords && state.allRows.length
        ? state.allRows
        : table.getFilteredRowModel().rows

    const convertedRows = getArrayOfDataForExport(
      rows as Row<RowData>[],
      columns,
    )
    const exportedColumns = columns
      .map(c => {
        if (c.meta?.exportHeader) {
          c.header = c.meta.exportHeader
        }
        return c
      })
      .filter(c => !c.meta?.disableExport)
    if (type === 'xlsx') {
      saveAsExcel<TDataType, TSelectionIdType>({
        columns: exportedColumns,
        data: convertedRows,
        fileName: title,
      })
    } else if (type === 'csv') {
      saveAsCSV(exportedColumns, convertedRows, title)
    } else {
      saveAsPDF(exportedColumns, convertedRows, title)
    }
  }

  const toggleSelect = (
    selectedId: TSelectionIdType | undefined,
    selectAllClicked: boolean,
  ) => {
    if (!rowSelection) return

    const allSelectableIds = rowSelection.selectableIDs

    if (!selectAllClicked && rowSelection.setSelectedIDs) {
      //deselect individual row if already selected
      if (
        selectedId !== undefined &&
        rowSelection.selectedIDs.includes(selectedId)
      ) {
        rowSelection.setSelectedIDs(
          rowSelection.selectedIDs.filter(id => id !== selectedId),
        )

        //select individual row if not selected
      } else {
        selectedId !== undefined
          ? rowSelection.setSelectedIDs([
              ...rowSelection.selectedIDs,
              selectedId,
            ])
          : rowSelection.setSelectedIDs([...rowSelection.selectedIDs])
      }
    }

    if (filteredRows.length > 0) {
      const totalFilteredIds: TSelectionIdType[] = filteredRows
        .map(
          row =>
            row.original[
              rowSelection.idPropertyName as keyof TDataType
            ] as TSelectionIdType,
        )
        .filter(testId =>
          allSelectableIds ? allSelectableIds.find(id => id === testId) : true,
        )

      const selectedHiddenIds = rowSelection.selectedIDs.filter(
        selectedId =>
          !totalFilteredIds.find(filteredId => filteredId === selectedId),
      )
      const selectedFilteredIds = totalFilteredIds.filter(filteredId =>
        rowSelection.selectedIDs.find(selectedId => selectedId === filteredId),
      )

      const selectedFilteredLocations =
        selectedFilteredIds?.length === totalFilteredIds?.length
          ? []
          : totalFilteredIds

      if (rowSelection.setSelectedIDs) {
        rowSelection.setSelectedIDs([
          ...selectedFilteredLocations,
          ...selectedHiddenIds,
        ])
      }
    }
  }

  const areAllFilteredSelected = () => {
    if (
      !rowSelection?.idPropertyName ||
      rowSelection?.selectedIDs?.length === 0
    )
      return false
    const firstUnselectedId = filteredRows?.find(row => {
      const rowID = row.original[
        rowSelection?.idPropertyName
      ] as TSelectionIdType
      if (!rowID) return false
      return !rowSelection.selectedIDs?.includes(rowID)
    })
    return !firstUnselectedId
  }

  return (
    <>
      <Styles className="table-container">
        {showHeader && (
          <ResizableTableHeader
            title={title}
            totalItems={data?.length}
            selectedItems={
              rowSelection?.selectedIDs?.length &&
              rowSelection?.selectedIDs?.length > 0
                ? rowSelection?.selectedIDs?.length
                : filteredRows?.length
            }
            isUpdating={isUpdating}
            addOptions={addOptions}
            backUrl={backUrl}
            rightHeaderContent={rightHeaderContent}
            showExport={showExportInHeader}
            exportData={exportData}
            testIdName={testIdName}
            oneRowHeader={oneRowHeader}
            globalFilter={state.globalFilter}
            setGlobalFilter={setFilter}
          />
        )}
        {showSubHeader && !oneRowHeader && (
          <SubHeader>
            {selectionActionButtons && (
              <SelectAllButton
                data={rowSelection?.selectableIDs || []}
                toggleSelectAll={toggleSelect}
                selectedItemsTotal={rowSelection?.selectedIDs?.length}
                selectionActionButtons={selectionActionButtons}
                disabled={rowSelection?.selectedIDs?.length === 0}
                showChecked={areAllFilteredSelected()}
              />
            )}
            <GlobalFilter
              totalCount={filteredRows?.length}
              globalFilter={state.globalFilter}
              setGlobalFilter={setFilter}
            />
            {React.Children.map(
              children,
              child =>
                child &&
                React.cloneElement(<>{child}</>, {tableRows: filteredRows}),
            )}
            <RightButtons>
              {dateRange && (
                <DateRangePicker {...dateRange} direction="right" />
              )}
              {data?.length > 0 && (
                <TableExportButton
                  hiddenGlobalFilter={false}
                  exportData={exportData}
                  testIdName={testIdName}
                />
              )}
            </RightButtons>
          </SubHeader>
        )}
        <ScrollableTable
          ref={tableContainerRef}
          id="tableContainer"
          aria-label="Virtual list"
          height={height}
        >
          <table>
            <thead
              style={{
                display: 'grid',
                position: 'sticky',
                top: 0,
                zIndex: 1,
              }}
            >
              {table.getHeaderGroups().map(headerGroup => (
                <React.Fragment key={headerGroup.id}>
                  <tr style={{display: 'flex'}}>
                    {headerGroup.headers.map(header => (
                      <TableHeader<TDataType>
                        header={header}
                        key={header.id}
                        testIdName={testIdName}
                      />
                    ))}
                  </tr>
                  <tr
                    style={{
                      display: filters?.showColumnFilters ? 'flex' : 'none',
                    }}
                  >
                    {headerGroup.headers.map(header => {
                      const canFilter = header.column.getCanFilter()
                      return (
                        <React.Fragment key={'filter' + header.column.id}>
                          <td
                            style={{
                              width: header.getSize(),
                              ...getCommonPinningStyles(header.column),
                              borderBottom: `1px solid var(--asc-coolgray)`,
                            }}
                          >
                            <ColumnFilter
                              header={header}
                              canFilter={canFilter}
                              testIdName={testIdName}
                            />
                          </td>
                        </React.Fragment>
                      )
                    })}
                  </tr>
                </React.Fragment>
              ))}
            </thead>
            <Body
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
              }}
            >
              {status === 'loading' ? (
                <tr>
                  <ContainerCell colSpan={100}>
                    <Spinner type={'partial'} />
                  </ContainerCell>
                </tr>
              ) : filteredRows?.length > 0 ? (
                <>
                  {rowVirtualizer.getVirtualItems().map(virtualRow => {
                    const row = filteredRows[virtualRow.index] as Row<TDataType>
                    const isRowSelected = rowSelection?.selectedIDs.includes(
                      row.original[
                        rowSelection.idPropertyName as keyof TDataType
                      ] as TSelectionIdType,
                    )
                    const rowClickFn = rowClick
                      ? () => rowClick(row.original)
                      : () => {}

                    return (
                      <StyledTableRow
                        data-index={virtualRow.index} //needed for dynamic row height measurement
                        ref={rowVirtualizer.measureElement} //measure dynamic row height
                        data-cy={'tableRow'}
                        key={virtualRow.key}
                        onClick={rowClickFn}
                        clickable={Boolean(rowClick)}
                        style={{
                          transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                        }}
                        index={virtualRow.index}
                        data-testid={
                          isRowSelected ? rowSelection?.dataTestId : ''
                        }
                      >
                        {row.getVisibleCells().map(cell => {
                          return (
                            <ContainerCell
                              width={cell.column.getSize()}
                              activeRow={isRowSelected}
                              index={virtualRow.index}
                              key={cell.id}
                              className={
                                cell.column.columnDef.header ===
                                'Direct Distance'
                                  ? 'containerCell directDistance'
                                  : 'containerCell'
                              }
                              style={{...getCommonPinningStyles(cell.column)}}
                            >
                              <Cell
                                disableOverflow={
                                  !cell.column.getCanResize() ||
                                  cell.column.columnDef.header === 'Actions'
                                }
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </Cell>
                            </ContainerCell>
                          )
                        })}
                      </StyledTableRow>
                    )
                  })}
                </>
              ) : (
                <tr>
                  <ContainerCell colSpan={100} data-cy="noRecords">
                    <CenteredColumn>No records found</CenteredColumn>
                  </ContainerCell>
                </tr>
              )}
            </Body>
          </table>
        </ScrollableTable>
      </Styles>
    </>
  )
}

export default ResizableTable
