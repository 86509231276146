import Axios from 'axios'
import Swal from 'sweetalert2'
import {User} from '../types/types'
import {QueryClient} from '@tanstack/react-query'
import {EditDeviceType} from './TemedaAdmin/Devices/hooks/usePutDevice'

export const resetPassword = (email = '') => {
  if (email !== '') {
    Swal.fire({
      title: 'Reset Password',
      text:
        'Are you sure you want to send a Reset Password email to ' +
        email +
        ' ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Send reset email',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        try {
          await Axios.get(`/users?a=resetpassword&email=${email}&f=json`)
          Swal.fire({
            icon: 'info',
            title: 'Reset Password Email Sent',
          })
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Failed to reset password for this email.',
            text: 'An unexpected error occurred.',
          })
        }
      },
    })
  }
}

export const deleteUser = (
  id: number,
  allUsers: User[],
  cache: QueryClient,
) => {
  //find user
  let foundUser = {} as User
  allUsers.forEach((user: User) => {
    if (user && user.idUser === id) {
      foundUser = user
    }
  })
  if (id && foundUser) {
    Swal.fire({
      title: 'Delete User',
      text: `Are you sure you want to delete user ${foundUser.First_Name} ${foundUser.Last_Name}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Delete user',
      preConfirm: async () => {
        try {
          const response = await Axios.delete(`/users/${id}`)
          if (!response) {
            throw new Error()
          } else {
            Swal.fire({
              icon: 'info',
              title: 'User Deleted',
            })
            cache.invalidateQueries(['managementUsers'])
            return true
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Failed to delete user.',
            text: 'An unexpected error occurred.',
          })
          return null
        }
      },
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    })
  } else {
    return null
  }
}

export const deleteDevice = (
  id: number,
  devices: EditDeviceType[],
  cache: QueryClient,
) => {
  let foundDevice: Partial<EditDeviceType> = {}
  devices.forEach((device: EditDeviceType) => {
    if (device && device.idDevice === id) {
      foundDevice = device
    }
  })
  if (id && foundDevice) {
    Swal.fire({
      title: 'Delete Device',
      text: `Are you sure you want to delete idDevice ${id}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Delete device',
      preConfirm: async () => {
        try {
          const response = await Axios.delete(`/devices/${id}`)
          if (!response) {
            throw new Error()
          } else {
            cache.invalidateQueries([`devices`])
            Swal.fire({
              icon: 'info',
              title: 'Device Deleted',
            })
            return true
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Failed to delete device.',
            text: 'An unexpected error occurred.',
          })
          return null
        }
      },
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    })
  } else {
    return null
  }
}
