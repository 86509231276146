import * as React from 'react'
import {useNavigate} from 'react-router-dom'
import {ArrowLeft, Plus} from 'phosphor-react'
import {StyledButton} from 'common/tables/TableButtons'
import {Loader} from 'semantic-ui-react'
import styled from 'styled-components/macro'
import {Row} from 'common/components'
import Badge from 'common/components/Badge'
import SplitDropdownButton from 'common/components/SplitDropdownButton'
import TableExportButton from '../TableExportButton'
import GlobalFilter from '../GlobalFilter'

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #ffffff;
  border-bottom: solid 1px var(--asc-coolgray);
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
`
const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
`

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--primary);
  svg {
    margin-right: 5px;
  }
  :hover {
    color: var(--secondary);
  }
`
const TitleBadgeGroup = styled(Row)`
  align-items: center;
`

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  align-items: center;
  width: fit-content;
  gap: 5px;
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`

interface Props {
  title: string
  totalItems: number
  selectedItems: number
  isUpdating?: boolean
  backUrl?: string
  addOptions?: {
    addAction: () => void
    addButtonText: string
    dropdownItems?: {
      text: string
      dataCy: string
      onClick: () => void
    }[]
  }
  rightHeaderContent?: JSX.Element
  showExport: boolean
  exportData: (type: 'xlsx' | 'csv' | 'pdf', allRecords: boolean) => void
  testIdName?: string
  oneRowHeader?: boolean
  totalCount?: number
  globalFilter?: string
  setGlobalFilter?: (value: string) => void
}

const ResizableTableHeader: React.FC<Props> = ({
  title,
  totalItems = 0,
  selectedItems,
  isUpdating,
  backUrl,
  addOptions,
  rightHeaderContent,
  showExport,
  exportData,
  testIdName,
  oneRowHeader,
  globalFilter,
  setGlobalFilter,
}) => {
  const navigate = useNavigate()
  if (!title) return <></>
  return (
    <Header className="header">
      <HeaderRow>
        <Row>
          {backUrl && (
            <BackButton
              data-cy={'back-button'}
              onClick={() => navigate(backUrl)}
            >
              <ArrowLeft size={25} />
            </BackButton>
          )}
          <TitleBadgeGroup>
            <Title>{title}</Title>
            {title && (
              <Badge data-cy="badge">
                {selectedItems >= 1 && selectedItems < totalItems
                  ? `${Intl.NumberFormat().format(
                      selectedItems,
                    )} of ${Intl.NumberFormat().format(totalItems)}`
                  : Intl.NumberFormat().format(totalItems)}
              </Badge>
            )}
            {isUpdating && (
              <LoaderContainer>
                <Loader active inline size="tiny" />
                Retrieving List
              </LoaderContainer>
            )}
          </TitleBadgeGroup>
        </Row>
        {oneRowHeader && (
          <Row>
            {' '}
            <GlobalFilter
              totalCount={totalItems}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </Row>
        )}
        <ButtonRow>
          {(showExport || oneRowHeader) && (
            <TableExportButton
              hiddenGlobalFilter={false}
              exportData={exportData}
              testIdName={testIdName}
            />
          )}
          {rightHeaderContent && rightHeaderContent}
          {addOptions?.addAction &&
            (addOptions?.dropdownItems ? (
              <SplitDropdownButton
                buttonText={addOptions.addButtonText}
                onButtonClick={addOptions.addAction}
                dropdownItems={addOptions.dropdownItems}
              />
            ) : (
              <StyledButton onClick={addOptions.addAction}>
                <Plus />
                {addOptions.addButtonText}
              </StyledButton>
            ))}
        </ButtonRow>
      </HeaderRow>
    </Header>
  )
}
export default ResizableTableHeader
