import styled from 'styled-components/macro'
import {CaretDown, MagnifyingGlass} from 'phosphor-react'
import {CheckboxContainer} from '../Checkbox'
import {QUERIES} from 'common/constants'

type StyleProps = {
  open?: boolean
  checked?: boolean
  selectedColor?: string
}

export const Container = styled.div`
  position: relative;
  cursor: pointer;
  margin-bottom: 16px;
`
export const Input = styled.input<StyleProps>`
  border: 1px solid var(--asc-coolgray);
  height: 41px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid var(--asc-coolgray);
  border-radius: ${({open}) => (open ? '5px 5px 0px 0px' : '5px')};
  outline: ${({open}) => (open ? '1px solid var(--asc-keylime)' : 'none')};
  margin-right: 2px;
  caret-color: transparent;
`
export const InputCaret = styled(CaretDown)`
  width: 24px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 10px;
  display: ${({open}) => (open ? 'hidden' : 'block')};
  transform: ${({open}: {open: boolean}) =>
    open ? 'scaleY(-1)' : 'scaleY(1)'};
  display: ${({open}: {open: boolean}) => (open ? 'none' : 'block')};

  cursor: pointer;
`
export const SearchIcon = styled(MagnifyingGlass)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  left: 57px;
  cursor: pointer;
`
export const Controls = styled.div`
  height: 60px;
  background-color: var(--asc-cultured);
  position: absolute;
  /* outline: 1px solid red; */
  border-bottom: 1px solid var(--asc-coolgray);
  border-left: 1px solid var(--asc-graphite);
  border-right: 1px solid var(--asc-graphite);
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
`
export const StyledCheckbox = styled(CheckboxContainer)`
  input[type='checkbox'] {
    margin-right: 20px;
    border-color: var(--asc-sonicsilver);
    background-color: #ffffff;
  }
`
export const OptionList = styled.div`
  position: absolute;
  height: 263px;
  width: 100%;
  top: 100px;
  background-color: #ffffff;
  border-top: 1px solid var(--asc-coolgray);
  border-bottom: 1px solid var(--asc-graphite);
  border-left: 1px solid var(--asc-graphite);
  border-right: 1px solid var(--asc-graphite);
  border-radius: 0px 0px 20px 20px;
  overflow-x: hidden;
  z-index: 10;
`
export const Option = styled.div<StyleProps>`
  height: 46px;
  border-bottom: 1px solid #d2d2d7;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  width: 100%;
  padding: 15px;
  background-color: ${({checked}) =>
    checked ? 'var(--asc-selectedgreen)' : '#ffffff'};
`
export const SearchBar = styled.input`
  height: 30px;
  background: #ffffff;
  border: 1px solid var(--asc-coolgray);
  border-radius: 5px;
  padding: 8px 8px 8px 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-right: 8px;
  display: flex;
  flex: 1 0;
  width: 0;
  :focus {
    outline: 1px solid var(--asc-keylime);
    border: 1px solid var(--asc-moss);
  }
`
export const SearchControl = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`
export const ShowSelectedToggle = styled.div`
  display: none;
  @media ${QUERIES.laptopAndUp} {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
    span {
      font-size: 12px;
      white-space: nowrap;
    }
  }
`
export const SelectedListFocused = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  position: absolute;
  top: 7px;
  right: 2px;
  width: 98%;
  padding: 0 5px;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-bottom: 3px;
`
export const SelectedListBlurred = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  position: absolute;
  top: 7px;
  right: 2px;
  width: 98%;
  padding: 0 5px;
  overflow-x: hidden;
  overflow-y: hidden;
`

const selectedColors = {
  gold: {
    background: 'var(--asc-gold)',
    border: 'none',
  },
}

export const SelectedItem = styled.div`
  background: ${({open, selectedColor}: StyleProps) =>
    selectedColor === 'gold'
      ? selectedColors.gold.background
      : open
      ? 'var(--asc-keylime)'
      : 'var(--asc-platinum)'};
  /* border: 1px solid var(--asc-keylime); */
  border-color: ${({selectedColor}: StyleProps) =>
    selectedColor === 'gold'
      ? selectedColors.gold.background
      : 'var(--asc-graphite)'};
  border-radius: 20px;
  padding: 3px 10px;
  white-space: nowrap;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* min-width: 45px; */
  margin-bottom: ${({open}) => (open ? '5px' : '0')};
`
export const Gradient = styled.div`
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  width: 61px;
  height: 30px;
  position: absolute;
  right: -5px;
`
export const Dropdown = styled.div`
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15);
`
export const EmptyText = styled.div`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
`
