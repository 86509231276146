import * as React from 'react'
import {Card, Divider, Form} from 'semantic-ui-react'
import styled from 'styled-components/macro'
import {useStore} from 'common/useStore'
import {returnAccounts, returnLevels} from './hierarchySelectHelpers'
import AlertModal from './AlertModal'
import {CaretDown} from 'phosphor-react'
const reducer = (state, action) => {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        loading: true,
      }
    case 'init':
      return {
        ...state,
        levels: action.data.levels,
        accounts: action.data.accounts,
        loading: false,
      }
    case 'updateAccounts':
      return {...state, accounts: action.data.accounts}
  }
}

const StyledCard = styled(Card)`
  &&& {
    background-color: var(--asc-cultured);
    box-shadow: none;
    border-radius: 20px;
    display: flex;
    align-self: flex-start;
    font-size: 13px;
    width: 100%;
    border: solid 1px var(--asc-coolgray);
  }
`

const StyledFormField = styled(Form.Field)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 10px 0 !important;
  justify-content: flex-start;
  white-space: nowrap;
  gap: 8px;
  & > label {
    font-weight: 700;
    color: black;
  }
`

const StyledFormSelect = styled(Form.Select)`
  &&& {
    margin: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    border: solid 1px var(--asc-coolgray);
    border-radius: 5px;
  }

  &&& > div {
    padding: 6px;
    background-color: white;
    width: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    .ui.active.selection.dropdown {
      box-shadow: none !important;
      :hover {
        box-shadow: none !important;
      }
    }
    :hover {
      box-shadow: none !important;
    }
  }
`
const StyledCardHeader = styled(Card.Header)`
  &&& {
    font-size: 16px !important;
  }
`
const StyledDivider = styled(Divider)`
  &&& {
    margin: 0.5rem 0;
  }
`
const initialState = {levels: [], accounts: [], loading: false}

const HierarchySelect = ({updateHierarchy, hierarchy}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const idCustomer = useStore(state => state.userConfig?.idCustomer)
  const idCustomerType = useStore(state => state.userConfig?.idCustomerType)
  const [showDialog, setShowDialog] = React.useState(false)
  React.useEffect(() => {
    // set options for dropdown on mount
    const fetchHierarchyLevels = async ({shouldBeDirty}) => {
      dispatch({type: 'loading'})
      try {
        let [levels, accounts] = await Promise.all([
          returnLevels(idCustomer),
          returnAccounts(
            idCustomer,
            hierarchy.idCustomerType || idCustomerType,
          ),
        ])

        dispatch({type: 'init', data: {levels, accounts}})
        if (!hierarchy.idCustomer) {
          updateHierarchy({
            ...hierarchy,
            idCustomerType: hierarchy.idCustomerType || idCustomerType,
            idCustomer: accounts[0].value,
            shouldBeDirty,
          })
        }
      } catch (error) {
        console.error(error)
        setShowDialog(true)
      }
    }
    if (state.levels.length === 0 && idCustomer && !state.loading) {
      fetchHierarchyLevels({shouldBeDirty: false})
    }
  }, [
    state.levels,
    idCustomer,
    hierarchy,
    updateHierarchy,
    state.loading,
    idCustomerType,
  ])

  const onLevelChange = async (e, {value}) => {
    const accounts = await returnAccounts(idCustomer, value)
    dispatch({type: 'updateAccounts', data: {accounts}})
    updateHierarchy({
      ...hierarchy,
      idCustomerType: value,
      idCustomer: accounts[0].value,
    })
  }

  const onAccountChange = (e, {value}) => {
    updateHierarchy({
      ...hierarchy,
      idCustomer: value,
    })
  }

  return (
    <StyledCard fluid>
      <Card.Content>
        <StyledCardHeader>Hierarchy</StyledCardHeader>
        <StyledDivider />
        <Card.Description>
          <StyledFormField>
            <label>Level </label>
            <StyledFormSelect
              onChange={onLevelChange}
              data-cy="hierarchyLevel"
              options={state.levels || []}
              disabled={!hierarchy.idCustomerType}
              value={hierarchy.idCustomerType}
              icon={<CaretDown color="var(--asc-sonicsilver)" weight="fill" />}
            ></StyledFormSelect>
          </StyledFormField>
          <StyledFormField>
            <label>Account Name</label>
            <StyledFormSelect
              onChange={onAccountChange}
              data-cy="hierarchyAccount"
              options={state.accounts || []}
              disabled={!hierarchy.idCustomer}
              value={hierarchy.idCustomer}
              icon={<CaretDown color="var(--asc-sonicsilver)" weight="fill" />}
            />
          </StyledFormField>
        </Card.Description>
      </Card.Content>
      <AlertModal
        showDialog={showDialog}
        onSave={() => {
          setShowDialog(false)
        }}
        type={'error'}
        title={'Unable to load the hierarchy.'}
        text={'Please check your connection and try again.'}
        showDenyButton={false}
        data-cy="errorDialog"
      />
    </StyledCard>
  )
}

export default HierarchySelect
