import * as React from 'react'
import styled from 'styled-components/macro'
import {useNavigate} from 'react-router-dom'
import {Button, Form} from 'semantic-ui-react'
import AscendLogo from 'images/ascend-logo.svg'
import TemedaLogo from 'images/temeda-logo-2020.png'
import {APP_FLAVOR, QUERIES} from 'common/constants'
import {resetPassword} from './forgotPasswordHelpers'
import LoginImage from 'images/login-image.png'
import {InputWithLabel} from 'common/components'
import AlertModal, {ButtonType} from 'common/components/AlertModal'
import {isEmail} from 'common/helpers'
import TemedaFavicon from 'images/temeda.png'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100vw;
  align-items: center;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
`

const LeftPanel = styled.div`
  padding: 50px;
  width: 100%;
  @media ${QUERIES.tabletAndUp} {
    width: 460px;
  }
  display: flex;
  flex-direction: column;
  height: 100%;
  &&& {
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      width: 460px;
      .field {
        width: 100%;
        margin-bottom: 30px;
      }
      input[type='text'],
      input[type='password'] {
        border: 1px solid var(--asc-coolgray);
        padding: 10px 15px;
        margin-right: 5px;
        background-color: white;
        font-size: 17px;
        border-style: solid;
        border-radius: 5px;
        :focus {
          border: 1px solid var(--asc-moss);
          box-sizing: border-box;
          box-shadow: 0px 0px 6px 1px var(--asc-keylime);
        }
      }
      label {
        /* margin-top: 8px; */
        color: black;
        font-size: 15px;
        font-weight: bold;
        span {
          font-weight: normal;
        }
      }
      .ui.checkbox {
        margin-top: 3px;
      }
      a {
        color: var(--primary);
        font-weight: bold;
        :hover {
          color: var(--secondary);
          span {
            color: var(--primary);
          }
        }
      }
    }
  }
`

const RightPanel = styled.div`
  display: flex;
  width: 100%;
  border: none;
  height: 100%;
`

const Logo = styled.img`
  width: 450px;
  margin-top: 30px;
`

const LoginButton = styled(Button)`
  &&& {
    padding: 10px 5px;
    background-color: var(--secondary) !important;
    color: var(--primary);
    width: 270px;
    height: 50px;
    font-weight: 700 !important;
    font-size: 13px;
    :hover {
      color: var(--secondary) !important;
      background: var(--primary) !important;
    }
  }
`

// const StyledLabel = styled.label`
//   &&&& {
//     color: black;
//     font-size: 15px;
//     font-weight: bold;
//   }
// `

const ReturnToLoginLink = styled.a`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 1.1rem;
`

const HelpMessage = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 16px;
  margin-top: 20px;
  span {
    font-weight: bold;
  }
  a {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    color: var(--primary);
    font-weight: bold;
    margin-top: 5px;
    flex-wrap: wrap;
    span {
      font-weight: normal;
      margin-right: 4px;
    }
  }
  h1 {
    font-size: 20px;
    font-weight: normal;
    color: black;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  img {
    height: 100%;
    object-fit: cover;
  }
`

const LoginHelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 25%;
  justify-content: flex-end;
  align-items: flex-start;
`
const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 25%;
  align-content: center;
  align-items: center;
`

const ForgotPassword = () => {
  // const queryClient = useQueryClient()
  // const themeContext = React.useContext(ThemeContext)
  const [loading, setLoading] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const navigate = useNavigate()
  const [errors, setErrors] = React.useState({})
  const [showDialog, setShowDialog] = React.useState(false)
  const [dialogTitle, setDialogTitle] = React.useState('')
  const [dialogText, setDialogText] = React.useState('')
  const [dialogType, setDialogType] = React.useState<ButtonType>('')

  const element = document.getElementById('dynamic-favicon') as HTMLLinkElement
  if (element) {
    element.href =
      APP_FLAVOR === 'ascend'
        ? `<svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 36.8313 23.0905"
            >
              <path
                d="M34.0568,23.0905l-.5508-5.2852L26.6734,11.995,8.91471,14.183,0,18.0387l24.6822-2.8006Z"
                fill="#b9cf34"
              />
              <path d="M27.5127,10.619l6.0392,5.1345Z" fill="#788837" />
              <path
                d="M3.43652,13.5898l9.79408-1.2107L27.5123,10.619l6.0392,5.1345,3.2798,2.786L34.8857,0Z"
                fill="#788837"
              />
            </svg>`
        : `${TemedaFavicon}`
  }

  const onSubmit = async () => {
    setLoading(true)
    if (email && isEmail(email)) {
      try {
        const response = await resetPassword(email)
        if (response === 'success') {
          setDialogTitle('Reset Password Email Sent')
          setDialogText('Please check your email for a login link')
          setDialogType('info')
          setShowDialog(true)
        } else {
          throw new Error(`reset password failed for ${email}`)
        }
      } catch (error) {
        setDialogTitle('Password reset failed')
        setDialogText('Please verify your Email address')
        setDialogType('error')
        setShowDialog(true)

        console.error(error)
      }
    } else {
      setErrors({
        email: {
          message: email ? 'Email is invalid' : 'Email is required',
        },
      })
    }

    setLoading(false)
  }

  const checkValidation = (newEmail: string) => {
    const emailValid = isEmail(newEmail)
    if (!emailValid) {
      setErrors({
        email: {
          message: newEmail ? 'Email is invalid' : 'Email is required',
        },
      })
    }

    return emailValid
  }

  return (
    <React.Fragment>
      <Container>
        <LeftPanel>
          <LogoContainer>
            <Logo src={APP_FLAVOR === 'ascend' ? AscendLogo : TemedaLogo} />
          </LogoContainer>
          <h3>Forgot Your Password?</h3>
          <HelpMessage>
            We’ll email you instructions to reset your password. If you don’t
            have access to your email anymore, contact our support team at
            customer.support@temeda.com.
          </HelpMessage>
          <Form onSubmit={onSubmit}>
            {/* <Form.Field>
            <StyledLabel htmlFor="forgot-email">Email</StyledLabel>
            <input
              type="text"
              value={email}
              onChange={e => setEmail(e.currentTarget.value)}
              data-cy="forgot-email"
              id="forgot-email"
            />
          </Form.Field> */}
            <InputWithLabel
              data-cy="login-email"
              value={email}
              onChange={e => {
                setEmail(e.currentTarget.value.replace(/\s/g, ''))
                checkValidation(e.currentTarget.value)
              }}
              label="Email"
              name="email"
              errors={errors}
            />
            <LoginButton
              primary
              fluid
              size="large"
              type="submit"
              disabled={loading}
              loading={loading}
            >
              SEND EMAIL
            </LoginButton>
            <ReturnToLoginLink href="/">Return to Login</ReturnToLoginLink>
            <LoginHelpContainer>
              <HelpMessage>
                <span>Need help? </span>

                <a
                  href={
                    APP_FLAVOR === 'ascend'
                      ? 'mailto:customer.support@ascendfleet.com'
                      : 'mailto:customer.support@temeda.com'
                  }
                >
                  <span>Email us at</span>
                  {APP_FLAVOR === 'ascend'
                    ? 'customer.support@ascendfleet.com'
                    : 'customer.support@temeda.com'}
                </a>
              </HelpMessage>
            </LoginHelpContainer>
          </Form>
        </LeftPanel>
        <RightPanel>
          <ImageContainer>
            <img src={LoginImage}></img>
          </ImageContainer>
        </RightPanel>
      </Container>
      <AlertModal
        showDialog={showDialog}
        onSave={() => {
          setShowDialog(false)
          navigate('/login')
        }}
        type={dialogType}
        title={dialogTitle}
        text={dialogText}
        showDenyButton={false}
        data-cy="dialog"
      />
    </React.Fragment>
  )
}
export default ForgotPassword
