import * as React from 'react'
import styled from 'styled-components/macro'
import Modal from 'common/Modal'
// import {useStore} from 'common/useStore'
import useCustomerNotications from 'routingAndDispatch/Setup/routeSettings/Customer Notifications/hooks/useCustomerNotifications'

import {Spinner} from 'common/components'
// import {CustomerNotificationType} from 'routingAndDispatch/Setup/routeSettings/Customer Notifications/notificationsHelpers'

const Container = styled.div`
  padding: 20px;
`

const List = styled.ul`
  height: 400px;
  overflow-y: scroll;
  margin: 10px;
  border: 1px solid var(--border-color);
  border-radius: 3px;
  padding: 0;
`

const Item = styled.li`
  list-style: none;
  padding: 5px;
  border-bottom: 1px solid var(--table-borders);
  cursor: pointer;
  :hover {
    background-color: var(--grey-100);
  }
`

const NotificationSelectionModal = ({
  showNotificationSelectionModal,
  setShowNotificationsModal,
  setSelectedNotification,
}: {
  showNotificationSelectionModal: boolean
  setShowNotificationsModal: (showModal: boolean) => void
  setSelectedNotification: (selectedNotificationId: number) => void
}) => {
  const {data: customerNotifications, status} = useCustomerNotications()

  const onSelect = (idRDEtaDefaultTemplate: number) => {
    setSelectedNotification(idRDEtaDefaultTemplate)
    setShowNotificationsModal(false)
  }

  if (status === 'error') {
    return <div>There was an error loading the reports. Please try again</div>
  }

  return (
    <Modal
      showDialog={showNotificationSelectionModal}
      title={'Notifications'}
      width={'400px'}
      renderFooter={false}
      onDismiss={() => setShowNotificationsModal(false)}
    >
      <Container>
        <h4>Select Notification:</h4>
        <List>
          {status === 'loading' ? (
            <Spinner type="partial" />
          ) : (
            <>
              {customerNotifications?.map(notification => {
                return (
                  <Item
                    key={notification.idRDEtaDefaultTemplate}
                    onClick={() =>
                      onSelect(notification.idRDEtaDefaultTemplate)
                    }
                  >
                    {notification.NotificationName}
                  </Item>
                )
              })}
            </>
          )}
        </List>
      </Container>
    </Modal>
  )
}
export default NotificationSelectionModal
