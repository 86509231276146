import {WINDOWTABS, APP_FLAVOR, emailSchema} from './constants'

import {useStore, ORoleType} from 'common/useStore'
import {ordinalNumber} from './helpersNumber'
import TemedaFavicon from 'images/temeda.png'
import {OrderType, StopType} from 'routingAndDispatch/types/typesModule'
import {format} from 'date-fns'
//TODO better email validation needed
export const isEmail = function (email: string) {
  const regex =
    // eslint-disable-next-line no-useless-escape
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ //allow apostrphes//
  return regex.test(email)
}

export const validateEmail = (email: string | undefined | null) => {
  if (!email) return false
  try {
    emailSchema.parse({email: email})
    return true
  } catch (error) {
    return false
  }
}

export const setFavicon = () => {
  const element = document.getElementById('dynamic-favicon') as HTMLLinkElement
  if (element) {
    if (APP_FLAVOR === 'ascend') {
      element.href = '/favicon.ico'
    } else {
      element.href = `${TemedaFavicon}`
    }
  }
}

export const openWindowTab = (type: string, url: string) => {
  closeWindowTab(type)
  WINDOWTABS[type] = window.open(url, '_blank')
  return WINDOWTABS[type]
}
const closeWindowTab = (type: string) => {
  if (WINDOWTABS[type]) {
    WINDOWTABS[type]?.close()
  }
}
// export const closeAllWindowTabs = () => {
//   Object.values(WINDOWTABS).forEach(tab => {
//     if (tab) {
//       tab?.close()
//     }
//   })
// }

export const checkEmails = (Email_List: string) => {
  let emailsValid = true
  let ordinalNumberEmail = 'first'
  if (Email_List && Email_List !== '') {
    const emailArray = Email_List.split(',')
    if (emailArray && emailArray.length > 0) {
      emailArray.forEach((email, index) => {
        if (emailsValid && !isEmail(email.trim())) {
          emailsValid = false
          ordinalNumberEmail = ordinalNumber(index)
        }
      })
    }
  }
  return {ordinalEmail: ordinalNumberEmail, emailsValid}
}
export const phoneNumbersCheck = (testValue: string | Array<string>) => {
  const phoneArray = Array.isArray(testValue)
    ? testValue
    : testValue
    ? testValue.split(',')
    : []
  let validNumbers = true
  let problemNumber = 0
  phoneArray.forEach((phone, index) => {
    if (phone?.length < 10 || phone?.length > 15) {
      validNumbers = false
      if (!problemNumber) problemNumber = index
    }
  })

  return {validNumbers, problemNumber: ordinalNumber(problemNumber)}
}

export const dashboardClick = (idAsset: number, icon: string) => {
  const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
  width=1500,height=800,left=100,top=100`
  const url = `/asset-dashboard/${idAsset}?start=${
    icon === 'alert'
      ? 'alerts'
      : icon === 'wrench'
      ? 'maintenance'
      : icon === 'driver'
      ? 'driver'
      : 'dashboard'
  }`
  window.open(url, 'asset-dashboard', params)
  const favicon = document.createElement('link')
  favicon.id = 'dynamic-favicon'
  favicon.rel = 'icon'
  document.head.appendChild(favicon)
  setFavicon()
}

// export const exitDashboardClick = () => {
//   window.resizeTo(window.screen.availWidth, window.screen.availHeight)
// }

export const useCheckUserRestriction = (type: string) => {
  const userConfig = useStore(state => state.userConfig)
  if (
    userConfig?.Role_Id === ORoleType.RestrictedAdministrator &&
    userConfig.RestrictedAdminAccess
  ) {
    const allowedRoutes = userConfig.RestrictedAdminAccess.split(',')

    if (allowedRoutes.includes(type)) {
      return true
    } else {
      return false
    }
  } else if (userConfig?.Role_Id === ORoleType.Administrator) {
    return true
  } else {
    return false
  }
}

export const setColorsBasedOnFlavor = () => {
  if (APP_FLAVOR === 'att') {
    document.body.style.setProperty('--primary', '#182028')
    document.body.style.setProperty('--secondary', '#00a1e1')
  } else if (APP_FLAVOR === 'ascend') {
    document.title = 'ASCEND'
  } else if (APP_FLAVOR === 'temeda') {
    document.title = 'TEMEDA'
  }
}

export const sortArrayOfObjectsByStringValue = <T>(
  arrayToSort: T[],
  key: keyof T,
  reverse: boolean = false,
) => {
  const sortedArray = arrayToSort.sort((a, b) => {
    const nameA = String(a[key]).toUpperCase() // ignore upper and lowercase
    const nameB = String(b[key]).toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return reverse ? 1 : -1
    }
    if (nameA > nameB) {
      return reverse ? -1 : 1
    }

    // names must be equal
    return 0
  })
  return sortedArray
}

// eslint-disable-next-line import/no-unused-modules
export const sortArrayOfObjectsByDateValue = <T>(
  arrayToSort: T[],
  key: keyof T,
  reverse: boolean = false,
) => {
  const sortedArray = arrayToSort.sort((a, b) => {
    const aDate = a[key]
    const bDate = b[key]
    if (aDate instanceof Date && bDate instanceof Date) {
      const timeA = aDate.getTime() // ignore upper and lowercase
      const timeB = bDate.getTime() // ignore upper and lowercase
      if (timeA < timeB) {
        return reverse ? 1 : -1
      }
      if (timeA > timeB) {
        return reverse ? -1 : 1
      }
    }

    // times must be equal
    return 0
  })
  return sortedArray
}

// export const sortDateStrings = (a: string, b: string) => {
//   const dateA = new Date(a)
//   const dateB = new Date(b)
//   return dateA.getTime() - dateB.getTime()
// }

export const notNullUndefined = (value: unknown) => {
  return value !== null && value !== undefined
}

export const getServiceDurationTime = (order?: OrderType) => {
  if (order && order.stops.length > 0) {
    let totalDurationMinutes = 0

    order?.stops.forEach((stop: StopType) => {
      stop.serviceDuration ? (totalDurationMinutes += stop.serviceDuration) : ''
    })

    const totalMinutes = Math.floor(totalDurationMinutes)
    const totalHours = Math.floor(totalMinutes / 60)
    const remainingMinutes = totalMinutes % 60

    const minutesString = () => {
      if (!totalHours) {
        return remainingMinutes ? remainingMinutes + ' min' : ''
      }
      return remainingMinutes ? ' : ' + remainingMinutes + ' min' : ''
    }

    return `${totalHours > 0 ? totalHours + ' hr' : ''}${minutesString()}`
  } else {
    return `0 hr : 0 min`
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// export const isValidNumber = (value: any) => {
//   return typeof value === 'number' && !isNaN(value)
// }

export const removeDateYear = (dateFormat: string) => {
  let newDate = dateFormat
  const splitDate = dateFormat.split('/', 3)

  if (splitDate[2] === 'yyyy') newDate = `${splitDate[0]}/${splitDate[1]}`

  if (splitDate[2] === 'yy') newDate = `${splitDate[0]}/${splitDate[1]}`

  return newDate
}

export const sortStringsCaseInsensitive = (a: string, b: string) => {
  if (a.toLowerCase() > b.toLowerCase()) return 1
  return -1
}

export const formatDateToStringForAPI = (dateToConvert: Date): string => {
  if (!dateToConvert) return ''
  const dateString =
    format(dateToConvert, 'yyyy-MM-dd') +
    'T' +
    format(dateToConvert, 'HH:mm:ss')
  return dateString
}
