import ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'
import {ColumnDef} from '@tanstack/react-table'

type Props<TDataType, TSelectionIdType> = {
  columns: ColumnDef<TDataType, TSelectionIdType>[]
  data: unknown[][]
  fileName?: string
}

export const saveAsExcel = async <TDataType, TSelectionIdType>({
  columns,
  data,
  fileName,
}: Props<TDataType, TSelectionIdType>) => {
  const workbook = new ExcelJS.Workbook()
  workbook.created = new Date()
  workbook.modified = new Date()
  const worksheet = workbook.addWorksheet()

  const headers = columns.map(c => {
    return {
      //TODO - fix this typescript weirdness once we have fully moved to new table
      // @ts-expect-error fix this typescript weirdness once we have fully moved to new table - space is needed for unnamed columns
      name: c.exportHeader || c.exportValue || c.Header || c.header || ' ',
      filterButton: true,
    }
  })

  worksheet.addTable({
    name: 'ExportTable',
    ref: 'A1',
    headerRow: true,
    totalsRow: false,
    style: {
      theme: 'TableStyleMedium2',
      showRowStripes: true,
    },
    columns: headers,
    rows: data,
  })

  const columnsToDelete = []

  worksheet.columns.forEach((column, index) => {
    const originalColumn = columns[index]
    // @ts-expect-error fix this typescript weirdness once we have fully moved to new table
    if (originalColumn.noExport) {
      columnsToDelete.push(index)
    }

    // @ts-expect-error fix this typescript weirdness once we have fully moved to new table
    if (originalColumn.meta?.excelWidth || originalColumn?.excelWidth) {
      //excelwidth was provided use that
      column.width =
        // @ts-expect-error fix this typescript weirdness once we have fully moved to new table
        originalColumn.meta?.excelWidth || originalColumn.excelWidth
    } else {
      //autofit width based on character count
      var dataMax = 0
      if (column?.eachCell) {
        column.eachCell({includeEmpty: true}, function (cell) {
          var columnLength = String(cell.value)?.length
          if (columnLength > dataMax) {
            dataMax = columnLength
          }
        })
        column.width = dataMax < 15 ? 15 : Math.min(dataMax, 100)
      }
    }
    // @ts-expect-error fix this typescript weirdness once we have fully moved to new table
    if (originalColumn?.formatting === 'number') {
      // @ts-expect-error fix this typescript weirdness once we have fully moved to new table
      const format = '#,##0.' + '0'.repeat(originalColumn?.decimals || 0)
      column.numFmt = format
    }
    // @ts-expect-error fix this typescript weirdness once we have fully moved to new table
    if (originalColumn?.formatting === 'currency') {
      column.numFmt = '$#,##0.00'
    }
  })

  const buf = await workbook.xlsx.writeBuffer()

  saveAs(new Blob([buf]), `${fileName || 'Export'}.xlsx`)
}
